import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useUserStore, useToastStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

const Mute: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const speakTo = userStore.cameraStore.speakTo;

  const handleAudio = async () => {
    if (speakTo === "none") {
      await userStore.speakToAllParticipants();
      toastStore.addToast(t("speaking_to_all"));
    } else {
      await userStore.speakToNoneParticipants();
      toastStore.addToast(t("speaking_to_none"));
    }
  };

  return (
    <div
      className='text-gray-250 cursor-pointer flex flex-col items-center justify-between'
      onClick={handleAudio}
    >
      <div className='relative'>
        {userStore.speakingToNone ? (
          <Icons.MicOff className='h-8' />
        ) : userStore.speakingToAll ? (
          <Icons.Microphone className='h-8' />
        ) : (
          <Icons.MicOne className='h-8' />
        )}
      </div>
      <span className='text-xs text-center leading-4'>
        {userStore.speakingToNone ? t("unmute") : t("mute")}
      </span>
    </div>
  );
});

export default Mute;
