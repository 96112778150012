import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRoomStore, useViewModeStore } from "../../../hooks/store";

import ExamView from "./ExamView/ExamView";
import HybridExamView from "./HybridExamView/HybridExamView";
import HybridParticipantView from "./HybridParticipantView/HybridParticipantView";
import IdentityView from "./IdentityView/IdentityView";
import OneOnOneView from "./OneOnOneView/OneOnOneView";
import ParticipantView from "./ParticipantView/ParticipantView";
import "../../../services/i18n/config";

const HostCall: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const totalUsers = _.size(viewModeStore.userStore.participants);
  const roomStore = useRoomStore();
  const { t } = useTranslation();
  return (
    <>
      {totalUsers !== 0 && (
        <>
          {viewModeStore.isMode("ONE_ON_ONE") && <OneOnOneView />}
          {viewModeStore.isMode("PARTICIPANT") && <ParticipantView />}
          {viewModeStore.isMode("EXAM") && roomStore.info?.exam?.screenSharing && <ExamView />}
          {viewModeStore.isMode("IDENTITY") && <IdentityView />}
          {viewModeStore.isMode("HYBRID_EXAM") && roomStore.info?.exam?.screenSharing && <HybridExamView />}
          {viewModeStore.isMode("HYBRID_PARTICIPANT") && roomStore.info?.exam?.screenSharing && <HybridParticipantView />}
        </>
      )}
      {totalUsers === 0 && (
        <div className='h-full w-full relative'>
          <div className='h-full flex flex-col items-center justify-center text-white space-y-4'>
            <p className='font-bold text-white'>{t("your_students_will_appear_here")} 🙋</p>
          </div>
        </div>
      )}
    </>
  );
});

export default HostCall;
