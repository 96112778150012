import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useUserStore, useToastStore, useViewModeStore, useChatStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

const Reset: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const viewModeStore = useViewModeStore();
  const chatStore = useChatStore();

  const handleReset = async () => {
    if (viewModeStore.isMode("PARTICIPANT")) return;

    viewModeStore.changeMode("PARTICIPANT");
    userStore.listenToAllParticipants();
    await userStore.speakToAllParticipants();
    toastStore.addToast(t("speaking_to_all"));
    chatStore.setSelected(null);
  };

  const className = classNames("flex flex-col items-center justify-between", {
    "text-gray-500": viewModeStore.isMode("PARTICIPANT"),
    "text-gray-250 cursor-pointer": !viewModeStore.isMode("PARTICIPANT"),
  });

  return (
    <div className={className} onClick={handleReset}>
      <div className='relative'>
        <Icons.Grid className='h-6' />
      </div>
      <span className='text-xs text-center leading-4'>{t("grid_view")}</span>
    </div>
  );
});

export default Reset;
