import React from "react";

const handleStringChange =
  (handler: (value: string) => void) =>
  (event: React.FormEvent<HTMLElement>): void => {
    handler((event.target as HTMLInputElement).value);
  };

const preventParentTrigger =
  (handler?: () => void) =>
  (event?: React.SyntheticEvent): void => {
    if (handler) {
      handler();
    }
    if (event) {
      event.stopPropagation();
    }
  };

const preventDefaultTrigger =
  (handler?: () => void) =>
  (event?: React.SyntheticEvent): void => {
    if (handler) {
      handler();
    }
    if (event) {
      event.preventDefault();
    }
  };

const events = {
  handleStringChange,
  preventParentTrigger,
  preventDefaultTrigger,
};

export default events;
