import { action, computed } from "mobx";

import { Resetable } from "../interfaces/resetable";

import RTCStore from "./rtc";
import RTMStore from "./rtm";
import RoomStore from "./room";
import UIStore from "./ui";
import DeviceStore from "./device";
import AuthorizationStore from "./authorization";

export type AWSCredentials = {
  accessKey: string;
  secretKey: string;
  bucket: string;
  region: string;
};

export type AgoraCredentials = {
  appId?: string;
  customerSecret?: string;
  customerKey?: string;
};

export type ExamsCredentials = {
  agora?: AgoraCredentials;
  aws?: AWSCredentials;
};

class AppStore implements Resetable {
  uiStore!: UIStore;

  roomStore!: RoomStore;

  rtcStore!: RTCStore;

  rtmStore!: RTMStore;

  deviceStore!: DeviceStore;

  authorizationStore!: AuthorizationStore;

  credentials: ExamsCredentials;

  constructor(credentials: ExamsCredentials) {
    this.uiStore = new UIStore(this);
    this.roomStore = new RoomStore(this);
    this.rtcStore = new RTCStore(this);
    this.rtmStore = new RTMStore(this);
    this.deviceStore = new DeviceStore(this);
    this.authorizationStore = new AuthorizationStore(this);
    this.credentials = credentials;
  }

  @computed
  get appId(): string {
    return this.agoraCredentials.appId || "";
  }

  @computed
  get restfullAPIToken(): string {
    return window.btoa(
      `${this.agoraCredentials.customerKey}:${this.agoraCredentials.customerSecret}`,
    );
  }

  @computed
  get agoraCredentials(): AgoraCredentials {
    return this.credentials.agora;
  }

  @computed
  get awsCredentials(): AWSCredentials {
    return this.credentials.aws;
  }

  @action
  reset(): void {
    this.uiStore.reset();
    this.roomStore.reset();
    this.rtcStore.reset();
    this.rtmStore.reset();
    this.deviceStore.reset();
  }
}

export default AppStore;
