import React from "react";
import { ConnectionState } from "../../../stores/app/rtm";
import Icons from "../../../images/icons";
import _ from "lodash";
import { useRoomStore } from "../../../hooks/store";
import ConnectionQuality from "../../ConnectionQuality/ConnectionQuality";

type Props = {
  connectionState?: string;
};

const ConnectionPlayer: React.FunctionComponent<Props> = ({ connectionState }: Props) => {
    const roomStore = useRoomStore();

    const getStatusColor = (connectionState: string) => {
        switch (connectionState) {
          case ConnectionState.CONNECTED:
            return "bg-green-600";
          case ConnectionState.CONNECTING:
          case ConnectionState.RECONNECTING:
            return "bg-[#F2A20C]";
          case ConnectionState.DISCONNECTED:
          case ConnectionState.ABORTED:
            return "bg-red-600";
          default:
            return "bg-gray-600";
        }
    }

return (
  <div className='pt-2 pb-1 px-1 max-w-full mr-auto'>
    <span className='flex flex-row items-center text-white truncate text-xs'>
        <div className={`rounded mx-1 h-2 w-2 ${getStatusColor(connectionState)}`} />
        {connectionState}
        {
            _.includes([ConnectionState.DISCONNECTED, ConnectionState.ABORTED], connectionState) && 
            <Icons.Reset className="h-3 pl-1" onClick={() => roomStore.rtm.handleReconnection() }/>
        }
        <ConnectionQuality />
    </span>
  </div>
)};

export default ConnectionPlayer;
