import { observer } from "mobx-react-lite";
import React from "react";
import { useModalStore, useRoomStore } from "../../hooks/store";
import Icons from "../../images/icons";


const ConnectionQuality: React.FunctionComponent = observer(() => {
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const networkQuality = roomStore.rtc?.cameraStore?.networkQuality;

  if(!networkQuality) return;

  const onOpenQualityModal = async () => {
    modalStore.openModal("NETWORK_QUALITY", {
      networkQuality: networkQuality,
    });
  }
  const colors = ["text-gray-200","text-[#63ca78]", "text-[#97bf64]", "text-[#edca5a]", "text-[#e59c4a]", "text-[#db4552]", "text-[#A52A2A]"];


  const colorUp = colors[networkQuality?.uplinkNetworkQuality ?? 0];
  const colorDown = colors[networkQuality?.downlinkNetworkQuality ?? 0];

  return (
    <div className="flex items-center justify-center ml-2 cursor-pointer" onClick={onOpenQualityModal}>
        <Icons.Up className={`h-4 ${colorUp}`}/>
        <Icons.Down className={`h-4 ${colorDown}`}/>
    </div>
  );
});

export default ConnectionQuality;
