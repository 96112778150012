import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthorizationStore } from "../../../hooks/store";
import useQuery from "../../../hooks/useQuery";
import { ExamsUserRole } from "../../../types/enum";
import BackgroundE4Z from "../BackgroundE4Z";

export default function Login() {
  const [username, setUsername] = useState("");
  const authorizationStore = useAuthorizationStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const query = useQuery();
  const [showGuestForm, setShowGuestForm] = useState(false);
  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const { loginWithPopup } = useAuth0();
  const { t } = useTranslation();

  const checkAccessType = useCallback(async () => {
    const result = await authorizationStore.getExamInfo(id, query.get("accountId"));
    return result;
  }, [id, query]);

  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      localStorage.setItem("exam", id);
      authorizationStore.setRoomUser({
        name: event.target.value,
        id: null,
        account: query.get("accountId"),
        role: ExamsUserRole.PARTICIPANT,
        agoraUserId: null,
        guest: true,
      });
      window.location.href = `/exams/${id}/welcome${search}`;
    }

    if (event.target.id === "submit") {
      authorizationStore.setRoomUser({
        name: username,
        id: null,
        account: query.get("accountId"),
        role: ExamsUserRole.PARTICIPANT,
        agoraUserId: null,
        guest: true,
      });
      window.location.href = `/exams/${id}/welcome${search}`;
    }
  };

  const handleLogin = () => {
    loginWithPopup().then(() => {
      window.location.href = `/exams/${id}/welcome${search}`;
    });
  };

  useEffect(() => {
    if (authorizationStore?.roomUser?.id || query.has("lms")) {
      navigate(`/exams/${id}/welcome${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationStore.roomUser, authorizationStore.roomUser, id, navigate, search]);

  useEffect(() => {
    checkAccessType().then((result) => {
      if (result.allowGuest) {
        setShowGuestForm(true);
      }
    });
  }, [checkAccessType]);

  return (
    <BackgroundE4Z title=''>
      <div className='w-full text-center space-y-2 mt-5 z-10'>
        {(!showGuestForm || query.get("host") === "true") && (
          <div className='flex justify-center pb-4'>
            <button onClick={handleLogin} className='btn btn-primary btn-blue btn-md'>
              {t("login_here")}
            </button>
          </div>
        )}
        {showGuestForm && query.get("host") !== "true" && (
          <>
            <h4 className='text-white pt-2 pb-2'>{t("enter_as_a_guest")}</h4>
            <div className='flex justify-center space-x-4'>
              <input
                type='text'
                value={username}
                onChange={handleUsername}
                onKeyDown={handleSubmit}
                required
                className='h-10 rounded-full w-[35%] border-2 appearance-none text-xs border-gray-300 placeholder-gray-300 font-light focus:outline-none focus:bg-white focus:border-gray-500'
              />
              <button onClick={handleSubmit} id='submit' className='btn btn-primary btn-blue'>
                {t("join")}
              </button>
            </div>
          </>
        )}
      </div>
    </BackgroundE4Z>
  );
}
