import { observer } from "mobx-react-lite";
import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useUserStore, useHandStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

const RaiseHand: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const handStore = useHandStore();

  const handRaised = handStore.isLocalHandRaised();

  const onRaiseHand = async () => {
    if (userStore.isLocalHost()) return;

    if (handRaised) {
      handStore.localLowerHand();
    } else {
      handStore.localRaiseHand();
    }
  };

  const className = classNames("cursor-pointer flex flex-col items-center justify-between", {
    "text-green": handRaised,
    "text-gray-250": !handRaised,
  });

  return (
    <div className={className} onClick={onRaiseHand}>
      <Icons.Hand className='h-7' />
      <span className='text-xs text-center leading-4'>
        {handRaised ? t("lower_hand") : t("raise_hand")}
      </span>
    </div>
  );
});

export default RaiseHand;
