import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRoomStore, useUserStore, useIncidenceStore, useModalStore } from "../../../hooks/store";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import dateHelper from "../../../lib/date";
import SessionService from "../../../services/session/sessions.service";
import { Incidence } from "../../../stores/app/room/incidence";
import HostChat from "../../Chat/HostChat/HostChat";
import Incidences from "../../Incidences/Incidences";
import Participants from "../../Participants/Participants";
import UserPlayer from "../../Players/UserPlayer/UserPlayer";
import Tabs, { TabsName } from "../Tabs/Tabs";

const HostSideBar: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const incidenceStore = useIncidenceStore();

  const active = roomStore.activeTab;

  const changeActiveTab = (tab: TabsName) => {
    roomStore.setActiveTab(tab);
    if (tab === "events") {
      incidenceStore.markRead();
    }
  };

  const onOpenInviteModal = async () => {
    modalStore.openModal("INVITE", {
      examId: roomStore.info.exam.id,
      passcode: roomStore.info.exam.passcode,
    });
  }

  const getIncidences = async () => {
    const incidencesFromBackend = await SessionService.getIncidences(
      roomStore.info.exam.id,
      roomStore.info.exam.sessionId,
    );
    const incidences: Incidence[] = [];
    incidencesFromBackend.forEach((incidence) => {
      incidences.push({
        id: incidence.id,
        uid: incidence.agoraUserId,
        type: incidence.incidenceType,
        message: incidence.message,
        creationDate: dateHelper.utcToUserTimezone(incidence.creationDate),
        read: true,
      });
    });
    incidenceStore.setIncidences(incidences);
  };

  useEffectOnce(() => {
    getIncidences();
  });

  return (
    <div className='flex flex-col w-64 h-full'>
      {!_.isNil(userStore.host) && (
        <UserPlayer user={userStore.host} className='w-full h-48 min-h-[12rem]' focus='PARTICIPANT' />
      )}
      <div className='flex flex-col flex-1 pt-4'>
        <Tabs active={active} onChange={changeActiveTab} />
        <div className='pt-2 h-full overflow-scroll h-[calc(100vh-20rem)]'>
          {active === "participants" && <Participants />}
          {active === "chat" && <HostChat />}
          {active === "events" && <Incidences />}

          {active === "participants" &&
          <div className='p-2 border-t'>
            <div className='px-4 py-1 border border-gray-600 rounded-lg inline-block text-sm text-gray-600 font-medium cursor-pointer' onClick={onOpenInviteModal}>
              { t("invite") }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
});

export default HostSideBar;
