import React from "react";

import ExamComponent from "../../Exam/Exam";
import RoomWrapper from "../../Room/Room";
import { useOutletContext } from "react-router-dom";
import { ContextRoom } from "../../../types";

export default function Start() {
  const { exam, user } = useOutletContext<ContextRoom>();

  return (
    <RoomWrapper exam={exam} user={user} route='Exam'>
      <ExamComponent />
    </RoomWrapper>
  );
}
