import RoomStore from ".";
import { Resetable } from "../../interfaces/resetable";
import { action, makeObservable, observable } from "mobx";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import * as cocossd from "@tensorflow-models/coco-ssd";
import UserStore from "./user";
import ExamsLogger from "../../../logger";
import { DetectedObject } from "@tensorflow-models/coco-ssd";
import mitt, { Emitter } from "mitt";
import { EventEmitted, eventEmitter } from "../../../services/util/eventEmitter";
import { ExamIncidenceType } from "../../../types/enum";


export type ObjectDetected = {
  class: string;
  creationDate: string;
  score?: number;
  count?: number;
};

const logPrefix = "[Detection Store]";

class DetectionStore implements Resetable {
  roomStore!: RoomStore;
  emitter: Emitter<{ [K in string]: EventEmitted }> | null;

  @observable
  objects: ObjectDetected[] = [];

  detectedObjects: ObjectDetected[] = [];

  started = false;

  video: HTMLVideoElement;
  detector: cocossd.ObjectDetection;

  constructor(room: RoomStore,  emitter: Emitter<{ [K in string]: EventEmitted }> | null = null) {
    makeObservable(this);
    this.roomStore = room;
    this.emitter = emitter || mitt();
    this.registerStartExamListener();
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  async initDetection(): Promise<void> {
    this.detector = await cocossd.load();
  }

  @action
  async startDetection(): Promise<void> {
    if (this.shouldSkipDetection()) {
      return;
    }
  
    await this.initDetection();
    this.started = true;

    const detectInterval = 2 * 1000;
    const id = this.userStore.localUser.videoTrack?.getTrackId();
    const video = document.getElementById(`video_${id}`) as HTMLVideoElement;
    const objects = {"bottle": 2, "laptop": 5, "remote": 1, "keyboard": 1, "cell phone": 10, "book": 10};
    const timeoutDuration = 1.5 * 60 * 1000;
  
    const performDetection = async () => {
      if (this.isVideoReady(video)) {
        const detectObj: DetectedObject[] = await this.detector.detect(video);
        this.processDetectedObjects(detectObj, objects, timeoutDuration);
      }
  
      setTimeout(() => {
        (async () => {
          await performDetection();
        })();
      }, detectInterval);
    };
  
    performDetection();
  }


  @action
  reset(): void {
    this.objects = [];
  }
  
  private shouldSkipDetection(): boolean {
    return this.roomStore.userStore.isLocalHost() || !this.roomStore.isDetectionEnabled;
  }

  private isVideoReady(video: HTMLVideoElement | undefined | null): boolean {
    return typeof video !== "undefined" && video !== null && video.readyState === 4;
  }

  private processDetectedObjects(detectedObjects: DetectedObject[], objects: Record<string, number>, timeoutDuration: number): void {
    detectedObjects.forEach(obj => {
      const alreadyDetected = this.detectedObjects.find(o => o.class === obj.class) || false;
      const detectionThreshold = alreadyDetected && alreadyDetected.count > objects[alreadyDetected.class];
      const timedOut = alreadyDetected && (Date.now() - parseInt(alreadyDetected?.creationDate, 10) < timeoutDuration);
  
      if (obj.score > 0.8 && Object.keys(objects).includes(obj.class) && !detectionThreshold && !timedOut) {
        this.createIncidence(obj);
        this.updateOrAddDetectedObject(alreadyDetected, obj);
      }
    });
  }

  private createIncidence(obj: DetectedObject): void {
    const incidentData = {
      examId: this.roomStore.info.exam.id,
      sessionId: this.roomStore.info.exam.sessionId,
      userId: this.roomStore.info.user.id,
      type: ExamIncidenceType.OBJECT_DETECTED,
      guest: this.roomStore.info.user.guest,
      message: obj.class,
    };
    this.roomStore.incidenceStore.createIncidence(incidentData);
  }

  private updateOrAddDetectedObject(alreadyDetected: ObjectDetected | boolean, obj: DetectedObject): void {
    if (alreadyDetected && typeof(alreadyDetected) != "boolean") {
      alreadyDetected.count++;
      alreadyDetected.creationDate = Date.now().toString();
    } else {
      this.detectedObjects.push({ class: obj.class, creationDate: Date.now().toString(), count: 1 });
    }
  }

  private registerStartExamListener() {
    eventEmitter.on("startExam", () => {
      if(!this.started) {
        this.startDetection();
        ExamsLogger.info(logPrefix, "startExam event detected in DetectionStore");
      }
    });
  }

}

export default DetectionStore;