import Select, { GroupBase, StylesConfig } from "react-select";
import React from "react";

type Option = { value: string; label: string };

type Mode = "DARK" | "LIGHT";

type Props = {
  value: Option;
  onChange: (option: Option) => void;
  options: Option[];
  mode: Mode;
  className?: string;
};

// FIXME: Replace this values for tailwind theme colors
const lightGray = "#D4D4D4";
const gray = "#454545";
const blue = "#212121";

const lightCustomStyles: Partial<StylesConfig<Option, false, GroupBase<Option>>> = {
  control: (provided) => ({
    ...provided,
    border: `1px solid ${blue}`,
    borderRadius: "none",
    cursor: "pointer",
    minHeight: "none",
    fontSize: "12px",
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${blue}`,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: "100%",
    color: blue,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
  }),
  indicatorSeparator: () => ({
    // Nothing so no styles
  }),
  placeholder: (provided) => ({
    ...provided,
    color: blue,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "4px",
    color: blue,
    "&:hover": {
      color: blue,
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    borderRadius: "none",
    border: `1px solid ${blue}`,
    borderTop: "white",
    margin: 0,
    fontSize: "12px",
  }),
  menuList: () => ({
    // Nothing so no styles
  }),
  option: (provided, state) => ({
    ...provided,
    display: "inline-block",
    whiteSpace: "initial",
    cursor: "pointer",
    color: blue,
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: "white",
    fontWeight: state.isSelected ? "bold" : "normal",
    maxWidth: "100%",
    "&:active": {
      backgroundColor: "white",
    },
  }),
};

const darkCustomStyles: Partial<StylesConfig<Option, false, GroupBase<Option>>> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: gray,
    border: `1px solid ${gray}`,
    borderRadius: "2px",
    cursor: "pointer",
    minHeight: "none",
    fontSize: "12px",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: lightGray,
    maxWidth: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
  }),
  indicatorSeparator: () => ({
    // Nothing so no styles
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "4px",
    color: lightGray,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: gray,
    boxShadow: "none",
    borderRadius: "none",
    margin: 0,
    fontSize: "12px",
  }),
  menuList: () => ({
    backgroundColor: gray,
  }),
  option: (provided, state) => ({
    ...provided,
    display: "inline-block",
    whiteSpace: "initial",
    cursor: "pointer",
    color: lightGray,
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: gray,
    fontWeight: state.isSelected ? "bold" : "normal",
    maxWidth: "100%",
  }),
};

const styles: Record<Mode, unknown> = {
  DARK: darkCustomStyles,
  LIGHT: lightCustomStyles,
};

const Dropdown: React.FunctionComponent<Props> = ({
  options,
  onChange,
  value,
  mode,
  className,
}: Props) => {
  const customStyles = styles[mode];
  return (
    <Select
      className={className}
      isSearchable={false}
      styles={customStyles}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default Dropdown;
