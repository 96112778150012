import { action, makeAutoObservable, observable } from "mobx";
import moment from "moment-timezone";
import AppStore from "..";
import { eraseCookie, setCookie } from "../../../lib/cookies";
import dateHelper from "../../../lib/date";
import AccountService from "../../../services/account.service";
import SessionGuestService, * as sessionsGuestService from "../../../services/session/sessions.guest.service";
import SessionService, * as sessionsService from "../../../services/session/sessions.service";
import { sec } from "../../../services/util/security";
import type { Account, ID, RoomExam, RoomUser } from "../../../types";
import { ExamsUserRole } from "../../../types/enum";
import { Resetable } from "../../interfaces/resetable";
import * as Sentry from "@sentry/react";

export enum ExamSessionErrors {
  LOGIN = 100,
  WAITING_LOUNGE,
  UNAUTHORIZED,
  NOT_FOUND,
  NOT_OPEN,
  PASSCODE_REQUIRED,
  EXAM_ACCESS_TYPE_LINK_WRONG_PASSWORD,
  GUEST_USERNAME_REQUIRED,
  SERVER_ERROR,
  NOT_OPEN_NOT_USER_SESSION,
  EXAM_SESSION_USER_BANNED,
  SESSION_NOT_ADMIT_MORE_PARTICIPANT,
  EXAM_NOT_IN_OPENED,
}

class AuthorizationStore implements Resetable {
  appStore!: AppStore;

  sessionResult: sessionsService.SessionResponse = null;

  startResult: sessionsService.SessionStartResponse = null;

  roomResult: sessionsService.SessionRoomInfoResponse = null;

  examInfo: sessionsGuestService.ExamInfo = null;

  @observable
  roomExam: RoomExam = null;

  @observable
  error: ExamSessionErrors = null;

  @observable
  roomUser: RoomUser = null;

  @observable
  accountId: ID = null;

  password: string = null;

  constructor(app: AppStore) {
    makeAutoObservable(this);
    this.appStore = app;
    this.password = localStorage.getItem("password") ?? null;
  }

  reset(): void {
    this.sessionResult = null;
    this.startResult = null;
    this.roomResult = null;
    this.roomExam = null;
    this.error = null;
    this.password = null;
    this.roomUser = null;
  }

  @action
  async getExamInfo(id: ID, accountId: ID): Promise<sessionsGuestService.ExamInfo> {
   const result =  await SessionGuestService.getExamInfo(id, accountId);
   this.setExamInfo(result);
   return result;
  }

  @action
  async getSession(id: ID, guest?: boolean): Promise<void> {
    if (guest) {
      this.setSessionResult(await SessionGuestService.get(id, this.roomUser.id));
    } else {
      this.setSessionResult(await SessionService.get(id));
    }

    this.setRoomUser({...this.roomUser, id: this.sessionResult.userId})

    if (this.sessionResult.examState !== "OPEN") {
      this.error = ExamSessionErrors.NOT_OPEN;
      this.transformSessionRoomToExamRoom();
      return;
    }

    if (this.sessionResult.inWaitingLounge) {
      this.error = ExamSessionErrors.WAITING_LOUNGE;
    }

    if (!this.password && this.sessionResult.passCodeEnable && !this.sessionResult.supervisor) {
      this.error = ExamSessionErrors.PASSCODE_REQUIRED;
    }
  }

  @action
  setAccoundId(id: ID) {
    this.accountId = id;
  }

  @action
  async getSessionLink(id: ID, guest?: boolean): Promise<void> {
    if (guest) {
      this.setSessionResult(
        await SessionGuestService.link(
          id,
          navigator.language,
          moment.tz.guess(),
          this.roomUser.name,
          this.password,
        ),
      );
    } else {
      this.setSessionResult(await SessionService.link(id, this.password));
    }

    this.setRoomUser({ ...this.roomUser, id: this.sessionResult.userId });

    if (this.sessionResult.examState && this.sessionResult.examState !== "OPEN") {
      this.error = ExamSessionErrors.NOT_OPEN;
      return;
    }

    if (this.sessionResult.inWaitingLounge) {
      this.error = ExamSessionErrors.WAITING_LOUNGE;
    }

    if (!this.password && this.sessionResult.passCodeEnable && !this.sessionResult.supervisor) {
      this.error = ExamSessionErrors.PASSCODE_REQUIRED;
    }
  }

  @action
  async getSessionLinkProctor(id: ID): Promise<void> {
    this.setSessionResult(await SessionService.proctorLink(id));

    if (this.sessionResult.examState && this.sessionResult.examState !== "OPEN") {
      this.error = ExamSessionErrors.NOT_OPEN;
      return;
    }

    if (this.sessionResult.inWaitingLounge) {
      this.error = ExamSessionErrors.WAITING_LOUNGE;
    }

    if (!this.password && this.sessionResult.passCodeEnable && !this.sessionResult.supervisor) {
      this.error = ExamSessionErrors.PASSCODE_REQUIRED;
    }
  }

  @action
  async sessionStart(id: ID, guest?: boolean): Promise<void> {
    if (guest) {
      try {
        this.startResult = await SessionGuestService.start(
          id,
          this.sessionResult.id,
          this.roomUser.id,
          this.password,
        );
      } catch (error) {
        if (error.message === "Request failed with status code 500") {
          localStorage.removeItem("user");
          localStorage.removeItem("password");
          localStorage.removeItem("exam");
          eraseCookie("examId");
          eraseCookie("sessionId");
          eraseCookie("user");
          eraseCookie("server");
          eraseCookie("examHasStarted");
          Sentry.setUser(null);
          window.location.reload();
        }
      }
    } else {
      this.startResult = await SessionService.start(id, this.sessionResult.id, this.password);
    }
    this.setRoomUser({
      ...this.roomUser,
      agoraUserId: this.startResult.agoraUserId,
    });
  }

  @action
  async getRoomInfo(id: ID, guest?: boolean): Promise<void> {
    if (guest) {
      this.roomResult = await SessionGuestService.getRoom(
        id,
        this.sessionResult.id,
        this.roomUser.id,
      );
    } else {
      this.roomResult = await SessionService.getRoom(id, this.sessionResult.id);
    }
  }

  @action
  async getAccount(accountId: string): Promise<Account> {
    return await AccountService.get(accountId);
  }

  @action
  async checkAccessControl(accountId: ID, id: ID, ltk?: string, lms?: string) {
    try {
      this.setAccoundId(accountId);
      this.error = null;
      if (sec.getAuthUser() || ltk || lms) {
        await this.authenticatedAccessFlow(id, ltk);
      } else {
        const examIdFromlocalStorage = localStorage.getItem("exam");
        if (examIdFromlocalStorage && examIdFromlocalStorage !== id) {
          localStorage.removeItem("user");
          localStorage.removeItem("password");
          localStorage.removeItem("exam");
          eraseCookie("examId");
          eraseCookie("sessionId");
          eraseCookie("user");
          eraseCookie("server");
          eraseCookie("examHasStarted");
          Sentry.setUser(null);
          window.location.reload();
        }
        const user = JSON.parse(localStorage.getItem("user"));

        if (user) {
          this.setRoomUser(user);
        }
        await this.guestAcccessFlow(id);
      }
      this.transformSessionRoomToExamRoom();
    } catch (exception) {
      this.handleHttpErrors(exception);
    }
  }

  async authenticatedAccessFlow(id: ID, ltk?: string) {
    if (!this.sessionResult) {
      await this.getSession(id);
    }

    if (this.error) {
      return;
    }

    if (!this.sessionResult.id) {
      if (ltk && this.roomUser.role === ExamsUserRole.HOST) {
        await this.getSessionLinkProctor(id);
      } else if (
        this.sessionResult.accessType === "LINK" &&
        !this.sessionResult.supervisor &&
        !this.sessionResult.userId
      ) {
        await this.getSessionLink(id);
      }
    }

    if (this.error) {
      return;
    }

    if (this.sessionResult.state === "FINISHED") {
      if (!window.location.href.includes("finish") && !window.location.href.includes("feedback")) {
        const search = new URL(document.location.toString()).searchParams;
        window.location.href = `/exams/${id}/finish?${search}`;
      }
      return;
    }

    if (!this.startResult) {
      await this.sessionStart(id);
    }

    if (!this.roomResult) {
      await this.getRoomInfo(id);
    }
  }

  async guestAcccessFlow(id: ID) {
    if (!this.roomUser?.name) {
      this.error = ExamSessionErrors.GUEST_USERNAME_REQUIRED;
      return;
    }

    if (!this.roomUser.id) {
      await this.getSessionLink(id, true);
    }

    if (!this.error) {
      await this.getSession(id, true);
    }
    if (this.error && this.error != ExamSessionErrors.WAITING_LOUNGE) {
      return;
    }

    if (this.sessionResult.state === "FINISHED") {
      if (!window.location.href.includes("finish") && !window.location.href.includes("feedback")) {
        const search = new URL(document.location.toString()).searchParams;
        window.location.href = `/exams/${id}/finish?${search}`;
      }
      return;
    }

    if (!this.startResult) {
      await this.sessionStart(id, true);
    }

    if (!this.roomResult) {
      await this.getRoomInfo(id, true);
    }
  }

  transformSessionRoomToExamRoom(): void {
    const roomExam = {
      id: this.sessionResult?.examId || null,
      sessionId: this.sessionResult?.id || null,
      agoraId: this.startResult?.agoraId || null,
      name: this.roomResult?.name || null,
      fullname: this.roomResult?.username || null,
      lmsURL: this.roomResult?.lmsURL || null,
      recordEnabled: this.roomResult?.recording || false,
      endTime:
        (this.roomResult?.endTime && dateHelper.utcToUserTimezone(this.roomResult?.endTime)) ||
        null,
      isRecording: this.roomResult?.userRecordingCamera,
      identityRegistration: this.roomResult?.identityRegistration || false,
      sentimentEnabled: this.roomResult?.sentimentEnabled || false,
      recognitionEnabled: this.roomResult?.recognitionEnabled || false,
      attentionEnabled: this.roomResult?.attentionEnabled || false,
      supervisorFinishedWaitingRoom: this.roomResult?.supervisorFinishedWaitingRoom || false,
      hasStarted:
        (this.sessionResult?.unattended
          ? this.sessionResult?.state === "STARTED"
          : this.roomResult?.manualStarted) || false,
      disableAudio: this.roomResult?.disableAudio || false,
      uploadFiles: this.sessionResult?.uploadFiles || false,
      takeScreenshot: this.roomResult?.takeScreenshot || false,
      // BUG: test this for waiting lounge
      startDate: dateHelper.utcToUserTimezone(this.sessionResult?.examStartDate) || null,
      isUnattended: this.sessionResult?.sessionType === "UNATTENDED",
      instructions: this.sessionResult?.academicInstructions || null,
      feedbackQuestions: this.roomResult?.feedback || this.sessionResult?.feedback || null,
      userRecording: this.roomResult?.userRecordingCamera,
      screenSharing: this.roomResult?.screenSharing,
      passcode: this.roomResult?.passcode,
      groupSession: this.sessionResult.groupSession,
      notifyParticipants: this.roomResult?.notifyParticipants || false,
      objectDetection: this.roomResult?.objectDetection || false,
    };

    this.setRoomExam(roomExam);
    setCookie("examHasStarted", this.roomResult?.manualStarted ? "true" : "false", 1);
  }

  @action
  handleHttpErrors(exception) {
    if (exception.response) {
      if (exception.response.status === 412) {
        if (exception.response.data.code === "EXAM_ACCESS_TYPE_LINK_WRONG_PASSWORD") {
          this.error = ExamSessionErrors.EXAM_ACCESS_TYPE_LINK_WRONG_PASSWORD;
        } else if (exception.response.data.code === "SESSION_IS_IN_WAITING_LOUNGE") {
          this.error = ExamSessionErrors.WAITING_LOUNGE;
          // eslint-disable-next-line no-empty
        } else if (exception.response.data.code === "EXAM_SESSION_USER_ALREADY_EXIST") {
        } else if (exception.response.data.code === "EXAM_NOT_IN_OPENED") {
          this.error = ExamSessionErrors.EXAM_NOT_IN_OPENED;
        } else if (exception.response.data.code === "EXAM_SESSION_NOT_IN_OPENED") {
          const messageArray = exception.response.data.message.split(", ");
          const examState = messageArray[messageArray.length - 1];
          if (examState === "CLOSED") {
            this.error = ExamSessionErrors.NOT_OPEN_NOT_USER_SESSION;
          }
        } else if (exception.response.data.code === "SESSION_NOT_ADMIT_MORE_PARTICIPANT") {
          this.error = ExamSessionErrors.SESSION_NOT_ADMIT_MORE_PARTICIPANT;
        }
         else {
          this.error = ExamSessionErrors.NOT_FOUND;
        }
      }

      if (exception.response.status === 404) {
        if (exception.response.data.code === "EXAM_SESSION_USER_BANNED") {
          this.error = ExamSessionErrors.EXAM_SESSION_USER_BANNED;
        } else {
          this.error = ExamSessionErrors.NOT_FOUND;
        }
      }

      if (exception.response.status === 403) {
        this.error = ExamSessionErrors.UNAUTHORIZED;
      }

      if (exception.response.status === 401) {
        this.error = ExamSessionErrors.LOGIN;
      }

      if (exception.response.status >= 500) {
        this.error = ExamSessionErrors.SERVER_ERROR;
      }
    }
  }

  @action
  async setRoomUser(user: RoomUser) {
    localStorage.setItem("user", JSON.stringify(user));
    // REFACTOR: handle authentication and cookies better
    setCookie("examId", this.sessionResult?.examId?.toString() || null, 1);
    setCookie("sessionId", this.sessionResult?.id?.toString() || null, 1);
    setCookie(
      "user",
      JSON.stringify({
        id: user.id,
        account: user.account,
        role: user.role,
        guest: user.guest,
        agoraUserId: user.agoraUserId,
        name: user.name,
      }),
      1,
    );
    setCookie("server", process.env.REACT_APP_API_BASE_URL, 1);
    if (!user.guest) {
      const token = await sec.getAccessTokenSilently()();
      setCookie("token", token, 1);
    }

    Sentry.setUser({id: user.id?.toString(), username: user.name})
    this.roomUser = user;
  }

  @action
  setRoomExam(exam: RoomExam) {
    this.roomExam = exam;
  }

  @action
  setExamInfo(info: sessionsGuestService.ExamInfo) {
    this.examInfo = info;
  }

  @action
  setSessionResult(sessionResult: sessionsService.SessionResponse) {
    const session = {
      academicInstructions:
        sessionResult?.academicInstructions || this.sessionResult?.academicInstructions,
      accessType: sessionResult?.accessType || this.sessionResult?.accessType,
      banned: sessionResult?.banned || this.sessionResult?.banned,
      examId: sessionResult?.examId || this.sessionResult?.examId,
      examStartDate: sessionResult?.examStartDate || this.sessionResult?.examStartDate,
      examState: sessionResult?.examState || this.sessionResult?.examState,
      id: sessionResult?.id || this.sessionResult?.id,
      inWaitingLounge: sessionResult?.inWaitingLounge || this.sessionResult?.inWaitingLounge,
      sessionType: sessionResult?.sessionType || this.sessionResult?.sessionType,
      state: sessionResult?.state || this.sessionResult?.state,
      supervisor: sessionResult?.supervisor || this.sessionResult?.supervisor,
      unattended: sessionResult?.unattended || this.sessionResult?.unattended,
      passCodeEnable: sessionResult?.passCodeEnable || this.sessionResult?.passCodeEnable,
      userId: sessionResult?.userId || this.sessionResult?.userId,
      feedback: sessionResult?.feedback || this.sessionResult?.feedback,
      uploadFiles: sessionResult?.uploadFiles || this.sessionResult?.uploadFiles,
      defaultFeedback: sessionResult?.defaultFeedback || this.sessionResult?.defaultFeedback,
      groupSession: sessionResult?.groupSession || this.sessionResult?.groupSession,
    };

    this.sessionResult = session;
  }
}

export default AuthorizationStore;
