import React, { useEffect, useState } from "react";

import { useRoomStore } from "../../../../../hooks/store";
import SessionService from "../../../../../services/session/sessions.service";
import { ExamsUser } from "../../../../../types";
import IdentityBlock from "../../../../IdentityValidation/IdentityBlock";
import UserPlayer from "../../../../Players/UserPlayer/UserPlayer";
import RoundedCard from "../../../../RoundedCard/RoundedCard";

import styles from "./OneOnOneRender.module.scss";

type Props = {
  user: ExamsUser;
};


const OneOnOneRender: React.FunctionComponent<Props> = ({ user }: Props) => {
  const [identityData, setIdentityData] = useState({
    docCheck: { extracted: {}, documentDataChecking: [], status: "denied" },
    docImg: "",
    faceMatchingImg: "",
    overallResult: {},
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const roomStore = useRoomStore();
  const { identityRegistration } = roomStore.info.exam;

  useEffect(() => {
    if (identityRegistration) {
      SessionService.getIdValidationResult(
        roomStore.info.exam.id,
        roomStore.info.exam.sessionId,
        user.uid,
      ).then((response) => {
        setIdentityData({
          docCheck: response.docCheck,
          docImg: response.docImage,
          faceMatchingImg: response.faceMatchingImg,
          overallResult: response.overallResult,
        });
        setSuccess(true);
        setLoading(false);
      });
    }
  }, [roomStore.info.exam.id, user, identityRegistration, roomStore.info.exam.sessionId]);

  return (
    <div
      className={
        roomStore.info.exam.screenSharing
          ? styles.grid_wrapper
          : styles.grid_wrapper_no_share_screen
      }
    >
      {roomStore.info.exam.screenSharing && (
        <div className={identityRegistration ? styles.viewer : styles.viewer_full}>
          <RoundedCard className='h-full'>
            <UserPlayer user={user} className='h-full' focus='EXAM' object_fit_contain />
          </RoundedCard>
        </div>
      )}
      <div className={identityRegistration ? styles.viewer : styles.viewer_full}>
        <RoundedCard className='h-full'>
          <UserPlayer
            user={user}
            className='h-full'
            hideName
            focus='PARTICIPANT'
            object_fit_contain
          />
        </RoundedCard>
      </div>
      {identityRegistration ? (
        <>
          <div className={styles.identity}>
            <RoundedCard className='h-full'>
              {loading ? null : (
                <IdentityBlock
                  overallResult={identityData.overallResult}
                  docCheck={identityData.docCheck}
                  username={user.username}
                />
              )}
            </RoundedCard>
          </div>
          <div className={styles.photo}>
            <RoundedCard className='h-full'>
              {!success ? null : <img src={identityData.faceMatchingImg} alt='img' />}
            </RoundedCard>
          </div>
          <div className={styles.photo}>
            <RoundedCard className='h-full'>
              {!success ? null : <img src={identityData.docImg} alt='img' />}
            </RoundedCard>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OneOnOneRender;
