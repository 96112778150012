/* eslint-disable @typescript-eslint/ban-ts-comment */
import AgoraRTC, * as agoraRtcSdkNg from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import { action, computed, makeObservable, observable } from "mobx";

import ExamsLogger from "../../../logger";
import { Resetable } from "../../interfaces/resetable";
import AppStore from "..";
import type { JoinOptions, RoomExam } from "../../../types";
import UserStore from "../room/user";
import compare from "../../../lib/compare";

import RTCStore from ".";
import { ExamIncidenceType, ExamsUserRole } from "../../../types/enum";
import i18n from "../../../services/i18n/config";

const logPrefix = "[Screen Store]";

class ScreenStore implements Resetable {
  rtcStore!: RTCStore;

  client: agoraRtcSdkNg.IAgoraRTCClient = null;

  @observable
  localUid: agoraRtcSdkNg.UID = null;

  @observable
  localVideoTrack: agoraRtcSdkNg.ILocalVideoTrack = null;

  @observable
  joined = false;

  @observable
  remoteUsers: Record<agoraRtcSdkNg.UID, agoraRtcSdkNg.IAgoraRTCRemoteUser> = {};

  constructor(rtc: RTCStore) {
    makeObservable(this);
    this.rtcStore = rtc;
  }

  get appStore(): AppStore {
    return this.rtcStore.appStore;
  }

  get userStore(): UserStore {
    return this.appStore.roomStore.userStore;
  }

  @computed
  get callOptions(): JoinOptions {
    return this.rtcStore.callOptions;
  }

  @computed
  get exam(): RoomExam {
    return this.appStore.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.localVideoTrack = null;
    this.localUid = null;

    this.client.removeAllListeners();
    this.client = null;
    this.joined = false;
  }

  @action
  init(): void {
    if (!_.isNil(this.client)) return;

    this.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
  }

  @action
  setJoined(val: boolean): void {
    this.joined = val;
  }

  @action
  setLocalUid(uid: agoraRtcSdkNg.UID): void {
    this.localUid = uid;
  }

  @action
  setLocalVideoTrack(track: agoraRtcSdkNg.ILocalVideoTrack): void {
    this.localVideoTrack = track;
  }

  @action
  async createLocalTracks(): Promise<void> {
    let screen: agoraRtcSdkNg.ILocalVideoTrack;
  
    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        screen = (await AgoraRTC.createScreenVideoTrack({
          screenSourceType: "screen",
          encoderConfig: "720p",
          optimizationMode: "detail"
        })) as agoraRtcSdkNg.ILocalVideoTrack;
    
        // Handle case where 'displaySurface' is not defined
        // @ts-ignore
        if (screen.getMediaStreamTrack().getSettings().displaySurface === "browser") {
          window.location.reload();
        }
        // @ts-ignore
      } while (screen.getMediaStreamTrack().getSettings().displaySurface !== "monitor");
  
      screen.on("track-ended", () => {
        this.sendAlertScreenUnshared();
      });
  
      this.setLocalVideoTrack(screen);
    } catch (error) {
      console.error("Error creating screen video track:", error);
  
      if (error.code === "PERMISSION_DENIED") {
        if (navigator.userAgent.toUpperCase().includes("MAC")) {
          window.location.href = "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture";
        } else {
          this.appStore.uiStore.toast.addToast("Please grant screen sharing permission to use this feature.", "WARNING");
        }
      } else {
        this.appStore.uiStore.toast.addToast("An error occurred while attempting to share the screen.", "WARNING");
      }
    }
  }
  

  @action
  async sendAlertScreenUnshared(): Promise<void> {
    if (this.appStore.authorizationStore.roomUser.role === ExamsUserRole.HOST) {
      return;
    }

    if(this.localVideoTrack) {
      await this.client.unpublish(this.localVideoTrack);
      this.localVideoTrack = null;
    }
    const screenSharingStopped = {
      examId: this.exam.id,
      sessionId: this.exam.sessionId,
      userId: this.appStore.roomStore.info.user.id,
      type: ExamIncidenceType.STOP_SHARING_DESKTOP,
      guest: this.appStore.authorizationStore.roomUser.guest,
    };
    this.appStore.roomStore.incidenceStore.createIncidence(screenSharingStopped);
  }

  @action
  async startCall(options: JoinOptions): Promise<void> {
    ExamsLogger.info(logPrefix, "Starting  call: ", options);

    this.init();

    await this.client.join(
      this.appStore.appId,
      `${options.channel}s`, // Joining separate channel so we can use the same uid
      options.token ?? null,
      options.uid,
    );

    this.setLocalUid(options.uid);

    this.listenEvents();

    if (this.userStore.isLocalHost()) return;
    await this.shareScreen();
  }

  @action
  async shareScreen(): Promise<void> {
    try {
      await this.createLocalTracks();
    } catch (e) {
      this.sendAlertScreenUnshared();
      
      if(this.localVideoTrack) {
        await this.client.unpublish(this.localVideoTrack);
      }
    }

    await this.client.setClientRole("host");
    if(this.localVideoTrack) {
      await this.client.publish(this.localVideoTrack);
    } else {
      ExamsLogger.info(logPrefix, "Screen Sharing Error");
      this.appStore.uiStore.toast.addToast(i18n.t("stopped_sharing_screen"), "WARNING");
    }
  }

  @action
  async leaveCall(): Promise<void> {
    ExamsLogger.info(logPrefix, "Leaving call: ", this.callOptions);

    this.localVideoTrack.close();

    await this.client.leave();

    this.setJoined(false);
  }

  private listenEvents(): void {
    if (!this.userStore.isLocalHost()) return;

    this.client.on("user-left", (user) => {
      ExamsLogger.info(logPrefix, "user-left", user);
      this.removeRemoteUser(user.uid);
    });

    this.client.on("user-published", async (user, media) => {
      ExamsLogger.info(logPrefix, "user-published", media, user);
      if (media === "video") {
        await this.client.subscribe(user, "video");
        this.setRemoteUser(user);
      }
    });

    this.client.on("user-unpublished", async (user, media) => {
      ExamsLogger.info(logPrefix, "user-unpublished", media, user);
      if (media === "video") {
        this.removeRemoteUser(user.uid);
      }
    });
  }

  isLocal(uid: agoraRtcSdkNg.UID): boolean {
    return compare.equalAgoraUID(this.localUid, uid);
  }

  @action
  setRemoteUser(user: agoraRtcSdkNg.IAgoraRTCRemoteUser): void {
    this.remoteUsers[user.uid] = user;
  }

  @action
  removeRemoteUser(uid: agoraRtcSdkNg.UID): void {
    delete this.remoteUsers[uid];
  }
}

export default ScreenStore;
