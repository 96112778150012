import { observable, action, makeObservable } from "mobx";
import { v4 as uuid } from "uuid";
import _ from "lodash/fp";

import { Resetable } from "../../interfaces/resetable";
import ExamsLogger from "../../../logger";

import UIStore from ".";

export type ToastType = "ERROR" | "WARNING" | "INFO" | "GROUP_SESSION" | "NON_GROUP_SESSION"

type Toast = {
  id: string;
  message: string;
  type?: ToastType;
  action?: () => void;
  actionText?: string;
};

const logPrefix = "[Toast Store]";
class ToastStore implements Resetable {
  uiStore: UIStore;

  @observable
  toastQueue: Toast[] = [];

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  @action
  addToast(message: string, type?: ToastType, action?: () => void, actionText?: string): void {
    if (this.toastQueue.find(t => t.message === message)) {
      return;
    }
    ExamsLogger.info(logPrefix, "Adding toast: ", message);
    this.toastQueue = _.concat(this.toastQueue, {
      id: uuid(),
      message,
      type,
      action,
      actionText,
    });
  }

  @action
  removeToast(id: string): void {
    ExamsLogger.info(logPrefix, "Removing toast: ", id);
    this.toastQueue = _.reject({ id }, this.toastQueue);
  }

  @action
  reset(): void {
    this.toastQueue = [];
  }
}

export default ToastStore;
