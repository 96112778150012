import React from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import { useAuthorizationStore } from "../../../hooks/store";
import useQuery from "../../../hooks/useQuery";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import Evaluation from "../../Evaluation/Evaluation";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";
import LoadingPage from "../Loading/Loading";

export default function Feedback() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const authorizationStore = useAuthorizationStore();
  const query = useQuery();
  const { search } = useLocation();

  if (!exam.id) {
    return <LoadingPage />;
  }

  if (
    !authorizationStore.sessionResult.defaultFeedback &&
    !authorizationStore.sessionResult.feedback
  ) {
    if (user.guest || query.get("ltk") || query.get("lms")) {
      return <Navigate to={`/exams/${exam.id}/finished${search}`} />;
    } else {
      window.location.href = process.env.REACT_APP_BASE_URL;
    }
  }

  return (
    <>
      <div className='bg-black flex flex-col h-screen'>
        <ParticipantTopBar
          examName={exam.name}
          takeScreenshot={exam.takeScreenshot}
          uploadFiles={exam.uploadFiles}
          recordEnabled={exam.recordEnabled}
          isUnattended={exam.isUnattended}
        />
        <div className='flex flex-row'>
          <Wrapped credentials={ExamCredentials} exam={exam} user={user} route='Feedback' />
          <div className='h-screen' />
          <BottomBarBeforeExam nextURL='' />
        </div>
      </div>
      <Evaluation exam={exam} />
    </>
  );
}
