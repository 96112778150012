import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useChatStore, useIncidenceStore, useUserStore } from "../../../hooks/store";

export type TabsName = "participants" | "chat" | "events";

type Props = {
  active: TabsName;
  onChange: (tab: TabsName) => void;
};

const Tabs: React.FunctionComponent<Props> = observer(({ active, onChange }: Props) => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const chatStore = useChatStore();
  const incidenceStore = useIncidenceStore();

  const activeClass = (tab: TabsName) =>
    tab === active ? "text-blue font-bold border-b-2 border-blue" : "text-gray-400 font-normal";

  const commonClass = "relative block hover:text-blue focus:outline-none pb-1";
  const numberClass = "text-2xs ml-0.5 absolute -top-0.5 left-full";

  return (
    <nav className='flex flex-row bg-white justify-between text-xs pl-4 pr-8'>
      <button
        type='button'
        className={`${commonClass} ${activeClass("participants")}`}
        onClick={() => onChange("participants")}
      >
        {t("participants")}
        <span className={numberClass}>{`(${userStore.totalParticipants})`}</span>
      </button>
      <button
        type='button'
        className={`${commonClass} ${activeClass("chat")}`}
        onClick={() => onChange("chat")}
      >
        {t("chat")}
        <span className={numberClass}>{`(${chatStore.totalUnreadMessages})`}</span>
      </button>
      <button
        type='button'
        className={`${commonClass} ${activeClass("events")}`}
        onClick={() => onChange("events")}
      >
        {t("events")}
        <span className={numberClass}>{`(${incidenceStore.totalIncidences})`}</span>
      </button>
    </nav>
  );
});

export default Tabs;
