import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useRecordStore, useUserStore } from "../../../hooks/store";
import Icons from "../../../images/icons";
import { getCookie } from "../../../lib/cookies";

const Recording: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const recordStore = useRecordStore();

  const examStarted = getCookie("examHasStarted");
  const recording = recordStore.isRecording || examStarted == "true";

  const className = classNames("flex flex-col items-center justify-between", {
    "text-green": recording,
    "text-gray-500": !recording,
  });

  const recordText = recording || userStore.isLocalParticipant() ? t("recording") : t("record");

  return (
    <div className={className}>
      <Icons.Record className='h-6' />
      <span className='text-xs text-center leading-4'>{recordText}</span>
    </div>
  );
});

export default Recording;
