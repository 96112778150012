import { Account, ID } from "../types";
import client from "./client";


export const get = async (accountId: ID): Promise<Account> => {
  const url = "/account-service/v1/accounts/" + accountId;

  const response = await client.get(url);
  return response.data;
};

const AccountService = {
  get,
};

export default AccountService;
