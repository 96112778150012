import React from "react";

import Button, { Props } from "../Button";

const RedButton: React.FunctionComponent<Props> = ({
  children,
  className = "",
  disabled = false,
  onClick,
}: Props) => (
  <Button onClick={onClick} className={`bg-red text-white ${className}`} disabled={disabled}>
    {children}
  </Button>
);

export default RedButton;
