import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import Icons from "../../../images/icons";
import Flash from "../../Flash/Flash";
import Tooltip from "../Tooltip";
import styles from "../Tooltip.module.scss";
import { useLocation, useParams } from "react-router-dom";

type Props = React.PropsWithChildren<{
  examName: string;
  numberOfParticipants: number;
  idValidation: boolean;
  uploadFiles: boolean;
}>;

const HostToolTip: React.FunctionComponent<Props> = ({
  examName,
  numberOfParticipants,
  idValidation,
  uploadFiles,
}: Props) => {
  const { t } = useTranslation();
  const [_message, setMessage] = useState("");
  const [showFlash, setShowFlash] = useState(false);
  const showFlashRef = useRef(showFlash);
  const { search } = useLocation();
  const { id } = useParams();
  const setShowFlashRef = (data: boolean) => {
    showFlashRef.current = data;
    setShowFlash(data);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/exams/${id}/welcome${search}`);
    setShowFlashRef(true);
    setMessage(t("url_copied_successfully"));
    setTimeout(() => {
      setShowFlashRef(false);
    }, 2000);
  };

  return (
    <>
      {showFlash && _message && <Flash show={showFlash} message={_message} />}
      <Tooltip
        place='left'
        tooltipId='host-tooltip'
        content={
          <div>
            <ul>
              <li className='mb-2'>{`· ${examName}`}</li>
              <li className='mb-2'>
                {`· ${numberOfParticipants} ${
                  numberOfParticipants === 1 ? t("participant") : t("participants")
                }`}
              </li>
              <li className='mb-2'>
                · {t("id_validation")}:{" "}
                <span
                  className={idValidation ? "text-[#2DABFF] font-bold" : "text-[#D0021B] font-bold"}
                >
                  {`${idValidation ? t("yes") : t("no")}`}
                </span>
              </li>
              <li className='mb-2'>
                · {t("upload_files")}:{" "}
                <span
                  className={uploadFiles ? "text-[#2DABFF] font-bold" : "text-[#D0021B] font-bold"}
                >
                  {`${uploadFiles ? t("yes") : t("no")}`}
                </span>
              </li>
              <li className='mb-2'>
                ·{t("invite_link")}{" "}
                <span className='text-[#2DABFF]'>
                  <button type='button' onClick={handleCopyLink}>
                    {t("copy_link")}
                  </button>
                </span>
              </li>
            </ul>
          </div>
        }
        offset={"{ 'bottom': 80 }"}
        className={styles.hostTooltip}
      >
        <div className='w-5'>
          <Icons.SecurityBlue />
        </div>
      </Tooltip>
    </>
  );
};

export default HostToolTip;
