import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";

import {
  useUserStore,
  useViewModeStore,
  useChatStore,
  useIncidenceStore,
} from "../../../../hooks/store";
import LeftButton from "../Button/LeftButton/LeftButton";
import RightButton from "../Button/RightButton/RightButton";

import OneOnOneRender from "./OneOnOneRender/OneOnOneRender";

const OneOnOneView: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const userStore = useUserStore();
  const chatStore = useChatStore();
  const incidenceStore = useIncidenceStore();

  const user = userStore.oneOnOneModeParticipant;
  const maxUsers = viewModeStore.getNumUsers("ONE_ON_ONE");
  const enoughUsers = _.size(userStore.participants) > maxUsers;

  const handleDoubleClick = async () => {
    viewModeStore.changeMode("PARTICIPANT");
    chatStore.setSelected(null);
    userStore.listenToAllParticipants();
    await userStore.speakToAllParticipants();
  };

  React.useEffect(() => {
    if (_.isNil(user?.uid)) return;

    chatStore.setSelected(user.uid);
    incidenceStore.setSelected(user.uid);
    userStore.listenToOneParticipant(user.uid);
    userStore.speakToOneParticipant(user.uid);
  }, [user.uid, userStore, chatStore, incidenceStore]);

  if (_.isEmpty(user)) {
    return null;
  }

  return (
    <div className='relative h-full w-full'>
      {enoughUsers && <LeftButton onClick={() => viewModeStore.prev("ONE_ON_ONE")} />}
      <div onDoubleClick={handleDoubleClick} className='h-full w-full'>
        <OneOnOneRender user={user} />
      </div>
      {enoughUsers && <RightButton onClick={() => viewModeStore.next("ONE_ON_ONE")} />}
    </div>
  );
});

export default OneOnOneView;
