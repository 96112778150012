import React from "react";
import { Route, Routes } from "react-router-dom";
import "./stylesheets/application.scss";
import { isChrome, isEdge } from "react-device-detect";
import Welcome from "./components/Pages/Welcome/Welcome";
import Instructions from "./components/Pages/Instructions/Instructions";
import Scheduled from "./components/Pages/Scheduled/Scheduled";
import Requirements from "./components/Pages/Requirements/Requirements";
import Start from "./components/Pages/Start/Start";
import Feedback from "./components/Pages/Feedback/Feedback";
import Finish from "./components/Pages/Finish/Finish";
import Passcode from "./components/Pages/Passcode/Passcode";
import Error from "./components/Pages/Error/Error";
import ProtectedWrapper from "./components/Pages/Protected/ProtectedWrapper";
import UnProtectedWrapper from "./components/Pages/UnProtected/UnProtectedWrapper";
import Registration from "./components/Pages/Registration/Registration";
import Login from "./components/Pages/Login/Login";
import DontTrollWithIncognito from "./components/Pages/DontTrollWithIncognito/DontTrollWithIncognito";
import Finished from "./components/Pages/Finished/Finished";
import Closed from "./components/Pages/Closed/Closed";
import WaitingRoom from "./components/Pages/WaitingRoom/WaitingRoom";
import AccessRejected from "./components/Pages/AccessRejected/AccessRejected";
import NotAdmited from "./components/Pages/NotAdmited/NotAdmited";
import ForceChrome from "./components/Pages/ForceChrome/ForceChrome";
import WaitingScheduled from "./components/Pages/WaitingScheduled/WaitingScheduled";
import PreCheck from "./components/Pages/PreCheck/PreCheck";

function App(): JSX.Element {
  return (
    <Routes>
      {isChrome || isEdge ? (
        <>
          <Route path='/unauthorized' element={<Error message={"Authorization failed"} />} />
          <Route path='/' element={<ProtectedWrapper />}>
            <Route path='exams/:id' element={<Login />} />
            <Route path='exams/:id/scheduled' element={<Scheduled />} />
            <Route path='exams/:id/waiting' element={<WaitingRoom />} />
            <Route path='exams/:id/welcome' element={<Welcome />} />
            <Route path='exams/:id/instructions' element={<Instructions />} />
            <Route path='exams/:id/requirements' element={<Requirements />} />
            <Route path='exams/:id/registration' element={<Registration />} />
            <Route path='exams/:id/start' element={<Start />} />
            <Route path='exams/:id/finish' element={<Finish />} />
            <Route path='exams/:id/feedback' element={<Feedback />} />
            <Route path='exams/:id/passcode' element={<Passcode />} />
            <Route path='exams/:id/finished' element={<Finished />} />
            <Route path='exams/:id/closed' element={<Closed />} />
          </Route>
          <Route path='/incognito-not-allowed' element={<DontTrollWithIncognito />} />
          <Route path='/rejected' element={<AccessRejected />} />
          <Route path='/not-admited' element={<NotAdmited />} />
          <Route path='/not-opened' element={<WaitingScheduled />} />
          <Route
            path='/precheck'
            element={
              <UnProtectedWrapper>
                <PreCheck />
              </UnProtectedWrapper>
            }
          />
          <Route path='*' element={<Error />} />
        </>
      ) : (
        <Route path='*' element={<ForceChrome />} />
      )}
    </Routes>
  );
}

export default App;
