import React from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash/fp";

import { useUserStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

import styles from "./SpeakingTo.module.scss";

const SpeakingTo: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const user = userStore.user(userStore.cameraStore.speakTo);

  return !_.isEmpty(user) ? (
    <div className={styles.label}>
      <Icons.SoundWave className='w-6' />
      <span>{` ${user.username}`}</span>
    </div>
  ) : null;
});

export default SpeakingTo;
