import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { ModalWaitingBanned } from "../../../types";
import Button from "../../Button/Button";
import RedButton from "../../Button/RedButton/RedButton";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalWaitingBanned;
};

const WaitingBanned: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose} className='w-1/2 !px-6 rounded-md'>
      <div className='flex flex-col space-y-4 mx-8 w-md text-left'>
        <div className='font-bold text-lg'>{t("you_are_going_to_reject_access_to_the_session_for_this_participant")}</div>
        <div>
          <p>{t("the_participant__will_be_removed_from_the_waiting_room", { fullName: data.fullName })}</p>
          <br />
          <p>{t("are_you_sure_you_want_to_remove_this_participant")}</p>
        </div>
        <div className='flex items-center self-end gap-2' id='modal-finish'>
          <Button
            onClick={() => { onClose(); }}
            className={"bg-white border border-gray-400 text-gray-600"}>
            {t("cancel")}
          </Button>
          <RedButton
            onClick={() => {
              onClose();
              data.onConfirm();
            }}
          >
            {t("Delete")}
          </RedButton>
        </div>
      </div>
    </Base>
  );
};

export default WaitingBanned;
