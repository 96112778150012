import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useUserStore } from "../../../hooks/store";

import "../../../services/i18n/config";
import styles from "./HostBusyLabel.module.scss";
import Icons from "../../../images/icons";

const HostBusyLabel: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const isBusy = userStore.isHostBusy;
  const { t } = useTranslation();

  return (
    isBusy && (
      <div className={styles.label}>
        <Icons.SoundWave className='w-6' />
        <span>{t("host_talking_to_some")}</span>
      </div>
    )
  );
});

export default HostBusyLabel;
