import React from "react";
import { useEffectOnce } from "../../../../hooks/useEffectOnce";

export default function Chatbot() {
  const getLandbotId = (): string => {
    if (navigator.language === "es-ES") {
      return process.env.REACT_APP_LANDBOT_ES_ID;
    }
    if (navigator.language === "ja") {
      return process.env.REACT_APP_LANDBOT_JA_ID;
    }
    return process.env.REACT_APP_LANDBOT_EN_ID;
  };

  useEffectOnce(() => {
    const landbotScript = document.createElement("script");
    const landbotId = getLandbotId();

    landbotScript.setAttribute("src", "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js");

    const landbotChatScript = document.createElement("script");
    landbotChatScript.innerHTML = `
    var myLandbot = new Landbot.Container({
        container: '#landbotChat',
        configUrl: 'https://storage.googleapis.com/landbot.online/v3/${landbotId}/index.json',
      });`;
    document.body.appendChild(landbotScript);
    landbotScript.addEventListener("load", () => document.body.appendChild(landbotChatScript));
  });
  return (
    <div className='text-md my-auto text-center w-full h-full'>
      <div id='landbotChat' style={{ width: "100%", height: "100%" }} />
    </div>
  );
}
