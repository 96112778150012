import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";

type Props = {
  message?: string;
};

export default function Closed({ message }: Props) {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={t("session_no_more_available")} />
  );
}
