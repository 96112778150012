import { ID } from "../types";
import client from "./client";

type Sentiment = {
  sentiment: string;
  date: string;
};
type Attention = {
  attention: number;
  date: string;
};
type CreateParams = {
  examId: ID;
  userId: ID;
  sessionId: ID;
  sentimentAge?: number;
  sentimentGender?: string;
  sentiment?: Sentiment[] | Attention[];
};

export const basic = async (data: CreateParams, guest?: boolean): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = {
    sentimentAge: data.sentimentAge,
    sentimentGender: data.sentimentGender,
  };
  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${
    data.sessionId
  }/sentiment/basic/`;
  const response = await client.post(url, postData, configs);
  return response.data;
};

export const attention = async (data: CreateParams, guest?: boolean): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = data.sentiment;

  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${
    data.sessionId
  }/sentiment/attention/`;
  const response = await client.post(url, postData, configs);
  return response.data;
};

export const emotional = async (data: CreateParams, guest?: boolean): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = data.sentiment;

  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${
    data.sessionId
  }/sentiment/emotional/`;
  const response = await client.post(url, postData, configs);
  return response.data;
};

export const quadrant = async (data: CreateParams, guest?: boolean): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = data.sentiment;

  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${
    data.sessionId
  }/sentiment/quadrant/`;
  const response = await client.post(url, postData, configs);
  return response.data;
};

const SentimentService = { basic, attention, emotional, quadrant };

export default SentimentService;
