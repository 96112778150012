import client from "./client";

const getTemporalToken = async (id: string): Promise<any> => {
  const response = await client.get(`${process.env.REACT_APP_LTI_URL}/temporalTokens?id=${id}`);
  return response.data;
};

const me = async (token: string): Promise<any> => {
  const response = await client.get(`${process.env.REACT_APP_LTI_URL}/me`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const authRefresh = async (token: string): Promise<any> => {
  const response = await client.get(`${process.env.REACT_APP_LTI_URL}/auth/refresh`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const LtiService = {
  getTemporalToken,
  me,
  authRefresh,
};

export default LtiService;
