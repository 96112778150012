import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useModalStore, useRoomStore, useUserStore } from "../../../hooks/store";
import Icons from "../../../images/icons";
import _ from "lodash";
import SessionService from "../../../services/session/sessions.service";

const Expel: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const roomStore = useRoomStore();
  const modalStore = useModalStore();

  const handleExpulsion = async () => {
    if (_.isInteger(userStore.cameraStore.speakTo)) {
        const user = userStore.user(userStore.cameraStore.speakTo);
        modalStore.openModal("WAITING_BANNED", {
            fullName: user.username,
            onConfirm: async () => {
                roomStore.rtc.cameraStore.removeRemoteUser(user.uid);
                await roomStore.banParticipant(user);
                await SessionService.addBan(roomStore.info.exam.id, roomStore.info.exam.sessionId, user.uid);
            },
        });
    }
  };

  return (
    <div
      className='text-gray-250 cursor-pointer flex flex-col items-center justify-between'
      onClick={handleExpulsion}
    >
      <div className='relative'>
        <Icons.Expel className='h-8' />
      </div>
      <span className='text-xs text-center leading-4'>
       { t("expel_participant") }
      </span>
    </div>
  );
});

export default Expel;
