import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useChatStore, useCollapsedStore, useUserStore } from "../../../hooks/store";
import Icons from "../../../images/icons";
import "../../../services/i18n/config";

const Focus: React.FunctionComponent = observer(() => {
  const chatStore = useChatStore();
  const userStore = useUserStore();
  const [collapseSideBar, setCollapseSideBar] = useState(true);
  const anyMessage = chatStore.anyUnreadMessage;
  const { t } = useTranslation();
  const collapsedStore = useCollapsedStore();

  const focusChatInput = () => {
    if (userStore.isLocalHost()) return;

    chatStore.focusInput();
    collapsedStore.collapse("SIDEBAR", collapseSideBar);
    setCollapseSideBar(!collapseSideBar);
  };

  const className = classNames("flex flex-col items-center justify-between", {
    "text-green": anyMessage,
    "text-gray-250": !anyMessage,
    "cursor-pointer": userStore.isLocalParticipant(),
  });

  return (
    <div className={className} onClick={focusChatInput}>
      <div className='relative'>
        <Icons.Focus
          className={`h-6 ${collapseSideBar && userStore.isLocalParticipant() ? "text-green" : ""}`}
        />
      </div>
      <span
        className={`text-xs text-center leading-4 ${
          collapseSideBar && userStore.isLocalParticipant() ? "text-green" : ""
        }`}
      >
        {t("focus")}
      </span>
    </div>
  );
});

export default Focus;
