import type { UID } from "agora-rtc-sdk-ng";
import AgoraRTM, { RtmClient, RtmChannel, RtmTextMessage, RtmMessage } from "agora-rtm-sdk";
import _ from "lodash/fp";
import { action, makeObservable, observable } from "mobx";

import AppStore from "..";
import ExamsLogger from "../../../logger";
import type { JoinOptions, ExamsMessage, ChannelUser, MessageAction } from "../../../types";
import { Resetable } from "../../interfaces/resetable";
import ChatStore from "../room/chat";
import UserStore from "../room/user";
import ActionStore from "../room/action";
import NotifyStore from "../ui/notify";

const logPrefix = "[RTM Store]";

export type ActionMessage = {
  type: MessageAction;
  params?: Record<string, unknown>;
};

export type PeersOnlineStatusResult = { 
  [peerId: string]: boolean
}
export enum PeerOnlineState {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  UNREACHABLE = "UNREACHABLE",
}
export enum ConnectionState {
  ABORTED = "ABORTED",
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
  RECONNECTING = "RECONNECTING",
}

export const createTextMessage = (message: string): RtmTextMessage => ({
  messageType: "TEXT",
  text: message,
});

export const createActionMessage = (message: ActionMessage): RtmTextMessage => ({
  messageType: "TEXT",
  text: JSON.stringify(message),
});

export const parseMessage = (message: RtmTextMessage): [boolean, string | ActionMessage] => {
  try {
    const text = JSON.parse(message.text) as ActionMessage;
    return [true, text as ActionMessage];
  } catch (e) {
    return [false, message.text as string];
  }
};

class RTMStore implements Resetable {
  appStore: AppStore;

  client: RtmClient = null;

  channel: RtmChannel = null;

  @observable
  joined = false;

  @observable
  inProgress = false;

  @observable
  options: JoinOptions;

  @observable
  channelUsers: Record<string, ChannelUser> = {};

  @observable
  messages: ExamsMessage[] = [];

  @observable
  peerStatus: Record<string, PeerOnlineState> = {};

  @observable
  connectionState = ConnectionState.CONNECTED;

  constructor(app: AppStore) {
    makeObservable(this);
    this.appStore = app;
  }

  get userStore(): UserStore {
    return this.appStore.roomStore.userStore;
  }

  get notifyStore(): NotifyStore {
    return this.appStore.uiStore.notify;
  }

  get chatStore(): ChatStore {
    return this.appStore.roomStore.chatStore;
  }

  get actionStore(): ActionStore {
    return this.appStore.roomStore.actionStore;
  }

  @action
  initClient(): void {
    if (!_.isNil(this.client)) return;
    // There is no alternative to createInstance even though it is marked as deprecated
    this.client = AgoraRTM.createInstance(this.appStore.appId);
  }

  @action
  initChannel(): void {
    if (!_.isNil(this.channel)) return;

    this.channel = this.client.createChannel(this.options.channel);
  }

  @action
  reset(): void {
    this.joined = false;

    this.channel.removeAllListeners();
    this.channel = null;

    this.client.removeAllListeners();
    this.client = null;

    this.messages = [];
    this.channelUsers = {};
    this.peerStatus = {};
    this.connectionState = null;
  }

  @action
  addMessage(message: ExamsMessage): void {
    this.messages = _.concat(this.messages, message);
  }

  existChannelUser(uid: UID): boolean {
    const user = _.get(uid, this.channelUsers);
    return !_.isEmpty(user);
  }

  @action
  updateChannelUser(user: ChannelUser): void {
    this.channelUsers = _.set(user.uid, user, this.channelUsers);
  }

  @action
  addChannelUser(user: ChannelUser): void {
    if (this.existChannelUser(user.uid)) return;

    this.channelUsers = _.set(user.uid, user, this.channelUsers);
  }

  @action
  removeChannelUser(uid: string): void {
    if (!this.existChannelUser(uid)) return;

    delete this.channelUsers[uid];
  }

  @action
  async loadChannelUser(uid: UID): Promise<ChannelUser> {
    // This is required because sometimes setting user attributes
    // does not set them even though we wait for response
    let user = null;
    /* eslint-disable no-await-in-loop */
    do {
      user = await this.client.getUserAttributes(_.toString(uid));
    } while (_.isEmpty(user));

    /* eslint-enable no-await-in-loop */
    return user;
  }

  listenClientEvents(): void {
    
    if(this.client.listenerCount("MessageFromPeer") === 0) {
      this.client.on("MessageFromPeer", (message, peerID, messageProps) => {
        ExamsLogger.info(logPrefix, "MessageFromPeer", message, peerID, messageProps);
        const [isAction, text] = parseMessage(message as RtmTextMessage);
        if (isAction) {
          this.actionStore.executeActionMessage(text as ActionMessage);
        } else {
          const examMessage = {
            senderId: peerID,
            receiverId: this.options.uid,
            text: text as string,
            timestamp: messageProps.serverReceivedTs,
          };
          this.addMessage(examMessage);
          this.notifyNewMessage(examMessage);
          this.chatStore.increaseUnreadMessages(peerID);
        }
      });
    }
    if(this.client.listenerCount("ConnectionStateChanged") === 0) {
      this.client.on("ConnectionStateChanged", async (newState, reason) => {
        ExamsLogger.info(logPrefix, `ConnectionStateChanged: New State - ${newState}, Reason - ${reason}`);
        this.setConnectionState(newState);

        const shouldReLogin = newState === ConnectionState.DISCONNECTED || newState === ConnectionState.ABORTED;

        if (shouldReLogin) {
            ExamsLogger.info(logPrefix, "Attempting to re-login due to connection state change...");
    
            try {
                await new Promise(resolve => setTimeout(resolve, 2000));
    
                await this.handleReconnection();
                ExamsLogger.info(logPrefix, "Re-login successful after connection state change.");
            } catch (error) {
                ExamsLogger.error(logPrefix, "Failed to re-login after connection state change", error);
            }
        }
      });
    }
    if(this.client.listenerCount("PeersOnlineStatusChanged") === 0) {
      this.client.on("PeersOnlineStatusChanged", (status) => {
        this.updatePeerStatus(status);
        ExamsLogger.info(logPrefix, "PeersOnlineStatusChanged", status);
      });
    }
  }

  listenChannelEvents(): void {
    if(this.channel.listenerCount("ChannelMessage") === 0) {
      this.channel.on("ChannelMessage", (message, memberId, messageProps) => {
        ExamsLogger.info(logPrefix, "ChannelMessage", message, memberId, messageProps);
        const [isAction, text] = parseMessage(message as RtmTextMessage);
        if (isAction) {
          this.actionStore.executeActionMessage(text as ActionMessage);
        } else {
          const examMessage = {
            senderId: memberId,
            receiverId: null,
            text: text as string,
            timestamp: messageProps.serverReceivedTs,
          };
          this.addMessage(examMessage);
          this.notifyNewMessage(examMessage);
          this.chatStore.increaseUnreadMessages(memberId);
        }
      });
    }
    if(this.channel.listenerCount("MemberJoined") === 0) {
      this.channel.on("MemberJoined", async (memberId) => {
        const user = await this.loadChannelUser(memberId);
        this.updatePeerStatus(memberId, PeerOnlineState.ONLINE);
        ExamsLogger.info(logPrefix, "MemberJoined", memberId, user);
        this.updateChannelUser(user);
      });
    }

    if(this.channel.listenerCount("MemberLeft") === 0) {
      this.channel.on("MemberLeft", (memberId) => {
        this.updatePeerStatus(memberId, PeerOnlineState.OFFLINE);
        ExamsLogger.info(logPrefix, "MemberLeft", memberId);
      });
    }
  }

  @action
  setConnectionState(newState: ConnectionState) {
    this.connectionState = newState;
  }


  @action
  updatePeerStatus(peerIdOrStatus: string | Record<string, PeerOnlineState>, status?: PeerOnlineState) {
    if (typeof peerIdOrStatus === "string" && status !== undefined) {
      this.peerStatus[peerIdOrStatus] = status;
    } else if (typeof peerIdOrStatus === "object") {
      this.peerStatus = { ...this.peerStatus, ...peerIdOrStatus };
    }
  }

  @action
  private async queryPeersOnlineStatus(peers: string[]) {
    const peerStatus = await this.client.queryPeersOnlineStatus(peers);
    Object.keys(peerStatus).forEach(peerId => {
      const onlineStatus = peerStatus[peerId]? PeerOnlineState.ONLINE : PeerOnlineState.OFFLINE;
      this.updatePeerStatus(peerId, onlineStatus);
    });
  }

  @action
  private async setRemoteUsersInfo(): Promise<void> {
    const members = await this.channel.getMembers();
    await Promise.all(
      _.map(async (id) => {
        const user = await this.loadChannelUser(id);
        this.updateChannelUser(user);
      }, members),
    );
    await this.queryPeersOnlineStatus(members);
  }

  @action
  private async setLocalUserInfo(user: ChannelUser): Promise<void> {
    const usr = {
      role: user.role,
      username: user.username,
      route: user.route,
      uid: _.toString(user.uid),
      fullname: user.fullname,
    };
    await this.client.setLocalUserAttributes(usr);
    const channelUser = await this.loadChannelUser(usr.uid);

    this.addChannelUser(channelUser);
    this.updatePeerStatus(usr.uid, PeerOnlineState.ONLINE);
  }

  @action
  async startMessaging(options: JoinOptions): Promise<void> {
    ExamsLogger.info(logPrefix, "Starting messaging: ", options);
    if (this.joined) {
      ExamsLogger.warn(logPrefix, "Cannot start messaging if you have already joined");
      return;
    }

    this.inProgress = true;

    this.initClient();

    this.options = options;

    this.initChannel();

    await this.rtmLogin(options);

    await this.setLocalUserInfo(this.options.user);

    await this.joinChannel();

    await this.setRemoteUsersInfo();

    this.listenClientEvents();
    this.listenChannelEvents();

  }

  @action
  leaveMessaging(): void {
    ExamsLogger.info(logPrefix, "Leaving messaging: ", this.options);
    if (!this.joined) {
      ExamsLogger.warn(logPrefix, "Cannot leave messaging if you have not joined");
      return;
    }

    this.client.logout();
    this.channel.leave();

    this.reset();
  }

  async rtmLogin(options: JoinOptions): Promise<void> {
    const retryCount = 3;
    let attempts = 0;
    let delay = 10000;
    
    while (attempts < retryCount) {
        try {
            await this.client.login({
              uid: _.toString(options.uid),
              token: options.token ?? null,
            });
            return;
        } catch (error) {
            attempts++;
            ExamsLogger.info(logPrefix, `Attempt ${attempts} failed. Error: ${error.message}`);
            if (attempts >= retryCount) { throw error; }
            ExamsLogger.info(logPrefix, `Waiting for ${delay / 1000} seconds before retrying...`);
        }

        await new Promise(resolve => setTimeout(resolve, delay));
        delay += 5000;
    }
  }

  @action
  async joinChannel(): Promise<void> {
    let attempts = 0;
    const maxAttempts = 3;
  
    while (!this.joined && attempts < maxAttempts) {
      try {
        await this.channel.join();
        this.joined = true;
      
        ExamsLogger.info(logPrefix, "Successfully joined channel");
      } catch (error) {
        attempts++;
        if (error.code === 4) { // JOIN_CHANNEL_TIMEOUT
          ExamsLogger.warn(logPrefix, `Join channel attempt ${attempts} failed with timeout. Retrying...`);

          await new Promise(resolve => setTimeout(resolve, 2000));
        } else {
          ExamsLogger.error(logPrefix, `Failed to join channel on attempt ${attempts} due to error: ${error}`);
          throw error;
        }
      }
    }
  
    if (!this.joined) {
      ExamsLogger.error(logPrefix, "Failed to join channel after maximum attempts");
    }
  }
  
  @action
  async handleReconnection() : Promise<void>{
    ExamsLogger.info(logPrefix, "Trying to reconnect...");

    // Check if logged in
    if (this.connectionState !== ConnectionState.CONNECTED) {
      ExamsLogger.warn(logPrefix, "User not logged in. Attempting to re-login...");
      await this.rtmLogin(this.options);
      await this.setLocalUserInfo(this.options.user);

      this.listenChannelEvents();
      this.joined = false;
    }

    // Check if joined channel
    if (!this.joined) {
      ExamsLogger.warn(logPrefix, "User not in channel. Attempting to re-join...");
      await this.joinChannel();
      await this.setRemoteUsersInfo();

      this.listenClientEvents();      
    }
  }

  @action
  async retryCall(callerContext, originalFunction, originalArguments) {
    ExamsLogger.info(logPrefix, `Retrying ${originalFunction.name} call`);
  
    try {
      await originalFunction.apply(callerContext, originalArguments);
      ExamsLogger.info(logPrefix, `Successfully retried ${originalFunction.name}`);
    } catch (error) {
      ExamsLogger.error(logPrefix, `Retry of ${originalFunction.name} failed: ${error}`);
    }
  }
  

  async sendActionMessageToPeer(message: RtmMessage, peerId: UID, retry = true): Promise<void> {
    if (_.isNil(this.client)) {
      ExamsLogger.error(logPrefix, "Cannot send message to peer if client is null");
      return;
    }
    if (_.isEmpty(message)) return;

    await this.client?.sendMessageToPeer(message, _.toString(peerId)).then(sendResult => {
      if (sendResult.hasPeerReceived) {
        ExamsLogger.info(logPrefix, "Action message has been recieved by: ", peerId, " Message: ", message);
      } else {
        ExamsLogger.info(logPrefix, "Action message failed:", peerId, " Message: ", message);
      }
    }).catch(async (error) => {
      ExamsLogger.info(logPrefix, "Error sending action message sent to: ", peerId, " Message: ", message, " Error: ", error);
      await this.handleReconnection();
      if(retry) {
        await this.retryCall(this, this.sendMessageToPeer, [message, peerId, false]);
      }
    });
  }
  
  @action
  async sendMessageToPeer(message: string, peerId: UID, retry = true): Promise<void> {
    if (_.isNil(this.client)) {
      ExamsLogger.error(logPrefix, "Cannot send message to peer if client is null");
      return;
    }
    if (_.isEmpty(message)) return;

    try {
      const peer = await this.client?.sendMessageToPeer(
        createTextMessage(message),
        _.toString(peerId),
      );
      if (peer.hasPeerReceived) {
        ExamsLogger.info(logPrefix, "Message has been recieved by: ", peerId, " Message: ", message);
        this.addMessage({
          text: message,
          timestamp: _.now(),
          senderId: this.options.uid,
          receiverId: peerId,
        });
      } else {
        ExamsLogger.info(logPrefix, "Message sent to: ", peerId, " Message: ", message);
      }
    } catch (error) {
      ExamsLogger.info(logPrefix, "Error sendMessageToPeer: ", peerId, " Message: ", message, " Error: ", error);
      await this.handleReconnection();
      if(retry) {
        await this.retryCall(this, this.sendMessageToPeer, [message, peerId, false]);
      }
    }
  }

  @action
  async sendMessageToChannel(message: string, retry = true): Promise<void> {
    if (_.isNil(this.channel)) {
      ExamsLogger.error(logPrefix, "Cannot send message to channel if channel is null");
      return;
    }
    if (_.isEmpty(message)) return;

    try {
      await this.channel?.sendMessage(createTextMessage(message));
      ExamsLogger.info(logPrefix, "Channel message: ", message, " from ", this.channel.channelId);
      this.addMessage({
        text: message,
        timestamp: _.now(),
        senderId: this.options.uid,
        receiverId: null,
      });
    } catch (error) {
      ExamsLogger.info(logPrefix, "Error sendMessageToChannel: ", " Message: ", message, " Error: ", error);
      if(error.code === 5) { // CHANNEL_MESSAGE_ERR_NOT_IN_CHANNEL
        this.joined = false;
      }
      await this.handleReconnection();
      if(retry) {
        await this.retryCall(this, this.sendMessageToPeer, [message, false]);
      }
    }
  }

  @action
  notifyNewMessage(message: ExamsMessage): void {
    const user = this.userStore.user(message.senderId);
    if (_.isEmpty(user)) return;

    this.notifyStore.showMessageNotification(`${user.username} sent "${message.text}"`, () =>
      this.chatStore.focusChatUser(user.uid),
    );
  }
}

export default RTMStore;
