import React from "react";

import { ExamsUser } from "../../types";

import UserLetters from "./UserLetters/UserLetters";

type Props = {
  user: ExamsUser;
};

const UserAvatar: React.FunctionComponent<Props> = ({ user }: Props) => (
  <UserLetters name={user?.username} />
);

export default UserAvatar;
