import { observer } from "mobx-react-lite";
import React from "react";
import _ from "lodash/fp";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useModalStore, useRoomStore, useToastStore, useViewModeStore } from "../../../hooks/store";
import { Loading } from "../../Loading/Loading";
import Icons from "../../../images/icons";
import commentService from "../../../services/comment.service";

const Comment: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const modalStore = useModalStore();
  const roomStore = useRoomStore();
  const toastStore = useToastStore();
  const viewModeStore = useViewModeStore();

  const [loading, setLoading] = React.useState(false);

  const onSubmitComment = async (comment: string): Promise<void> => {
    try {
      const { userStore } = viewModeStore;

      if (_.isInteger(userStore.cameraStore.speakTo) && comment !== "") {
        const user = userStore.user(userStore.cameraStore.speakTo);
        await commentService.create({
          comment,
          examId: roomStore.info.exam.id,
          sessionId: roomStore.info.exam.sessionId,
          userId: user.uid,
        });
        toastStore.addToast(t("successfully_saved_comment"));
      } else {
        toastStore.addToast(t("error_saving_comment"));
      }
    } catch (e) {
      toastStore.addToast(t("error_saving_comment"));
    }
  };

  const openCommentModal = async (): Promise<void> => {
    setLoading(true);
    modalStore.openModal("COMMENT", { onSubmit: onSubmitComment });
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      data-test-id='comment-btn'
      className={`text-gray-500 ${
        viewModeStore.isMode("ONE_ON_ONE") && "text-gray-250 cursor-pointer"
      } flex flex-col items-center justify-between`}
      onClick={viewModeStore.isMode("ONE_ON_ONE") ? openCommentModal : undefined}
    >
      <Icons.Comment className='h-6' />
      <span className='text-xs text-center leading-4'>{t("comment")}</span>
    </div>
  );
});

export default Comment;
