import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import RedButton from "../../Button/RedButton/RedButton";
import Chat from "../Chat/Chat";
import Focus from "../Focus/Focus";
import RaiseHand from "../RaiseHand/RaiseHand";
import Recording from "../Recording/Recording";
import Snapshot from "../Snapshot/Snapshot";
import { useModalStore, useRoomStore } from "../../../hooks/store";
import { useLocation } from "react-router-dom";
import TimeLeft from "../TimeLeft/TimeLeft";
import ShareScreen from "../ShareScreen/ShareScreen";
import excellentLearningHelper from "../../../lib/excellentLearning";

const ParticipantBottomBar: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const modalStore = useModalStore();
  const roomStore = useRoomStore();
  const { search } = useLocation();

  const onClickEndExam = () => {
    modalStore.openModal("EXIT_EXAM", {
      onExit: async () => {
        await roomStore.callActionFinishExam();
        window.location.href = `/exams/${roomStore.info.exam.id}/finish${search}`;
      },
    });
  };

  (async () => {
    await excellentLearningHelper.handleExamClosed(roomStore?.info?.user?.account, roomStore.callActionFinishExam);
  })();

  return (
    <div className='flex flex-row w-full justify-between'>
      <div className='flex flex-row space-x-8 w-64' />
      <div className='flex flex-row space-x-12'>
        {roomStore.info?.exam?.screenSharing && <ShareScreen />}
        <Focus />
        {!roomStore.info?.exam?.isUnattended && <Chat />}
        {roomStore.isRecordingEnabled && <Recording />}
        {!roomStore.info?.exam?.isUnattended && <RaiseHand />}
        {roomStore.isSnapshotEnabled && <Snapshot disabled={!roomStore.hasStarted} />}
      </div>
      <div className='flex flex-row items-center space-x-6'>
        {roomStore.info?.exam.isUnattended && <TimeLeft />}
        <RedButton
          className='whitespace-nowrap text-lg'
          onClick={onClickEndExam}
          disabled={!roomStore.hasStarted}
        >
          {roomStore.hasStarted && t("end_exam_session")}
          {!roomStore.hasStarted && t("exam_hasnt_started_yet")}
        </RedButton>
      </div>
    </div>
  );
});

export default ParticipantBottomBar;
