import { action, makeObservable, observable } from "mobx";

import ExamsLogger from "../../../logger";
import { Resetable } from "../../interfaces/resetable";

import UIStore from ".";

const logPrefix = "[Loading Store]";
class LoadingStore implements Resetable {
  @observable
  loading = false;

  uiStore!: UIStore;

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  @action
  startLoading(): void {
    ExamsLogger.info(logPrefix, "Start loading");
    this.loading = true;
  }

  @action
  stopLoading(): void {
    ExamsLogger.info(logPrefix, "Stop loading");
    this.loading = false;
  }

  @action
  reset(): void {
    this.loading = false;
  }
}

export default LoadingStore;
