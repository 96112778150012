import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { useRoomStore, useUserStore } from "../../../hooks/store";
import UserPlayer from "../../Players/UserPlayer/UserPlayer";
import RoundedCard from "../../RoundedCard/RoundedCard";

import Exam from "./Exam/Exam";
import { ExamIncidenceType, ExamsUserRole } from "../../../types/enum";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import useInterval from "../../../hooks/useInterval";
import { useMultipleMonitors } from "../../../hooks/useMultipleMonitorsCheck";
import ParticipantView from "../HostCall/ParticipantView/ParticipantView";

const FIVE_MINUTES = 300000; // milliseconds

const ParticipantCall: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const roomStore = useRoomStore();
  const [thereAreMultipleMonitors, setThereAreMultipleMonitors] = useState(useMultipleMonitors())

  const checkExtensionConnection = (extensionId, incidentData) => {
    try {
      chrome.runtime.sendMessage(extensionId, "installed&active", (response: string) => {
        if (response !== "👍") {
          roomStore.incidenceStore.createIncidence(incidentData);
        }
      });
    } catch (e) {
      roomStore.incidenceStore.createIncidence(incidentData);
    }
  };

  const onBeforeunload = async () => {
    if (roomStore.info.exam.hasStarted) { 
      const userDropped = {
        examId: roomStore.info.exam.id,
        sessionId: roomStore.info.exam.sessionId,
        userId: roomStore.info.user.id,
        type: ExamIncidenceType.USER_DROPPED,
        guest: roomStore.info.user.guest,
      }
      roomStore.incidenceStore.createIncidence(userDropped);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeunload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeunload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    if (userStore.localUser.role === ExamsUserRole.PARTICIPANT) {
      setTimeout(() => {
        // chrome extension id, change this in local env for testing
        const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
        const incidentData = {
          examId: roomStore.info.exam.id,
          sessionId: roomStore.info.exam.sessionId,
          userId: roomStore.info.user.id,
          type: ExamIncidenceType.KO_CHROME_EXTENSION,
          guest: roomStore.info.user.guest,
        };

        if (roomStore.info.user.guest) {
          checkExtensionConnection(extensionId, incidentData);
        } else {
          if (Object.keys(roomStore.info).includes("exam") && roomStore.info.exam.id) {
            checkExtensionConnection(extensionId, incidentData);
          }
        }
      }, 500);
    }
  });

  useInterval(() => {
      if (thereAreMultipleMonitors && roomStore?.info?.exam && userStore.localUser.role === ExamsUserRole.PARTICIPANT) {
      const multipleMonitors = {
        examId: roomStore.info.exam.id,
        sessionId: roomStore.info.exam.sessionId,
        userId: roomStore.info.user.id,
        type: ExamIncidenceType.MULTIPLE_MONITORS,
        guest: roomStore.info.user.guest
      };
      roomStore.incidenceStore.createIncidence(multipleMonitors);
    }
    setThereAreMultipleMonitors(useMultipleMonitors());
    }, FIVE_MINUTES)

  return (
    <>
      {!roomStore.info?.exam?.groupSession && (
        <RoundedCard className='w-full h-full'>
          {_.isEmpty(roomStore.lmsLink) ? (
            <UserPlayer
              object_fit_contain
              user={userStore.localUser}
              className='h-full w-full'
              focus='PARTICIPANT'
              hideName
            />
          ) : (
            <Exam user={userStore.localUser} link={roomStore.lmsLink} />
          )}
        </RoundedCard>
      )}
      {roomStore.info?.exam?.groupSession && (
        <ParticipantView />
      )}
    </>
  );
});

export default ParticipantCall;
