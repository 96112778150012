import mitt, { Emitter } from "mitt";

export type EventTrigger = "startRecord" | "startExam";
export type EventEmitted = Record<EventTrigger, string>;

class EventEmitterSingleton {
  private static instance: Emitter<EventEmitted> | null = null;

  private constructor() {
    // Private constructor to prevent instantiation
  }

  static getInstance(): Emitter<EventEmitted> {
    if (!EventEmitterSingleton.instance) {
      EventEmitterSingleton.instance = mitt();
    }
    return EventEmitterSingleton.instance;
  }
}

export const eventEmitter = EventEmitterSingleton.getInstance();