import { ITrack } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../../services/i18n/config";

type Props = {
  track?: ITrack;
  className?: string;
  type: "SCREEN" | "CAM";
  name: string;
};

const RenderPlayer: React.FunctionComponent<Props> = ({ track, className, type, name}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  
  React.useEffect(() => {
    if (!_.isNil(ref.current)) {
      track?.play(ref.current);
    }

    return () => track?.stop();
  }, [ref, track]);
  
  return <div className={`${className} flex justify-center items-center`} ref={ref}>
    {!track && type === "CAM" && (
      <p className="text-white text-center">{name}</p>
    )}
    {!track && type === "SCREEN" && (
      <p className="text-white text-center">{t("screen_not_shared")}</p>
    )}
  </div>;
};

export default RenderPlayer;
