import { observer } from "mobx-react-lite";
import React from "react";

import { useUserStore } from "../../hooks/store";

import HostBottomBar from "./HostBottomBar/HostBottomBar";
import ParticipantBottomBar from "./ParticipantBottomBar/ParticipantBottomBar";

const BottomBar: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();

  return (
    <div className='px-6 h-16 bg-gray-700 w-full flex items-center'>
      {userStore.localUser?.uid ? userStore.isLocalHost() ? <HostBottomBar /> : <ParticipantBottomBar /> : <></>}
    </div>
  );
});

export default BottomBar;
