export class ServiceError {
  constructor(
    message: string,
    code: string | undefined = undefined,
    status: number | undefined = undefined,
    data: unknown | undefined = undefined,
  ) {
    this.message = message;
    this.code = code;
    this.status = status;
    this.data = data;
    Object.setPrototypeOf(this, ServiceError.prototype);
  }
  message: string;
  code?: string;
  status?: number;
  data?: unknown;
}
