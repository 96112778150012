import React from "react";
import { useTranslation } from "react-i18next";

import i18n from "../../../services/i18n/config";
import { ModalChromeExtension } from "../../../types";
import BlueButton from "../../Button/BlueButton/BlueButton";
import RedButton from "../../Button/RedButton/RedButton";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalChromeExtension;
};

const ChromeExtension: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose}>
      <div className='flex flex-col mx-8'>
        <p className='font-bold text-xl mb-2'>
          {t("the_exams4zoom_extension_is_mandatory")}
          {i18n.language === "ja" ? (
            <>
              <a
                target='_blank'
                href='https://chrome.google.com/webstore/detail/exams4zoom/ikgahaiijeoaeeknbphnnjklapmpojpg?hl=en'
                className='text-blue'
                rel='noreferrer'
              >
                {t("here")}
              </a>
              {t("you_can_download_it")}
            </>
          ) : (
            <>
              {t("you_can_download_it")}
              <a
                target='_blank'
                href='https://chrome.google.com/webstore/detail/exams4zoom/ikgahaiijeoaeeknbphnnjklapmpojpg?hl=en'
                className='text-blue'
                rel='noreferrer'
              >
                {t("here")}
              </a>
            </>
          )}
        </p>
        <p className='mb-4'>{t("chrome_extension_flag")}</p>
        <div className='flex items-center justify-end space-x-2'>
          <RedButton
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </RedButton>
          <BlueButton
            onClick={() => {
              onClose();
              data.onOpen();
            }}
          >
            {t("continue")}
          </BlueButton>
        </div>
      </div>
    </Base>
  );
};

export default ChromeExtension;
