import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useState } from "react";

import "../../services/i18n/config"
import { useModalStore, useRoomStore, useUserStore } from "../../hooks/store";
import Icons from "../../images/icons";
import dateHelper from "../../lib/date";
import { ChannelUser, RoomExam } from "../../types";
import SessionService from "../../services/session/sessions.service";

export type Props = {
    exam: RoomExam;
};

const WaitingList: React.FunctionComponent<Props> = observer(({ exam }: Props) => {
    const { t } = useTranslation();
    const userStore = useUserStore();
    const modalStore = useModalStore();
    const roomStore = useRoomStore();
    const rtmParticipants = userStore.rtmParticipants;
    const [participants, setParticipants] = useState(rtmParticipants);

    const onClickBanned = (participant: ChannelUser) => {
        modalStore.openModal("WAITING_BANNED", {
            fullName: participant.fullname,
            onConfirm: async () => {
                await roomStore.banParticipant(participant);
                await SessionService.addBan(exam.id, exam.sessionId, participant.uid);
            },
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const term = _.toLower(e.target.value);
        if (term !== "") {
            setParticipants(
                _.filter(
                    rtmParticipants,
                    (participant) => (
                        _.includes(_.toLower(participant.fullname), term) ||
                        _.includes(_.toLower(participant.username), term)
                    )
                )
            );
        } else {
            setParticipants(rtmParticipants);
        }
    }

    useEffect(() => {
        setParticipants(rtmParticipants);
    }, [rtmParticipants])

    return (
        <div className='w-full flex justify-center mt-8 gap-6'>
            <div className='text-left '>
                <p className='font-bold mb-2'>{t("participants_in_waiting")}</p>
                <p className='text-sm'>{t("participants_you_remove_from_the_list_will_not_be_able_to_access_the_session")}</p>
                <div style={{ minWidth: "500px" }}>
                    {rtmParticipants.length > 0 ? (
                        <div>
                            <div>
                                <div className="relative">
                                    <Icons.Search className="w-8 bottom-2 left-3 absolute" />
                                    <input
                                        type="text"
                                        className="w-full h-12 mt-4 rounded-lg pl-12 focus:ring-gray-400 focus:border-transparent"
                                        placeholder={t("search_participants")}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                {participants.length == 0 &&
                                    <p className="mt-6 text-lg text-center">
                                        {t("no_participant_matches_the_search_criteria")}
                                    </p>
                                }
                            </div>
                            <div className='overflow-auto mt-2' style={{ maxHeight: "calc(100vh - 16rem)" }}>
                                {participants.map((participant) => (
                                    <div
                                        key={participant.uid}
                                        className='w-full flex bg-white border p-4 rounded-lg items-center my-4'
                                    >
                                        <div className='mr-4'>
                                            {participant.picture ? (
                                                <img
                                                    src={participant.picture}
                                                    alt={participant.username}
                                                    className='w-12 rounded-lg'
                                                />
                                            ) : (
                                                <Icons.User className='w-12 bg-gray-200 rounded-lg p-2' />
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-md font-bold">{participant.fullname}</p>
                                            <p>{participant.username}</p>
                                        </div>
                                        <div className='ml-auto'>

                                            <div onClick={() => onClickBanned(participant)}>
                                                <Icons.Delete
                                                    className='text-[#AB342E] hover:bg-[#EFD7D6] ease-in duration-100 cursor-pointer p-2 rounded-full w-9 h-9'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Icons.HostWaitingEmpty className='w-full my-2' />
                            <p className='mt-4 text-center'>{t("no_participants_in_the_waiting_room_yet")}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='text-left my-2 max-w-sm'>
                <div className=' border px-4 py-6 mb-4 bg-[#ECF0F4]'>
                    <Icons.Coffee className='w-6 text-[#2D8CFF]' />
                    <p
                        className='pt-3'
                        dangerouslySetInnerHTML={{ __html: t("there_are_x_participants_in_the_waiting_room", {participants: rtmParticipants.length}) }}
                    />
                </div>
                <div className='border px-4 py-6 bg-[#ECF0F4]'>
                    <p className='font-bold'>{t("open_session_scheduled_for")}</p>
                    <p className='text-[#2D8CFF] text-xl font-bold my-4'>{dateHelper.getInFormat(exam.startDate)}</p>
                    <p className='text-sm' dangerouslySetInnerHTML={{ __html: t("you_can_wait_for_the_configured_time_or_open_the_session_earlier_if_it_is_needed_html") }} />
                </div>
            </div>
        </div>
    );
});

export default WaitingList;
