import React from "react";
import { useTranslation } from "react-i18next";
import "../../../services/i18n/config";
import { ModalNetworkQuality } from "../../../types";
import Base, { BaseModalProps } from "../Base/Base";
import classNames from "classnames";
import { useRoomStore } from "../../../hooks/store";
import { observer } from "mobx-react-lite";

type Props = BaseModalProps & {
  data: ModalNetworkQuality;
};

const NetworkQuality: React.FunctionComponent<Props> = observer(({onClose}) => {
  const roomStore = useRoomStore();
  const { t } = useTranslation();
  const networkQuality = roomStore.rtc.cameraStore.networkQuality;
  const { downlinkNetworkQuality, uplinkNetworkQuality } = networkQuality ?? { downlinkNetworkQuality: 0, uplinkNetworkQuality: 0 };
    
  const getColorClass = (quality: number, index: number): string => {
    if (quality > 0 && index >= quality) {
      switch (index) {
        case 0:
          return "bg-gray-200";
        case 1:
          return "bg-[#63ca78]";
        case 2:
          return "bg-[#97bf64]";
        case 3:
          return "bg-[#edca5a]";
        case 4:
          return "bg-[#e59c4a]";
        case 5:
          return "bg-[#db4552]";
        case 6:
          return "bg-[#A52A2A]";
        default:
          return "";
      }
    } else {
      return "bg-gray-200";
    }
  };

  const getOverallMessage = (): string => {
    const overallQuality = Math.max(downlinkNetworkQuality, uplinkNetworkQuality);
    switch (overallQuality) {
      case 1:
        return t("excellentQuality");
      case 2:
        return t("goodQuality");
      case 3:
        return t("slightlyImpairedQuality");
      case 4:
        return t("notVerySmoothlyQuality");
      case 5:
        return t("poorQuality");
      case 6:
        return t("disconnectedQuality");
      default:
        return t("unknownQuality");
    }
  };

  const renderProgressBar = (quality: number, label: string): JSX.Element => {
    return (
        <div className='flex items-center justify-center'>
            <div className='mt-6 mb-3 text-center'>
                <div className="flex flex-col items-center justify-center mb-6">
                    <p className="text-lg text-gray-700 mb-2">{label}</p>
                    <div className="relative pt-1 mx-5">
                    <div className="overflow-hidden h-4 text-xs flex rounded bg-gray-200 w-80">
                        {[6, 5, 4, 3, 2, 1].map((index) => (
                        <div
                            key={index}
                            style={{ width: "16.66%" }}
                            className={classNames("shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center", getColorClass(quality, index), index!=1 && "border-r border-gray-400")}
                        ></div>
                        ))}
                        
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };

  return (
    <Base onClose={onClose} className='w-1/2 !px-6 rounded-md'>
    <div className='flex flex-col space-y-4 mx-8 mb-4 w-md text-left'>
        <div className="flex flex-col items-center justify-center bg-white w-full">
            {renderProgressBar(downlinkNetworkQuality, t("downloadQuality"))}
            {renderProgressBar(uplinkNetworkQuality, t("uploadQuality"))}
            <p className="text-lg text-gray-700 font-semibold text-center mt-4">{getOverallMessage()}</p>
        </div>
    </div>
    </Base>
  );
});

export default NetworkQuality;
