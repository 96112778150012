import _ from "lodash";
import React, { ReactNode } from "react";

export type Props = React.PropsWithChildren<{
  label: string;
  loading: boolean;
  status?: boolean | string;
  description?: string;
  custom?: ReactNode;
  action?: () => void;
}>;

const PingLoader: React.FunctionComponent<Props> = ({
  label = "",
  loading = true,
  status,
  description = "",
  custom,
  action,
}: Props) => {
  const color = _.isNull(status) ? "blue" : status ? "green" : "red";
  const icon = _.isNull(status) ? "!" : status ? "✓" : "X";

  const handleRetry = () => {
    if (action) {
      action();
    }
  };

  return (
    <div className='my-2'>
      <div className='flex items-center gap-4 py-2'>
        <span className='relative flex h-8 w-8'>
          <span
            className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-${color} opacity-75 ${
              loading ? "block" : "hidden"
            }`}
          ></span>
          <span
            className={`relative inline-flex rounded-full h-8 w-8 p-1 text-white text-lg text-bold justify-center items-center bg-${color}`}
          >
            {icon}
          </span>
        </span>
        <span className='text-2xl'>{label}</span>
        {action && (
          <button className='text-4xl' onClick={handleRetry}>
            ⟳
          </button>
        )}
      </div>
      <span
        className={`pl-12 text-base items-start gap-2 ${description?.length ? "flex" : "hidden"}`}
      >
        {description?.replace(/\w+/g, _.capitalize)}
        {!_.isNull(custom) && custom}
      </span>
    </div>
  );
};

export default PingLoader;
