import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useUserStore, useToastStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

const Deafen: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const listenTo = userStore.cameraStore.listenTo;

  const handleAudio = async () => {
    if (listenTo === "none") {
      userStore.listenToAllParticipants();
      toastStore.addToast(t("listening_to_all"));
    } else {
      if(listenTo === "all" && userStore.speakingToUser) {
        userStore.listenToOneParticipant(userStore.cameraStore.speakTo);
        toastStore.addToast(t("listening_to") + " " + t("selected_participant"));
      } else {
        userStore.listenToNoneParticipants();
        toastStore.addToast(t("listening_to_none"));
      }

    }
  };

  return (
    <div
      className='text-gray-250 cursor-pointer flex flex-col items-center justify-between'
      onClick={handleAudio}
    >
      <div className='relative'>
        {userStore.listeningToNone ? (
          <Icons.SpeakerOff className='h-8' />
        ) : userStore.listeningToAll? (
          <Icons.Audio className='h-8' />
        ): (
          <Icons.SpeakerOne className='h-8' />
        )}
      </div>
      <span className='text-xs text-center leading-4'>
        {userStore.listeningToNone ? t("activate_sound") : t("deactivate_sound")}
      </span>
    </div>
  );
});

export default Deafen;
