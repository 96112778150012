import React from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

type Props = {
  dateTime: string;
  navigateTo?: string;
};

const CountDown: React.FunctionComponent<Props> = ({ dateTime, navigateTo }: Props) => {
  const navigate = useNavigate();
  const handleComplete = () => {
    if (navigateTo) {
      window.location.href = navigateTo;
    } else {
      window.location.reload();
    }
  };

  return (
    <Countdown daysInHours className='font-size-45' date={dateTime} onComplete={handleComplete} />
  );
};

export default CountDown;
