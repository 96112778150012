import type {
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  UID,
} from "agora-rtc-sdk-ng";
import { action, computed, makeObservable, observable } from "mobx";

import ExamsLogger from "../../../logger";
import type { JoinOptions } from "../../../types";
import { Resetable } from "../../interfaces/resetable";
import AppStore from "..";

import CameraStore from "./camera";
import ScreenStore from "./screen";

const logPrefix = "[RTC Store]";
class RTCStore implements Resetable {
  appStore: AppStore;

  cameraStore: CameraStore;

  screenStore: ScreenStore;

  @observable
  callOptions: JoinOptions = null;

  @observable
  connected = false;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.cameraStore = new CameraStore(this);
    this.screenStore = new ScreenStore(this);
  }

  @computed
  get joined(): boolean {
    return this.cameraStore.joined && this.screenStore.joined;
  }

  @computed
  get localUid(): UID {
    return this.cameraStore.localUid;
  }

  @computed
  get localAudioTrack(): IMicrophoneAudioTrack {
    return this.cameraStore.localAudioTrack;
  }

  @computed
  get localVideoTrack(): ICameraVideoTrack {
    return this.cameraStore.localVideoTrack;
  }

  @computed
  get localScreenTrack(): ILocalVideoTrack {
    return this.screenStore.localVideoTrack;
  }

  @computed
  get remoteUsers(): IAgoraRTCRemoteUser[] {
    return this.cameraStore.remoteUsers;
  }

  @computed
  get screenRemoteUsers(): Record<UID, IAgoraRTCRemoteUser> {
    return this.screenStore.remoteUsers;
  }

  @action
  async createLocalTracks(): Promise<void> {
    await this.createCameraLocalTracks();
    await this.createScreenLocalTracks();
  }

  @action
  async createCameraLocalTracks(): Promise<void> {
    await this.cameraStore.createLocalTracks();
  }

  @action
  async createScreenLocalTracks(): Promise<void> {
    await this.screenStore.createLocalTracks();
  }

  @action
  setConnected(val: boolean): void {
    this.connected = val;
  }

  @action
  async startCall(options: JoinOptions): Promise<void> {
    if (this.joined) {
      ExamsLogger.warn(logPrefix, "Cannot start call if you have already joined");
      return;
    }

    this.callOptions = options;

    RTCStore.handleBadBandwidth(() => {
      if (!this.connected && options.user.role === "PARTICIPANT") {
        window.location.replace(window.location.href);
      }
    }, 30000);

    await this.cameraStore.startCall(options);
    this.setConnected(true);
    
    if (this.appStore.roomStore.info.exam.screenSharing) {
      await this.screenStore.startCall(options);
    }
  }

  @action
  async leaveCall(): Promise<void> {
    if (!this.joined) {
      ExamsLogger.warn(logPrefix, "Cannot leave call if you have not joined");
      return;
    }

    await this.screenStore.leaveCall();
    await this.cameraStore.leaveCall();

    this.reset();
  }

  @action
  reset(): void {
    this.cameraStore.reset();
    this.screenStore.reset();
  }

  private static handleBadBandwidth(callback: () => void, maxExecutionTime: number): void {
    setTimeout(() => {
      callback();
    }, maxExecutionTime);
  }
}

export default RTCStore;
