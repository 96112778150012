import React from "react";

import ArrowButton from "../../../../ArrowButton/ArrowButton";

type Props = {
  onClick: () => void;
  className?: string;
};

const RightButton: React.FunctionComponent<Props> = ({ onClick, className = "" }: Props) => (
  <div
    className={`absolute flex flex-col h-full items-center justify-center -right-2 top-0 ${className}`}
  >
    <ArrowButton onClick={onClick} stickDirection='right' arrowDirection='right' />
  </div>
);

export default RightButton;
