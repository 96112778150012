import { UID } from "agora-rtc-sdk-ng";

// We need this function since UID can be string or number and == can compare between them
// eslint-disable-next-line eqeqeq
const equalAgoraUID = (a: UID, b: UID): boolean => a == b;

const Compare = {
  equalAgoraUID,
};

export default Compare;
