import React, { useEffect, useState } from "react";
import _ from "lodash/fp";

import ParticipantSideBarBeforeExam from "../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/ParticipantSideBarBeforeExam";
import CheckAudioAndVideo from "../CheckAudioAndVideo/CheckAudioAndVideo";
import BottomBarBeforeExam, {
  Props as BottomBarProps,
} from "../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import { RoomExam } from "../../types";
import TopBar from "../TopBar/TopBar";
import RequirementsSteps, { Step } from "../RequirementsSteps/RequirementsSteps";
import RoundedCard from "../RoundedCard/RoundedCard";
import { useTranslation } from "react-i18next";

export type Props = BottomBarProps & {
  exam: RoomExam;
  role: string;
  demoRole: string;
  share_screen_tutorial_img: string;
};

const ExamConditions: React.FunctionComponent<Props> = ({
  nextURL,
  exam,
  role,
  demoRole,
  share_screen_tutorial_img,
}: Props) => {
  const [extensionInstalled, setExtensionInstalled] = React.useState<boolean>(false);
  const [retryExtensionInstalled, setRetryExtensionInstalled] = React.useState<boolean>(false);
  const examRole = exam.isDemo ? demoRole : role;
  const { t } = useTranslation();

  const [steps, setSteps] = useState<Step[]>([
    { label: t("video_and_audio"), state: "IN_PROCESS", show: true },
    { label: t("download_extension"), state: "TODO", show: true },
    { label: t("share_screen_instructions"), state: "TODO", show: exam.screenSharing },
  ]);

  useEffect(() => {
    if (steps[2].state === "IN_PROCESS" && !steps[2].show) {
      window.location.href = nextURL;
    }
  }, [steps])

  useEffect(() => {
    setTimeout(() => {
      // chrome extension id, change this in local env for testing
      const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
      try {
        if (!extensionInstalled && examRole === "PARTICIPANT") {
          chrome.runtime.sendMessage(extensionId, "installed&active", (response: string) => {
            if (response === "👍") {
              setExtensionInstalled(true);
              const stepsAfterChromeExtensionCheck: Step[] = [
                steps[0],
                { label: t("download_extension"), state: "DONE", show: true },
                {
                  label: t("share_screen_instructions"),
                  state: steps[0].state === "DONE" ? "IN_PROCESS" : "TODO",
                  show: exam.screenSharing
                },
              ];
              setSteps(stepsAfterChromeExtensionCheck);
            } else {
              setRetryExtensionInstalled(!retryExtensionInstalled);
            }
          });
        }
      } catch (e) {
        setExtensionInstalled(false);
        setRetryExtensionInstalled(!retryExtensionInstalled);
      }
    }, 500);
  }, [extensionInstalled, retryExtensionInstalled, examRole, steps, t]);

  const callback = () => {
    if (examRole === "PARTICIPANT") {
      if (steps[0].state === "IN_PROCESS") {
        setSteps([
          { ...steps[0], state: "DONE" },
          {
            ...steps[1],
            state: steps[1].state === "TODO" ? "IN_PROCESS" : "DONE",
          },
          {
            ...steps[2],
            state: steps[1].state === "DONE" ? "IN_PROCESS" : "TODO",
          },
        ]);
        return;
      }
      if (steps[1].state === "IN_PROCESS") {
        setSteps([steps[0], { ...steps[1], state: "DONE" }, { ...steps[2], state: "IN_PROCESS" }]);
        return;
      }
      if (steps[2].state === "IN_PROCESS") {
        window.location.href = nextURL;
        return;
      }
    } else {
      window.location.href = nextURL;
      return;
    }
  };

  return (
    <div className='h-screen w-full bg-black flex flex-col'>
      <TopBar hideSpeakingToAndDropdown />
      <div className='flex flex-row flex-1'>
        {_.isEqual(examRole, "PARTICIPANT") && (
          <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} />
        )}
        <div className='p-2 pt-0 w-full h-full'>
          <RoundedCard className='flex flex-col h-full w-full'>
            {role === "PARTICIPANT" && <RequirementsSteps steps={steps} />}
            {steps[0].state === "IN_PROCESS" && <CheckAudioAndVideo />}
            {steps[1].state === "IN_PROCESS" && (
              <div className='w-full h-full flex bg-white items-center justify-center'>
                <div className='mt-6 mb-6 text-center'>
                  <div className='max-w-[550px]'>
                    <p className='font-bold text-xl text-center mb-2'>{t("necessary_extension")}</p>
                    <p className='mb-4'>{t("we_need_you_to_download_this_extension")}</p>
                    <img
                      className='mb-4 flex justify-center w-full'
                      src={`${process.env.PUBLIC_URL}/install_chrome_extension.png`}
                      width={450}
                      alt='share screen tutorial'
                    />
                    <div className='flex justify-center'>
                      <a
                        target='_blank'
                        href='https://chrome.google.com/webstore/detail/exams4zoom/ikgahaiijeoaeeknbphnnjklapmpojpg?hl=en'
                        className='btn btn-lg btn-primary btn-blue text-center'
                        rel='noreferrer'
                      >
                        {t("download_extension")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {steps[2].state === "IN_PROCESS" && (
              <div className='w-full h-full flex bg-white items-center justify-center'>
                <div className='mt-6 mb-6 text-center'>
                  {
                    steps[2].show && (
                      <div className='max-w-[550px]'>
                        <p className='font-bold text-xl text-center mb-2'>{t("share_screen_instructions")}</p>
                        <p className='mb-4'>{t("share_entire_screen")}</p>
                        <img
                          className='mb-4 flex justify-center w-full'
                          src={share_screen_tutorial_img}
                          width={450}
                          alt='share screen tutorial'
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            )}
          </RoundedCard>
        </div>
      </div>
      <BottomBarBeforeExam nextURL={nextURL} callback={callback} forceCallback />
    </div>
  );
};

export default ExamConditions;
