import { User } from "@auth0/auth0-react";
import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const addAccessTokenInterceptor = (getAccessTokenSilently, user: User) => {
  const accountId =
    user[`${process.env.REACT_APP_AUTH0_API}userRolesContext`].accounts[0].accountId;
  client.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;

    if (accountId) {
      config.params = { ...config.params, accountId: accountId };
    }

    return config;
  });
};

export const addAccessTokenInterceptorForGuests = (accountId: string) => {
  client.interceptors.request.use(async (config) => {
    if (accountId) {
      config.params = { ...config.params, accountId: accountId };
    }

    return config;
  });
};

export const addAccessTokenInterceptorForLti = (accountId: string, token: string) => {
  client.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${token}`;
    if (accountId) {
      config.params = { ...config.params, accountId: accountId };
    }

    return config;
  });
};

export default client;
