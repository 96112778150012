import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";
import Button from "../../Button/Button";
import { useLocation } from "react-router-dom";

export default function WaitingScheduled(): JSX.Element {
  const { t } = useTranslation();
  const examId = localStorage.getItem("exam");
  const { search } = useLocation();

  return (
    <BackgroundE4Z title={t("the_session_is_not_open_yet")} >
        <div className="block">
          <p className='text-white text-xl text-center mt-2'>{t("if_you_have_any_questions")}</p>
          <Button onClick={() => { window.location.href = `/exams/${examId}/welcome${search}`;} } className='btn btn-primary btn-blue mx-auto mt-4'>
              {t("reload")}
          </Button>
        </div>
    </BackgroundE4Z>
  );
}
