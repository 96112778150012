import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";

type Props = {
  message?: string;
};

// TODO: handle close for lti and guest
export default function Error({ message }: Props) {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={message || "404 Not found"}>
      <a
        className='z-10 w-50 inline-flex items-center justify-center px-3 py-2 border border-transparent text-lg font-bold rounded-lg shadow-sm text-white bg-[#2DABFF] hover:bg-blue-300 focus:outline-none mt-16'
        href={process.env.REACT_APP_BASE_URL}
      >
        {t("back_to_dashboard")}
      </a>
    </BackgroundE4Z>
  );
}
