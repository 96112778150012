import React from "react";
import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import { UID } from "agora-rtc-sdk-ng";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { ExamsUser } from "../../../types";
import Dropdown from "../../Dropdown/Dropdown";
import { useChatStore } from "../../../hooks/store";
import compare from "../../../lib/compare";

type Props = {
  users: ExamsUser[];
  selected: UID | null;
  onSelect: (selected: UID | null) => void;
};

const ChatFilter: React.FunctionComponent<Props> = observer(
  ({ selected, onSelect, users }: Props) => {
    const { t } = useTranslation();
    const chatStore = useChatStore();

    const selectedUser = _.find((user) => compare.equalAgoraUID(user.uid, selected), users);

    const everyoneOption = { value: "", label: t("everyone") };
    const userOptions = _.map(
      (user) => ({
        value: _.toString(user.uid),
        label: `(${chatStore.userUnreadMessages(user.uid)}) ${user.username}`,
      }),
      users,
    );

    const options = _.concat(everyoneOption, userOptions);

    const onChange = (id: string) => {
      const newSelectedUser = _.find((user) => compare.equalAgoraUID(user.uid, id), users);
      onSelect(newSelectedUser ? newSelectedUser.uid : null);
    };

    return (
      <Dropdown
        mode='LIGHT'
        options={options}
        value={
          !_.isNil(selectedUser)
            ? { value: _.toString(selectedUser.uid), label: selectedUser.username }
            : everyoneOption
        }
        onChange={(option) => onChange(option.value)}
      />
    );
  },
);

export default ChatFilter;
