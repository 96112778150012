import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";

import "../../../services/i18n/config";
import { ContextRoom } from "../../../types";
import { ExamsUserRole } from "../../../types/enum";
import RegistrationComponent from "../../Registration/Registration";
import PreroomWrapper from "../../Room/Preroom";

const Registration: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { exam, user } = useOutletContext<ContextRoom>();
  const { search } = useLocation();

  if (user.role === ExamsUserRole.HOST || !exam.identityRegistration) {
    return <Navigate to={`/exams/${exam.id}/start${search}`} />;
  }

  return (
    <PreroomWrapper exam={exam} user={user} route={t("registration_id_validation")}>
      <RegistrationComponent nextURL={`/exams/${exam.id}/start${search}`} exam={exam} user={user} />
    </PreroomWrapper>
  );
};

export default Registration;
