/* eslint-disable no-duplicate-imports */
import { AxiosRequestConfig } from "axios";
import handleError from "./util/ErrorHandler";
import { ServiceError } from "./util/ServiceError";
import client from "./client";
import axios from "axios";
import { ID, DocumentRs } from "../types";

// TODO: ask Dani or Back end dev if makes sense that document-guest service is /guest and session-guest services is /guests
const createDocument = async (
  entityType: string,
  entityId: ID,
  file: File,
  documentType: string,
  userId?: ID,
): Promise<DocumentRs | ServiceError> => {
  const options: AxiosRequestConfig = {
    params: {
      entityType,
      entityId,
      userId,
    },
  };
  const document = {
    contentType: file.type,
    documentType: documentType,
    name: file.name,
  };

  try {
    const networkResponse = await client.post<DocumentRs>(
      `${process.env.REACT_APP_API_BASE_URL}/document-service/v1/documents${
        userId ? "/guest" : ""
      }/`,
      document,
      options,
    );
    return networkResponse.data;
  } catch (error) {
    return handleError(error);
  }
};

const generateURLToUploadToS3 = async (
  entityType: string,
  entityId: ID,
  documentId: string,
  userId?: ID,
): Promise<any> => {
  const options: AxiosRequestConfig = {
    params: {
      entityType,
      entityId,
      userId,
    },
  };

  try {
    const networkResponse = await client.get<Document>(
      `${process.env.REACT_APP_API_BASE_URL}/document-service/v1/documents${
        userId ? "/guest" : ""
      }/${documentId}/s3Url`,
      options,
    );
    return networkResponse.data;
  } catch (error) {
    return handleError(error);
  }
};

const uploadToS3 = async (url: string, file: File, progress?: any): Promise<any> => {
  axios.defaults.headers["Content-Type"] = file.type;
  try {
    const networkResponse = await axios.put<Document>(url, file, progress);
    return networkResponse.data;
  } catch (error) {
    return handleError(error);
  }
};

const DocumentService = { createDocument, generateURLToUploadToS3, uploadToS3 };

export default DocumentService;
