import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";

export default function NotAdmited(): JSX.Element {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={t("not_admited")} >
        <p className='text-white text-xl text-center mt-2'>{t("if_you_have_any_questions")}</p>
    </BackgroundE4Z>
  );
}
