import { ID } from "../../types";
import client from "../client";
import { ExamChatRequestType } from "./sessions.service";

export enum ExamState {
  CANCELED = "CANCELED",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  SCHEDULED = "SCHEDULED"
};

export type SessionResponse = {
  academicInstructions: string;
  accessType: "LINK";
  banned: boolean;
  LIST;
  examId: ID;
  examStartDate: string;
  examState: ExamState;
  id: ID;
  inWaitingLounge: boolean;
  sessionType: "LIVE" | "UNATTENDED";
  state: "FINISHED" | "OPENED" | "STARTED";
  supervisor: boolean;
  unattended: boolean;
  passCodeEnable: boolean;
  groupSession: boolean;
  userId?: ID;
  defaultFeedback?: [
    "EASY_TO_USE",
    "CLARITY_INSTRUCTIONS",
    "LEVEL_DISTRACTIONS",
    "QUALITY_AUDIO_VIDEO",
  ];
  feedback?: string[];
  uploadFiles?: boolean;
};

export type SessionRoomInfoResponse = {
  id: ID;
  examId: ID;
  name: string;
  username: string;
  lmsURL: string;
  endTime: string | null;
  identityRegistration: boolean;
  recording: boolean;
  sentimentEnabled: boolean;
  recognitionEnabled: boolean;
  attentionEnabled: boolean;
  disableAudio: boolean;
  uploadFiles: boolean;
  takeScreenshot: boolean;
  feedback: string[];
  userRecordingCamera: boolean;
  userRecordingScreen: boolean;
  manualStarted: boolean;
  supervisorFinishedWaitingRoom: boolean;
  screenSharing: boolean;
  passcode: string;
  manualStartDate?: string;
  notifyParticipants: boolean;
  objectDetection?: boolean;
};

export type SessionStartResponse = {
  agoraId: number;
  agoraUserId: number;
  examId: ID;
  id: ID;
};

export type SessionFinishResponse = {
  agoraId: number;
  examId: ID;
  id: ID;
};

export type SessionIdValidationToken = {
  responseCode: number;
  token: string;
};

export type ExamInfo = {
  allowGuest: boolean;
  passcodeEnabled: boolean;
  examState: ExamState;
  examStartDate: string;
};

const get = async (examId: ID, userId: ID): Promise<SessionResponse> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${examId}?userId=${userId}`,
  );
  return response.data;
};

const getExamInfo = async (examId: ID, accountId: ID): Promise<ExamInfo> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${examId}/info?accountId=${accountId}`,
  );
  return response.data;
};

const link = async (
  examId: ID,
  language: string,
  timezone: string,
  userName: string,
  password?: string,
): Promise<SessionResponse> => {
  const response = await client.post(
    `${
      process.env.REACT_APP_API_EXAM_SERVICE
    }/v1/sessions/guests/link?examId=${examId}&language=${language}&timezone=${timezone}&userName=${userName}${
      password ? "&password=" + password : ""
    }`,
  );
  return response.data;
};

const start = async (
  examId: ID,
  sessionId: ID,
  userId: ID,
  password?: string,
): Promise<SessionStartResponse> => {
  const response = await client.put(
    `${
      process.env.REACT_APP_API_EXAM_SERVICE
    }/v1/sessions/guests/${sessionId}/start?examId=${examId}&userId=${userId}${
      password ? "&password=" + password : ""
    }`,
  );
  return response.data;
};

const getRoom = async (examId: ID, sessionId: ID, userId: ID): Promise<SessionRoomInfoResponse> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/room/${sessionId}?examId=${examId}&userId=${userId}`,
  );
  return response.data;
};

const getToken = async (examId: ID, sessionId: ID, userId: ID): Promise<SessionIdValidationToken> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/idValidation/token?examId=${examId}&userId=${userId}`,
  );
  return response.data;
};

const storeIdValidationResult = async (
  examId: ID,
  sessionId: ID,
  result: string,
  userId: ID,
): Promise<SessionStartResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/idValidation/result/${result}?examId=${examId}&userId=${userId}`,
  );
  return response.data;
};

const agoraStart = async (
  examId: ID,
  sessionId: ID,
  resourceId: string,
  userId: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/agora/${resourceId}/start?examId=${examId}&userId=${userId}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const agoraStop = async (
  examId: ID,
  sessionId: ID,
  channel: string,
  resourceId: string,
  uid: ID,
  userId: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/agora/${resourceId}/stop?channel=${channel}&examId=${examId}&uid=${uid}&userId=${userId}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const agoraAcquire = async (
  examId: ID,
  sessionId: ID,
  userId: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/agora/acquire?examId=${examId}&userId=${userId}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const finishExam = async (
  examId: ID,
  sessionId: ID,
  userId: ID,
): Promise<SessionResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/finish?examId=${examId}&userId=${userId}`,
  );
  return response.data;
};

const addChat = async (examId: ID, sessionId: ID, request: ExamChatRequestType): Promise<SessionRoomInfoResponse> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/guests/${sessionId}/chat?examId=${examId}`,
    request,
  );
  return response.data;
};

const SessionGuestService = {
  get,
  getExamInfo,
  start,
  getRoom,
  getToken,
  storeIdValidationResult,
  agoraAcquire,
  agoraStart,
  agoraStop,
  link,
  finishExam,
  addChat,
};

export default SessionGuestService;
