import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Icons from "../../images/icons";
import { bytesToSize } from "../../lib/filesize";
import documentService from "../../services/document.service";
import { ServiceError } from "../../services/util/ServiceError";
import { Loading } from "../Loading/Loading";
import { ID } from "../../types";
import BlueButton from "../Button/BlueButton/BlueButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthorizationStore } from "../../hooks/store";
import useQuery from "../../hooks/useQuery";

const FileUpload: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { search } = useLocation();
  const authorizationStore = useAuthorizationStore();
  const { roomExam } = authorizationStore;
  const query = useQuery();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const previews = files.map((file) => (
    <div className='flex items-center' key={file.name}>
      <Icons.File className='h-16 m-auto text-gray-300' />
      <div className='text-md w-40 p-2'>
        <p>{file.name}</p>
        <p className='font-bold'>{bytesToSize(file.size)}</p>
      </div>
    </div>
  ));

  const uploadDocument = async (file: File): Promise<ID> => {
    const entityType = "EXAM";
    const entityId = roomExam.id;
    const documentType = "ATTACH_FILE";
    let percentCompleted = 0;

    const progress = {
      onUploadProgress: (progressEvent: any) => {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      },
    };

    const documentCreated = await documentService.createDocument(
      entityType,
      entityId,
      file,
      documentType,
      authorizationStore.roomUser.guest ? authorizationStore.roomUser.id : null,
    );
    if (documentCreated instanceof ServiceError) {
      throw new Error("ERR_CREATING_DOCUMENT");
    }

    const s3Url = await documentService.generateURLToUploadToS3(
      entityType,
      entityId,
      String(documentCreated.id),
      authorizationStore.roomUser.guest ? authorizationStore.roomUser.id : null,
    );
    if (s3Url instanceof Error) {
      throw new Error("ERR_GENERATING_URL");
    }

    const s3Document = await documentService.uploadToS3(s3Url.url, file, progress);
    if (s3Document instanceof Error) {
      throw new Error("ERR_UPLOADING_DOCUMENT");
    }
    return documentCreated.id;
  };

  const onSubmit = () => {
    setLoading(true);
    try {
      files.forEach(async (file) => await uploadDocument(file));
      leaveRedirect();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const leaveRedirect = async () => {
    if (
      authorizationStore.sessionResult.defaultFeedback ||
      authorizationStore.sessionResult.feedback
    ) {
      navigate(`/exams/${roomExam.id}/feedback${search}`);
    } else if (authorizationStore.roomUser.guest || (query.get("ltk") || query.get("lms"))) {
      navigate(`/exams/${roomExam.id}/finished${search}`);
    } else {
      window.location.href = process.env.REACT_APP_BASE_URL;
    }
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className='absolute inset-0 flex items-center justify-center bg-gray-700 bg-dark-transparent -mr-64'>
        <div className='w-1/2 p-12 bg-[#FCFCFD] text-[#23262F] text-center relative rounded overflow-y-scroll max-h-full z-10'>
          <Icons.Close
            className='absolute right-7 top-7 cursor-pointer w-3 text-lg'
            onClick={leaveRedirect}
          />
          <div className='text-right items-center justify-between mt-4 relative'>
            <div className='flex justify-center'>
              <p className='font-bold font-size-10'>{t("exam_ended_upload_results")}</p>
            </div>
            <div
              {...getRootProps()}
              className='relative border-dashed border border-[#4B506D] mt-8 p-36 rounded'
            >
              <input
                {...getInputProps()}
                type='file'
                multiple
                className='py-6 flex justify-center'
              />
              <div className='text-center p-16 absolute top-0 right-0 left-0 bottom-0 m-auto'>
                <Icons.Upload className='h-16 m-auto' />
                <h4>{t("drag_and_drop")}</h4>
                <hr />
                <p className='text-[#00A0E0]'>{t("browse_the_files")}</p>
              </div>
            </div>
            <div
              className='flex mt-4 items-center'
              style={{ display: progress > 0 ? "flex" : "none" }}
            >
              <div className='w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700'>
                <div className='bg-blue h-4 rounded-full' style={{ width: progress + "%" }}></div>
              </div>
              <div className='pl-2'>{progress}%</div>
            </div>
            <div className='flex mt-6 justify-between'>
              <div className='flex flex-wrap text-left'>{previews}</div>
              <BlueButton onClick={onSubmit} className='whitespace-nowrap text-lg my-auto'>
                {t("send")}
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
