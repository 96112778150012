import _ from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Icons from "../../../images/icons";

import "../../../services/i18n/config";
import { ModalInviteData } from "../../../types";
import Button from "../../Button/Button";
import Flash from "../../Flash/Flash";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalInviteData;
};

const Invite: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = _.replace(search, "&host=true", "");
  const [message, setMessage] = useState("");
  const [showFlash, setShowFlash] = useState(false);
  const showFlashRef = useRef(showFlash);
  const examUrl = `${window.location.origin}/exams/${data.examId}/welcome${query}`;

  const setShowFlashRef = (data: boolean) => {
    showFlashRef.current = data;
    setShowFlash(data);
  };

  const onClipboardCopy = (text: string, message: string) => {
    navigator.clipboard.writeText(text);
    setMessage(message);
    setShowFlash(true);
    setTimeout(() => {
      setShowFlashRef(false);
    }, 2000);
  };

  return (
    <>
      {showFlash && message && <Flash show={showFlash} message={message} />}
      <Base onClose={onClose} className='w-1/2 !px-6 rounded-md'>
        <div className='flex flex-col space-y-4 mx-8 w-md text-left'>
          <div className='font-bold text-lg'>{t("invite_participants")}</div>
          <div>
            <h3 className='font-bold pb-2'>{t("link_to_access")}</h3>
            <p>{t("share_link_and_anyone_clicks")}</p>
            <div className='flex p-4 my-2 border border-gray-300 rounded-lg cursor-pointer' onClick={() => onClipboardCopy(examUrl, t("url_copied_successfully"))}>
              <Icons.Link className='h-6 w-6 text-blue mr-2' />
              <p className='w-[calc(100%-4rem)]'>{ examUrl }</p>
              <Icons.Copy className='h-6 w-6 ml-auto' />
            </div>
          </div>

          { data.passcode && 
            <div>
              <h3 className='font-bold pb-2'>{t("extra_security_options")}</h3>
              <p className='w-[calc(100%-4rem)]'>{t("participants_access_with_passcode")}</p>
              <div className='flex p-4 my-2 border border-gray-300 rounded-lg cursor-pointer' onClick={() => onClipboardCopy(data.passcode,  t("pass_copied_successfully"))}>
                <Icons.Lock className='h-6 w-6 text-blue mr-2' />
                <p className="font-semibold">{ data.passcode }</p>
                <Icons.Copy className='h-6 w-6 ml-auto' />
              </div>
            </div>
          }
          <div className='flex items-center self-end gap-2' id='modal-finish'>
            <Button
              onClick={() => { onClose(); }}
              className={"bg-white border border-gray-400 text-gray-600"}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Base>
    </>
  );
};

export default Invite;
