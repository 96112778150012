import classNames from "classnames";
import React from "react";

import Icons from "../../images/icons";

type Direction = "left" | "right";

type Props = {
  stickDirection: Direction;
  arrowDirection: Direction;
  onClick: () => void;
};

const ArrowButton: React.FunctionComponent<Props> = ({
  stickDirection,
  onClick,
  arrowDirection,
}: Props) => {
  const className = classNames(
    "z-10 cursor-pointer bg-gray-700 flex items-center text-white h-12 w-6 shadow-md",
    {
      "rounded-r-full pl-1 justify-start": stickDirection === "left",
      "rounded-l-full pr-1 justify-end": stickDirection === "right",
    },
  );

  const iconClass = classNames("transform transition-transform ease-in-out duration-300", {
    "rotate-90": arrowDirection === "left",
    "-rotate-90": arrowDirection === "right",
  });

  return (
    <div onClick={onClick} className={className}>
      <Icons.Chevron className={iconClass} width='10px' />
    </div>
  );
};

export default ArrowButton;
