import { action, computed, makeObservable, observable } from "mobx";
import _ from "lodash/fp";

import ExamsLogger from "../../../logger";
import { Resetable } from "../../interfaces/resetable";
import type { Modals } from "../../../types";

import UIStore from ".";

const logPrefix = "[Modal Store]";
class ModalStore implements Resetable {
  @observable
  modals: Modals = {};

  uiStore!: UIStore;

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  isOpen(type: keyof Modals): boolean {
    return _.has(type, this.modals);
  }

  getModalData<K extends keyof Modals>(type: K): Modals[K] {
    return _.get(type, this.modals);
  }

  @computed
  get isAnyOpen(): boolean {
    return !_.isEmpty(this.modals);
  }

  @action
  openModal<K extends keyof Modals>(type: K, data: Modals[K]): void {
    ExamsLogger.info(logPrefix, "Opening modal: ", type);
    this.modals = _.set(type, data, this.modals);
  }

  @action
  closeModal(type: keyof Modals): void {
    ExamsLogger.info(logPrefix, "Closing modal: ", type);
    delete this.modals[type];
  }

  @action
  reset(): void {
    this.modals = {};
  }
}

export default ModalStore;
