import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "../../../../services/i18n/config";
import { useModalStore, useRoomStore, useToastStore } from "../../../../hooks/store";
import { ExamsUser } from "../../../../types";
import BlueButton from "../../../Button/BlueButton/BlueButton";
import RenderPlayer from "../../../Players/RenderPlayer/RenderPlayer";
import useQuery from "../../../../hooks/useQuery";
import excellentLearningHelper from "../../../../lib/excellentLearning";

type Props = {
  link: string;
  user: ExamsUser;
};

const Exam: React.FunctionComponent<Props> = observer(({ user, link }: Props) => {
  const { t } = useTranslation();
  const [examOpened, setExamOpened] = useState<boolean>(false);
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const toastStore = useToastStore();
  const query = useQuery();
  const shared = !roomStore.info?.exam?.screenSharing || roomStore.rtc?.screenStore?.localVideoTrack != null;

  const openExamWindow = async() => {
    const accountId = query.get("accountId");
 
    await excellentLearningHelper.enrollLmsExam(accountId,roomStore.info.user.id, roomStore.info.exam.id).then((res)=>{
      if(!excellentLearningHelper.isExcellentLearning(accountId) || res?.success == true){
        window.open(link, "_blank", "popup,fullscreen");
        excellentLearningHelper.handleExamOpened(accountId);
      } else {
        toastStore.addToast(`${t("couldnt_open_exam_try_again")}`);
      }
    });
  };
  

  const handleExamOpened = () => {
    if (shared) {
      if (examOpened) {
        modalStore.openModal("OPENED_EXAM", {
          onOpen: () => {
            openExamWindow();
          },
        });
      } else {
        openExamWindow();
        setExamOpened(true);
      }
    } else {
      toastStore.addToast(`${t("share_screen_first_to_open_exam")}`);
    }
  };

  return (
    <div className='h-full w-full relative'>
      <div className='h-full flex flex-col items-center justify-center text-white space-y-4'>
        <p className='font-bold text-white'>{t("exam_registration_completed")}</p>
        <p className='text-sm text-white'>{t("exam_will_open_new_window")}</p>
        {roomStore.info.exam.password && (
          <p
            className='text-sm text-white'
            //  eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t("password_credentials", {
                username: roomStore.info.exam.username,
                password: roomStore.info.exam.password,
              }),
            }}
          />
        )}
        <BlueButton disabled={!roomStore.hasStarted || !shared} onClick={handleExamOpened}>
          { !shared? t("waiting_screen_share") : t("open_exam")}
        </BlueButton>
      </div>
      <div className='absolute top-0 right-0 shadow-md bg-[#000000] rounded-bl overflow-hidden'>
        <RenderPlayer track={user.videoTrack} className='w-20 h-14' type="SCREEN" name={user.username} />
      </div>
    </div>
  );
});

export default Exam;
