import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";

export default function AccessRejected(): JSX.Element {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={t("sorry_but_you_cannot_access_the_session")} >
        <p className='text-white text-xl text-center mt-2'>{t("if_you_have_any_questions")}</p>
    </BackgroundE4Z>
  );
}
