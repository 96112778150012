import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";

/**
 * Like, a user can use incognito mode in the browser but this issues multiple errors and chrome extensions are not allowed in this mode
 * so we just render this page to tell the user, hey go back dude
 * @returns JSX.Element
 */
export default function DontTrollWithIncognito(): JSX.Element {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={t("incognito_not_enabled")} />
  );
}
