import type { UID } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import { action, computed, makeObservable, observable } from "mobx";

import i18n from "../../../services/i18n/config";
import type { ExamsUser, RoomExam } from "../../../types";
import { Resetable } from "../../interfaces/resetable";
import RTMStore, { createActionMessage } from "../rtm";
import NotifyStore from "../ui/notify";

import UserStore from "./user";

import RoomStore from ".";

class HandStore implements Resetable {
  roomStore!: RoomStore;

  @observable
  raised: Set<UID> = new Set();

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  get notifyStore(): NotifyStore {
    return this.roomStore.appStore.uiStore.notify;
  }

  get rtmStore(): RTMStore {
    return this.roomStore.rtm;
  }

  @computed
  get localUser(): ExamsUser {
    return this.userStore.localUser;
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @computed
  get host(): ExamsUser {
    return this.userStore.host;
  }

  @action
  reset(): void {
    this.raised = new Set();
  }

  @action
  raiseHand(uid: UID): void {
    this.raised.add(_.toString(uid));
  }

  @action
  lowerHand(uid: UID): void {
    this.raised.delete(_.toString(uid));
  }

  @action
  localRaiseHand(): void {
    if (_.isEmpty(this.localUser)) return;

    this.raiseHand(this.localUser.uid);

    const message = createActionMessage({
      type: "RAISE_HAND",
      params: { uid: this.localUser.uid },
    });
    this.rtmStore.sendActionMessageToPeer(message, _.toString(this.host.uid));
  }

  @action
  localLowerHand(): void {
    if (_.isEmpty(this.localUser)) return;

    this.lowerHand(this.localUser.uid);

    const message = createActionMessage({
      type: "LOWER_HAND",
      params: { uid: this.localUser.uid },
    });
    this.rtmStore.sendActionMessageToPeer(message, _.toString(this.host.uid));
  }

  isHandRaised(uid: UID): boolean {
    return this.raised.has(_.toString(uid));
  }

  isLocalHandRaised(): boolean {
    if (_.isEmpty(this.localUser)) return false;

    return this.isHandRaised(this.localUser.uid);
  }

  @action
  notifyRaiseHand(uid: UID): void {
    const user = this.userStore.user(uid);
    if (_.isEmpty(user)) return;

    this.notifyStore.showHandNotification(
      `${i18n.t("user")} ${user.username} ${i18n.t("raised_the_hand")}`,
    );
    this.raiseHand(uid);
  }
}

export default HandStore;
