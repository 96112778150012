import React from "react";

import { AppProps } from "../../../../AppWrapper/AppWrapper";
import PreroomWrapper, { RoomProps } from "../../../../Room/Preroom";
import ParticipantSideBarBeforeExam from "../ParticipantSideBarBeforeExam";

type Props = AppProps & RoomProps;

const Wrapped: React.FunctionComponent<Props> = ({ exam, user, route }: Props) => (
  <PreroomWrapper exam={exam} user={user} route={route}>
    <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} />
  </PreroomWrapper>
);

export default Wrapped;
