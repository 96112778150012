import React, { useId } from "react";

export type Step = {
  state: "TODO" | "IN_PROCESS" | "DONE";
  label: string;
  show: boolean;
};

type Props = {
  steps: Step[];
};

export default function RequirementsSteps({ steps }: Props) {
  const id = useId();
  return (
    <div className='w-full flex bg-white items-center justify-center'>
      <div className='mt-6 mb-3 text-center'>
        <ol className='items-center w-full sm:flex justify-between mb-12 mt-5'>
          {steps.map((step, index) => {
            if (!step.show) {
              return null;
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
              <li key={`${id}-${index}`} className='relative mb-6 sm:mb-0 w-[300px]'>
                <div className='flex items-center'>
                  <div
                    className={`flex z-10 justify-center items-center w-6 h-6 rounded-full text-white ${
                      step.state === "IN_PROCESS"
                        ? "bg-blue"
                        : step.state === "DONE"
                        ? "bg-[#005F83]"
                        : "text-[#6B7279] bg-white border-2"
                    } ring-0 ring-white sm:ring-8 dark:ring-blue shrink-0 absolute left-1/2`}
                  >
                    <div>{index + 1}</div>
                  </div>
                  <div
                    className={`hidden sm:flex w-full ${
                      step.state === "IN_PROCESS"
                        ? "bg-blue"
                        : step.state === "DONE"
                        ? "bg-[#005F83]"
                        : "bg-gray-100"
                    } h-0.5`}
                  ></div>
                </div>
                <div className='mt-3'>
                  <h3
                    className={`text-lg ${
                      step.state === "IN_PROCESS" && "font-semibold"
                    } text-gray-900 dark:text-white`}
                  >
                    {step.label}
                  </h3>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
