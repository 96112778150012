import React, { useEffect } from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import Icons from "../../../images/icons";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";
import { ExamsUserRole } from "../../../types/enum";
import { useTranslation } from "react-i18next";
import { useLoadingStore } from "../../../hooks/store";

export default function Instructions() {
  const { exam, user } = useOutletContext<ContextRoom>();
  const { t } = useTranslation();
  const { search } = useLocation();
  const loadingStore = useLoadingStore();

  if (user.role === ExamsUserRole.HOST) {
    return <Navigate to={`/exams/${exam.id}/requirements${search}`} />;
  }

  useEffect(() => {
    if (exam) {
      loadingStore.stopLoading();
    } else {
      loadingStore.startLoading();
    }
  }, [exam])

  if (!exam) {
    return null;
  }
  return (
    <div className='bg-black flex flex-col h-screen'>
      <ParticipantTopBar
        examName={exam.name}
        takeScreenshot={exam.takeScreenshot}
        uploadFiles={exam.uploadFiles}
        recordEnabled={exam.recordEnabled}
        isUnattended={exam.isUnattended}
      />
      <div className='flex flex-row h-full'>
        <Wrapped credentials={ExamCredentials} exam={exam} user={user} route='Instructions' />
        <div className='flex flex-col flex-1 overflow-y-scroll'>
          <div className='flex flex-1 items-stretch p-2 pt-0'>
            <div className='flex bg-white min-w-full rounded-sm p-8 pb-16'>
              <div className='flex flex-col flex-1 w-1/2 text-gray-700'>
                <h2 className='text-2xl text-gray-700 mb-6'>{t("exam_guidelines")}</h2>
                <p className='text-base mb-4 font-semibold'>{t("before_exam_remember")}</p>
                <div className='flex items-center mb-4'>
                  <Icons.Record className='w-7 mr-4 text-blue' />
                  <p className='text-base'>
                    {t("cam_mic_required")}
                    {exam.recordEnabled && t("youll_be_recorded")}
                  </p>
                </div>
                {exam.identityRegistration && (
                  <div className='flex items-center mb-4'>
                    <Icons.IdBlue className='w-7 mb-4 text-blue' />
                    <p className='text-base'>{t("id_at_hand")}</p>
                  </div>
                )}
                <div className='flex items-center mb-4'>
                  <Icons.Hand className='w-7 mr-4 text-blue' />
                  <p
                    className='text-base'
                    dangerouslySetInnerHTML={{
                      __html: t("need_help_and_raise_your_hand_html"),
                    }}
                  />
                </div>
                <div className='flex items-center mb-4'>
                  <Icons.Minimize className='w-7 mr-4 text-blue' />
                  <p
                    className='text-base'
                    dangerouslySetInnerHTML={{ __html: t("need_focus_html") }}
                  />
                </div>
                <div className='flex items-center mb-4'>
                  <p className='text-base'>{t("during_exam_host_drop")}</p>
                </div>
                <div className='flex items-center mt-8 mb-4'>
                  <Icons.Alert className='w-7 mr-4 text-blue' />
                  <p className='text-base font-semibold'>{t("youll_be_notified")}</p>
                </div>

                <ul className='list-disc text-base mb-4 pl-4 italic'>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("go_outof_camera_html"),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("allow_into_camera_html"),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("open_tab_window_html"),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("stop_sharing_html"),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("undetectable_stream_html"),
                    }}
                  />
                  <li dangerouslySetInnerHTML={{ __html: t("close_exam_html") }} />
                </ul>
              </div>
              <div className='w-px bg-gray-500 justify-items-center my-8 mx-8'></div>
              <div className='flex flex-col flex-1 w-1/2 custom-instructions'>
                <h2 className='text-2xl text-gray-700 mb-6'>{t("academic_integrity")}</h2>
                {exam.instructions && (
                  <div dangerouslySetInnerHTML={{ __html: exam.instructions }} />
                )}
                {!exam.instructions && (
                  <div className='text-base mb-4 '>
                    <div>{t("trust_based")}</div>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("do_your_work_html"),
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("no_sharing_html"),
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("do_your_best_html"),
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("dishonest_activities_html"),
                        }}
                      />
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <BottomBarBeforeExam nextURL={`/exams/${exam.id}/requirements${search}`} />
      </div>
    </div>
  );
}
