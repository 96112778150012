// FIXME: Pendent to fix ts checks
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { action, computed, makeObservable, observable } from "mobx";
import _ from "lodash/fp";

import { Resetable } from "../../interfaces/resetable";
import type { ExamsUser, RoomExam } from "../../../types";
import ExamsLogger from "../../../logger";

import UserStore from "./user";

import RoomStore from ".";
import moment from "moment";
import SentimentService from "../../../services/sentiment.service";
import { ExamIncidenceType, ExamsUserRole } from "../../../types/enum";

const logPrefix = "[Sentiment Store]";

class SentimentStore implements Resetable {
  roomStore!: RoomStore;

  @observable
  playing = false;

  @observable
  initCY: Promise<void> = null;

  age = 0;
  gender = "";

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
    this.appendScript();
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  @computed
  get localUser(): ExamsUser {
    return this.userStore.localUser;
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.initCY = null;
  }

  @action
  async stopAnalysis(): Promise<void> {
    if (_.isNil(this.initCY)) return;

    this.initCY.then(({ stop }: { stop: Promise<void> }) => stop());
    this.reset();
  }

  @action
  async pausePlayAnalysis(evt: string): Promise<void> {
    if (_.isNil(this.initCY)) return;

    if (evt === "pause" && this.playing) {
      this.initCY.then(({ stop }: { stop: Promise<void> }) => stop());
      this.playing = false;
    }
    if (evt === "play" && !this.playing) {
      this.initCY.then(({ start }: { start: Promise<void> }) => start());
      this.playing = true;
    }
  }

  async appendScript(): Promise<void> {
    // FIXME: We are downloading this source even Morphcast is needed or not. It is unnecesary data consumed by the user
    // find a way to get the exam data without mobx
    const loaded = document.getElementById("morphcast-sdk") !== null;
    if (!loaded) {
      const script = document.createElement("script");
      script.id = "morphcast-sdk";
      script.src = "https://ai-sdk.morphcast.com/v1.15/ai-sdk.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  @action
  async sendAlert(evt: ExamIncidenceType): Promise<void> {
    if (this.localUser.role === ExamsUserRole.HOST || !this.exam.hasStarted) {
      return;
    }
    const sentimentIncidence = {
      examId: this.exam.id,
      sessionId: this.exam.sessionId,
      userId: this.roomStore.info.user.id,
      type: evt,
      guest: this.roomStore.info.user.guest,
    };
    this.roomStore.incidenceStore.createIncidence(sentimentIncidence);
  }

  async initAnalysis(): Promise<void> {
    const id = this.userStore.localUser.videoTrack?.getTrackId();
    const vid = document.getElementById(`video_${id}`);
    const customSource = CY.createSource.fromVideoElement(vid);
    if (typeof CY === "undefined" || customSource == null) {
      this.initAnalysis();
    }
    const cface = { multiFace: true };
    const calarmface = { timeWindowMs: 8000, initialToleranceMs: 10000, threshold: 0.75 };
    const calarmmorefaces = { timeWindowMs: 5000, initialToleranceMs: 10000, threshold: 0.33 };
    const calarmlowatt = { timeWindowMs: 30000, initialToleranceMs: 10000, threshold: 0.75 };

    this.initCY = CY.loader()
      .source(customSource)
      .licenseKey("c60d8cc6fc3e45e188bd4c3195e34441a3c4a72e5dfa")
      .addModule(CY.modules().FACE_DETECTOR.name, cface)
      .addModule(CY.modules().FACE_AGE.name)
      .addModule(CY.modules().FACE_AROUSAL_VALENCE)
      .addModule(CY.modules().FACE_GENDER.name)
      .addModule(CY.modules().FACE_EMOTION.name)
      .addModule(CY.modules().FACE_ATTENTION.name)
      .addModule(CY.modules().ALARM_NO_FACE.name, calarmface)
      .addModule(CY.modules().ALARM_MORE_FACES.name, calarmmorefaces)
      .addModule(CY.modules().ALARM_LOW_ATTENTION.name, calarmlowatt)
      .load();
  }

  throttle(func: () => void, wait: number): void {
    let timeout: NodeJS.Timeout;
    return (...args: any) => {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(this, args);
        }, wait);
      }
    };
  }

  formattedDate(): string {
    return moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  }

  async sentimentBasic(query: any): void {
    if (this.age > 0 && this.gender !== "") {
      const data = {
        ...query,
        sentimentAge: this.age,
        sentimentGender: this.gender,
      };
      await SentimentService.basic(data, this.roomStore.info.user.guest);
    }
  }
  async sentimentAttention(data: any): void {
    await SentimentService.attention(data, this.roomStore.info.user.guest);
  }
  async sentimentEmotional(data: any): void {
    await SentimentService.emotional(data, this.roomStore.info.user.guest);
  }
  async sentimentQuadrant(data: any): void {
    await SentimentService.quadrant(data, this.roomStore.info.user.guest);
  }

  async sentimentDispatcher(type: string, value: any): void {
    const examId = this.exam.id;
    const sessionId = this.exam.sessionId;
    const userId = this.roomStore.info.user.id;
    const query = {
      examId,
      sessionId,
      userId,
    };
    const data = { ...query, sentiment: value };

    switch (type) {
      case "sentiment_basic":
        this.sentimentBasic(query);
        break;
      case "sentiment_attention":
        this.sentimentAttention(data);
        break;
      case "sentiment_emotion":
        this.sentimentEmotional(data);
        break;
      case "sentiment_quadrant":
        this.sentimentQuadrant(data);
        break;
    }
  }

  @action
  sentimentNeeded() {
    if (this.exam?.attentionEnabled || this.exam?.sentimentEnabled || this.exam?.recognitionEnabled) {
      return true;
    }
    
    return false;
  }

  @action
  async startAnalysis(): void {
    if (!this.sentimentNeeded()) {
      return;
    }
    
    this.initAnalysis();
    this.initCY.then(({ start }: { start: Promise<void> }) => {
      ExamsLogger.info(logPrefix, "Starting...");
      this.playing = true;
      start();
    });

    let sntArr = [];
    let snt = 0;
    let attArr = [];
    let att = 0;
    let qrtArr = [];
    let qrt = 0;
    let noFace = true;
    let moreFaces = true;
    let lowAttention = true;
    const memory = 90000;

    if (this.exam.recognitionEnabled) {
      const ageListener = this.throttle((evt) => {
        this.age = evt.detail.output.numericAge;
        this.sentimentDispatcher("sentiment_basic", evt.detail.output.numericAge);
        window.removeEventListener(CY.modules().FACE_AGE.eventName, ageListener, true);
      }, 60000);
      window.addEventListener(CY.modules().FACE_AGE.eventName, ageListener, true);

      const genderListener = this.throttle((evt) => {
        this.gender = evt.detail.output.mostConfident;
        this.sentimentDispatcher("sentiment_basic", evt.detail.output.mostConfident);
        window.removeEventListener(CY.modules().FACE_GENDER.eventName, genderListener);
      }, 60000);
      window.addEventListener(CY.modules().FACE_GENDER.eventName, genderListener);

      window.addEventListener(
        CY.modules().ALARM_NO_FACE.eventName,
        this.throttle((evt) => {
          if (evt.detail.output.noFace && noFace) {
            this.sendAlert(ExamIncidenceType.ABSENT_PARTICIPANT);
            noFace = false;
            lowAttention = false;
            setTimeout(() => {
              lowAttention = true;
            }, memory / 3 + 1000);
            setTimeout(() => {
              noFace = true;
            }, memory);
          }
        }, 1000),
      );
      window.addEventListener(
        CY.modules().ALARM_MORE_FACES.eventName,
        this.throttle((evt) => {
          if (evt.detail.output.moreFaces && moreFaces) {
            this.sendAlert(ExamIncidenceType.PARTICIPANT_NOT_ALONE);
            moreFaces = false;
            setTimeout(() => {
              moreFaces = true;
            }, memory);
          }
        }, 1000),
      );
    }

    if (this.exam.attentionEnabled) {
      window.addEventListener(
        CY.modules().FACE_ATTENTION.eventName,
        this.throttle((evt) => {
          att = {
            attention: parseInt(evt.detail.output.attention * 100, 10),
            date: this.formattedDate(),
          };

          attArr.push(att);
          if (attArr.length === 60) {
            this.sentimentDispatcher("sentiment_attention", attArr);
            attArr = [];
          }
        }, 5000),
      );
      window.addEventListener(
        CY.modules().ALARM_LOW_ATTENTION.eventName,
        this.throttle((evt) => {
          if (evt.detail.output.lowAttention && lowAttention) {
            this.sendAlert(ExamIncidenceType.DROP_LVL_ATTENTION);
            lowAttention = false;
            setTimeout(() => {
              lowAttention = true;
            }, memory);
          }
        }, 1000),
      );
    }

    if (this.exam.sentimentEnabled) {
      window.addEventListener(
        CY.modules().FACE_EMOTION.eventName,
        this.throttle((evt) => {
          snt = {
            sentiment: evt.detail.output.dominantEmotion,
            date: this.formattedDate(),
          };

          sntArr.push(snt);
          if (sntArr.length === 60) {
            this.sentimentDispatcher("sentiment_emotion", sntArr);
            sntArr = [];
          }
        }, 5000),
      );
      window.addEventListener(
        CY.modules().FACE_AROUSAL_VALENCE.eventName,
        this.throttle((evt) => {
          qrt = {
            sentiment: evt.detail.output.quadrant,
            date: this.formattedDate(),
          };

          qrtArr.push(qrt);
          if (qrtArr.length === 60) {
            this.sentimentDispatcher("sentiment_quadrant", qrtArr);
            qrtArr = [];
          }
        }, 5000),
      );
    }
  }
}

export default SentimentStore;
