import { ExamIncidenceType, IncidenceResponseType } from "../channels/exam_incidences_channel";
import { ID } from "../types";
import client from "./client";

export type CreateParams = {
  examId: ID;
  sessionId: ID;
  userId: ID;
  type: ExamIncidenceType;
  guest: boolean;
  message?: string;
};

export const getAll = async (data: CreateParams): Promise<IncidenceResponseType[]> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const url = "/exam-service/v1/exams/reviews/incidences";
  return await client
    .get(url, configs)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const create = async (data: CreateParams): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = {
    incidenceType: data.type,
    message: data.message || data.type,
  };

  // const baseurl = window.location.href.indexOf('demo') !== -1 ? '/demo' : '';
  const url = `/exam-service/v1/sessions${data.guest ? "/guests" : ""}/${data.sessionId}/incidence`;

  await client
    .post(url, postData, configs)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

const IncidenceService = {
  getAll,
  create,
};

export default IncidenceService;
