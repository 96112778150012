import React from "react";
import { observer } from "mobx-react-lite";

import {
  useUserStore,
  useChatStore,
  useHandStore,
  useViewModeStore,
  useIncidenceStore,
} from "../../hooks/store";
import { ExamsUser } from "../../types";

import Participant from "./Participant/Participant";

const Participants: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const handStore = useHandStore();
  const chatStore = useChatStore();
  const viewModeStore = useViewModeStore();
  const incidenceStore = useIncidenceStore();

  const onClickChat = (participant: ExamsUser) => {
    chatStore.focusChatUser(participant.uid);
  };

  const handleDoubleClick = async (participant: ExamsUser) => {
    const index = userStore.participantIndex(participant.uid);

    if (viewModeStore.isUserFocused(index)) {
      viewModeStore.changeMode("PARTICIPANT");
      chatStore.setSelected(null);
      incidenceStore.setSelected(null);
      userStore.listenToAllParticipants();
      await userStore.speakToAllParticipants();
      return;
    }

    viewModeStore.changeUserRange("ONE_ON_ONE", { from: index, to: index + 1 });
    viewModeStore.changeMode("ONE_ON_ONE");
  };

  return (
    <div className='flex flex-col h-[calc(100%-3rem)] bg-white'>
      {userStore.participants.map((participant, index) => (
        <Participant
          key={participant.uid}
          participant={participant}
          odd={(index + 1) % 2 !== 0}
          onDoubleClick={handleDoubleClick}
          handRaise={{
            isRaised: handStore.isHandRaised(participant.uid),
          }}
          incidentTriggered={{
            color: incidenceStore.isIncidentTriggered(participant.uid),
          }}
          hasAbandoned={incidenceStore.userDropped(participant.uid)}
          messages={{
            hasUnread: chatStore.hasUnreadMessages(participant.uid),
            onClick: onClickChat,
          }}
          onlineState={chatStore.peerStatus(participant.uid)}
        />
      ))}
    </div>
  );
});

export default Participants;
