import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locale/en.json";
import es from "./locale/es.json";
import ja from "./locale/ja.json";

type Language = "en" | "es" | "ja";
type CountryIdValidation = "US" | "ES" | "JP";
type LanguageIdValidation = "en-US" | "ja" | "es-MX"

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ja: {
    translation: ja,
  },
} as const;

const getLang = (): Language => {
  if (navigator.language === "es-ES") {
    return "es";
  }
  if (navigator.language === "ja") {
    return "ja";
  }
  return "en";
};

export const getCountryIdValidation = (): CountryIdValidation => {
  if (navigator.language === "es-ES") {
    return "ES";
  }
  if (navigator.language === "ja") {
    return "JP";
  }
  return "US";
};

export const getLangIdValidation = (): LanguageIdValidation => {
  if (navigator.language === "es-ES") {
    return "es-MX";
  }
  if (navigator.language === "ja") {
    return "ja";
  }
  return "en-US";
};

i18n.use(initReactI18next).init({
  lng: getLang(),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
