import { observer } from "mobx-react-lite";
import React from "react";
import _ from "lodash/fp";
import { UID } from "agora-rtc-sdk-ng";

import { useChatStore, useUserStore } from "../../../hooks/store";
import Messages from "../Messages/Messages";
import ChatFilter from "../ChatFilter/ChatFilter";

const HostChat: React.FunctionComponent = observer(() => {
  const chatStore = useChatStore();
  const userStore = useUserStore();

  const selectedUID = chatStore.selected;

  const clearMessages = (uid: UID | null) => {
    if (_.isNil(uid)) return;

    chatStore.clearUnreadMessages(uid);
  };

  const selectUser = (uid: UID | null) => {
    chatStore.setSelected(uid);
    clearMessages(uid);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='px-4'>
        <ChatFilter
          selected={chatStore.selected}
          onSelect={selectUser}
          users={userStore.participants}
        />
      </div>
      <div
        className='flex-1 mt-2'
        onFocus={() => clearMessages(selectedUID)}
        onClick={() => clearMessages(selectedUID)}
      >
        {_.isNil(selectedUID) ? (
          <Messages
            messageList={chatStore.channelMessages}
            onSendMessage={(msg) => chatStore.sendMessageToChannel(msg)}
            localUid={userStore.localUser.uid}
            isHost
          />
        ) : (
          <Messages
            messageList={chatStore.directMessages(selectedUID)}
            onSendMessage={(msg) => chatStore.sendMessageToPeer(msg, selectedUID)}
            localUid={userStore.localUser.uid}
            isHost
          />
        )}
      </div>
    </div>
  );
});

export default HostChat;
