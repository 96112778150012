import _ from "lodash/fp";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../../services/i18n/config";
import events from "../../../../lib/events";
import EmojiPicker from "../EmojiPicker/EmojiPicker";

type Props = {
  onSend: (message: string) => void;
};

const InputMessage: React.FunctionComponent<Props> = ({ onSend }: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState("");

  const onSubmit = () => {
    if (_.isEmpty(message)) return;

    onSend(message);
    setMessage("");
  };

  const onSelectEmoji = (emoji: string) => {
    setMessage((msg) => msg + emoji);
  };

  return (
    <div className='flex flex-row space-x-2 relative'>
      <div className='flex items-center justify-center'>
        <EmojiPicker onSelect={onSelectEmoji} />
      </div>
      <form onSubmit={events.preventDefaultTrigger(onSubmit)} className='flex-1'>
        <input
          id='chat-input'
          type='text'
          className='h-10 rounded-full w-full border-2 appearance-none text-xs border-gray-300 placeholder-gray-300 font-light focus:outline-none focus:bg-white focus:border-gray-500'
          onChange={events.handleStringChange(setMessage)}
          value={message}
          placeholder={t("send_message")}
        />
      </form>
    </div>
  );
};

export default InputMessage;
