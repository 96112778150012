import { ID } from "../types";
import client from "./client";

type CreateParams = {
  snapshotId: ID;
  comment: string;
  userId: ID;
  examId: ID;
  sessionId: ID;
};

export const create = async (data: CreateParams, guest?: boolean): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
      userId: data.userId,
    },
  };
  const postData = {
    comment: data.comment,
  };
  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${data.sessionId}/snapshot/${
    data.snapshotId
  }`;
  const response = await client.put(url, postData, configs);
  return response.data;
};

const SnapshotService = { create };

export default SnapshotService;
