import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { ModalOpenRoom } from "../../../types";
import BlueButton from "../../Button/BlueButton/BlueButton";
import Button from "../../Button/Button";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalOpenRoom;
};

const OpenRoom: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose} className='w-1/2 !px-6 rounded-md'>
      <div className='flex flex-col space-y-4 mx-8 w-md text-left'>
        <div className='font-bold text-lg'>{t("you_are_going_to_open_the_session_before_the_scheduled_time")}</div>
        <div>
          <p>{t("remember_open_the_room")}</p>
        </div>
        <div className='flex items-center self-end gap-2' id='modal-finish'>
          <Button
            onClick={() => { onClose(); }}
            className={"bg-white border border-gray-400 text-gray-600"}>
            {t("cancel")}
          </Button>
          <BlueButton
            onClick={() => {
              onClose();
              data.onConfirm();
            }}
          >
            {t("open_the_room")}
          </BlueButton>
        </div>
      </div>
    </Base>
  );
};

export default OpenRoom;
