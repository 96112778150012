import { observer } from "mobx-react-lite";
import React from "react";

import { useUserStore } from "../../hooks/store";

import HostCall from "./HostCall/HostCall";
import ParticipantCall from "./ParticipantCall/ParticipantCall";

const VideoCall: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();

  if (!userStore.localUser?.role) {
    return null;
  }
  return (
    <div className='p-2 pt-0 flex-1'>
      {userStore.isLocalHost() ? <HostCall /> : <ParticipantCall />}
    </div>
  );
});

export default VideoCall;
