import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { ModalExitExamData } from "../../../types";
import RedButton from "../../Button/RedButton/RedButton";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalExitExamData;
};

const ExitExam: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose}>
      <div className='flex flex-col space-y-10 mx-8'>
        <div className='font-bold text-xl'>{t("have_you_finished_the_exam")}</div>
        <div className='flex items-center justify-center' id='modal-finish'>
          <RedButton
            onClick={() => {
              onClose();
              data.onExit();
            }}
          >
            {t("end_exam")}
          </RedButton>
        </div>
      </div>
    </Base>
  );
};

export default ExitExam;
