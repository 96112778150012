import { action, makeObservable } from "mobx";

import AppStore from "..";
import { Resetable } from "../../interfaces/resetable";

import CollapsedStore from "./collapsed";
import LoadingStore from "./loading";
import ModalStore from "./modal";
import NotifyStore from "./notify";
import ToastStore from "./toast";

class UIStore implements Resetable {
  loading: LoadingStore;

  toast: ToastStore;

  modal: ModalStore;

  collapsed: CollapsedStore;

  notify: NotifyStore;

  appStore!: AppStore;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.loading = new LoadingStore(this);
    this.toast = new ToastStore(this);
    this.modal = new ModalStore(this);
    this.collapsed = new CollapsedStore(this);
    this.notify = new NotifyStore(this);
  }

  @action
  reset(): void {
    this.loading.reset();
    this.toast.reset();
    this.modal.reset();
    this.collapsed.reset();
  }
}

export default UIStore;
