import axios from "axios";
import { ServiceError } from "./ServiceError";

async function handleError(error: unknown): Promise<ServiceError> {
  if (axios.isAxiosError(error)) {
    return new ServiceError(error.message, error.response?.data.code, error.response?.status);
  } else {
    return new ServiceError("UNKNOWN");
  }
}

export default handleError;
