import { observer } from "mobx-react-lite";
import React from "react";
import _ from "lodash/fp";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useModalStore, useRoomStore, useToastStore } from "../../../hooks/store";
import { Loading } from "../../Loading/Loading";
import Icons from "../../../images/icons";
import screenshot from "../../../lib/screenshot";
import snapshotService from "../../../services/snapshot.service";
import documentService from "../../../services/document.service";
import { ServiceError } from "../../../services/util/ServiceError";
import dataURLtoFile from "../../../lib/file";
import { ID } from "../../../types";

type Props = {
  disabled?: boolean;
};

const Snapshot: React.FunctionComponent<Props> = observer(({ disabled }: Props) => {
  const { t } = useTranslation();
  const modalStore = useModalStore();
  const roomStore = useRoomStore();
  const toastStore = useToastStore();

  const [loading, setLoading] = React.useState(false);

  const uploadSnapshot = async (image: string): Promise<ID> => {
    const entityType = "EXAM";
    const entityId = roomStore.info.exam.id;
    const name = "snapshot" + Date.now() + ".png";
    const snapshot = dataURLtoFile(image, name);
    const documentType = "SNAPSHOT";

    const documentCreated = await documentService.createDocument(
      entityType,
      entityId,
      snapshot,
      documentType,
      roomStore.info.user.guest ? roomStore.info.user.id : null,
    );
    if (documentCreated instanceof ServiceError) {
      throw new Error("ERR_UPLOAD_IMG");
    }

    const s3Url = await documentService.generateURLToUploadToS3(
      entityType,
      entityId,
      String(documentCreated.id),
      roomStore.info.user.guest ? roomStore.info.user.id : null,
    );
    if (s3Url instanceof Error) {
      throw new Error("ERR_UPLOAD_IMG");
    }

    const s3Document = await documentService.uploadToS3(s3Url.url, snapshot);
    if (s3Document instanceof Error) {
      throw new Error("ERR_UPLOAD_IMG");
    }
    return documentCreated.id;
  };

  const onSubmitSnaphot = async (image: string, comment: string): Promise<void> => {
    const snapshotId = await uploadSnapshot(image);

    try {
      await snapshotService.create(
        {
          snapshotId,
          comment,
          examId: roomStore.info.exam.id,
          sessionId: roomStore.info.exam.sessionId,
          userId: roomStore.info.user.id,
        },
        roomStore.info.user.guest,
      );
      toastStore.addToast(t("successfully_uploaded_snapshot"));
    } catch (e) {
      toastStore.addToast(t("error_uploading_snapshot"));
    }
  };

  const openScreenshotModal = async (): Promise<void> => {
    setLoading(true);
    const image = await screenshot.makeScreenshot();
    if (!_.isNil(image)) {
      modalStore.openModal("SCREENSHOT", { image, onSubmit: onSubmitSnaphot });
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      data-test-id='snapshot-btn'
      className={`text-gray-250 ${
        !disabled && "cursor-pointer"
      } flex flex-col items-center justify-between`}
      onClick={!disabled ? openScreenshotModal : undefined}
    >
      <Icons.Snapshot className='h-6' />
      <span className='text-xs text-center leading-4'>{t("snapshot")}</span>
    </div>
  );
});

export default Snapshot;
