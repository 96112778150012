import * as StompJs from "@stomp/stompjs";
import { Client } from "@stomp/stompjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const connectChannel = (onConnect: any, token: string): Client => {
  let stompClient = null;

  const stompConfig = {
    brokerURL: `${process.env.REACT_APP_WSS_BASE_URL}/communication-service/connect?Authorization=${token}`,
    reconnectDelay: 1000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,

    onConnect: onConnect,
  };
  stompClient = new StompJs.Client(stompConfig);

  stompClient.onWebSocketClose = function websocketOnClose() {
    console.log("Stomp client closed");
  };

  stompClient.beforeConnect = function () {
    console.log("Stomp client connected");
  };

  stompClient.onStompError = function (frame) {
    console.log("Broker reported error: " + frame.headers["message"]);
    console.log("Additional details: " + frame.body);
  };

  stompClient.activate();

  return stompClient;
};

export default connectChannel;
