import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import Icons from "../../../images/icons";
import { useRoomStore } from "../../../hooks/store";

const ShareScreen: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const roomStore = useRoomStore();
  const shared = roomStore.rtc.screenStore.localVideoTrack != null;

  const onShareScreen = () => {
    if (shared) return;
    (async () => {
      await roomStore.rtc.screenStore.shareScreen();
    })();
  };

  return (
    <div
      className={`${ shared ? "text-gray-500" : "text-gray-250 cursor-pointer" } flex flex-col items-center justify-between`}
      onClick={onShareScreen}
    >
      <div className='relative'>
        <Icons.ScreenShare className='h-8' />
      </div>
      <span className='text-xs text-center leading-4'>
       { t("share_screen") }
      </span>
    </div>
  );
});

export default ShareScreen;
