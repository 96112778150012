import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppWrapper from "../../AppWrapper/AppWrapper";
import { ExamCredentials } from "../../../factories";
import Protected from "./Protected";
import { useAuth0 } from "@auth0/auth0-react";
import useQuery from "../../../hooks/useQuery";
import {
  addAccessTokenInterceptor,
  addAccessTokenInterceptorForGuests,
} from "../../../services/client";
import { sec } from "../../../services/util/security";
import SessionGuestService from "../../../services/session/sessions.guest.service";
import Loading from "../Loading/Loading";

export default function ProtectedWrapper() {
  const { id } = useParams();
  const { getAccessTokenSilently, user, isLoading, isAuthenticated, loginWithPopup } = useAuth0();
  const [showApp, setShowApp] = useState(false);
  const query = useQuery();
  const ltk = query.get("ltk");
  const lms = query.get("lms");

  if (!id || window.location.pathname === "/" || !query.get("accountId")) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }
  const navigate = useNavigate();

  const checkAccessType = useCallback(async () => {
    const result = await SessionGuestService.getExamInfo(id, query.get("accountId"));
    return result;
  }, [id, query]);

  useEffect(() => {
    if (ltk || lms) {
      setShowApp(true);
    } else if (!isLoading) {
      if (isAuthenticated) {
        addAccessTokenInterceptor(getAccessTokenSilently, user);
        sec.setAuthUser(user);
        sec.setAccessTokenSilently(getAccessTokenSilently);
        setShowApp(true);
      } else {
        checkAccessType().then((result) => {
          if (!result.allowGuest) {
            navigate(`/exams/${id}?${query}`);
          }
          addAccessTokenInterceptorForGuests(query.get("accountId"));
          setShowApp(true);
        });
      }
    }
  }, [
    checkAccessType,
    getAccessTokenSilently,
    id,
    isAuthenticated,
    isLoading,
    loginWithPopup,
    ltk,
    lms,
    navigate,
    query,
    user,
  ]);

  if (isLoading || !showApp) {
    return <Loading />;
  }

  return (
    <AppWrapper credentials={ExamCredentials}>
      <Protected examId={id} />
    </AppWrapper>
  );
}
