import React from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";

type QuestionProps = {
  index: number;
  question: string;
  handleFeedback: any;
};

type Props = React.PropsWithChildren<QuestionProps>;

const Question: React.FunctionComponent<Props> = ({ index, question, handleFeedback }: Props) => {
  const { t } = useTranslation();
  const handleRating = (rate: number) => {
    handleFeedback(index, question, rate);
  };

  return (
    <>
      {index === 4 && <hr />}
      <div className='flex justify-between items-center'>
        <h3>{t(question)}</h3>
        <Rating onClick={handleRating} />
      </div>
    </>
  );
};

export default Question;
