import React from "react";

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const RoundedCard: React.FunctionComponent<Props> = ({ children, className = "" }: Props) => (
  <div className={`rounded-sm overflow-hidden ${className}`}>{children}</div>
);

export default RoundedCard;
