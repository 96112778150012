import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";

import { useUserStore, useViewModeStore } from "../../../../hooks/store";
import UserPlayer from "../../../Players/UserPlayer/UserPlayer";
import RoundedCard from "../../../RoundedCard/RoundedCard";
import LeftButton from "../Button/LeftButton/LeftButton";
import RightButton from "../Button/RightButton/RightButton";

const ParticipantView: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const userStore = useUserStore();

  const users = userStore.participantModeParticipants;
  const maxUsers = viewModeStore.getNumUsers("PARTICIPANT");
  const enoughUsers = _.size(userStore.participants) > maxUsers;
  const columns = viewModeStore.getUsersColumns("PARTICIPANT", _.size(users));
  const rows = viewModeStore.getUsersRows("PARTICIPANT", _.size(users));

  if (_.isEmpty(users)) {
    return null;
  }

  return (
    <div
      className='grid gap-2 h-full w-full relative min-h-full'
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)` ,
        gridTemplateRows: `repeat(${rows}, 1fr`,
      }}
    >
      {enoughUsers && <LeftButton onClick={() => viewModeStore.prev("PARTICIPANT")} />}
      {users.map((participant) => (
        <div
          onDoubleClick={() => userStore.doubleClickOnParticipant(participant)}
          key={participant.uid}
        >
          <RoundedCard className='h-full'>
            <UserPlayer
              focus='PARTICIPANT'
              className='w-full h-full'
              object_fit_contain
              user={participant}
            />
          </RoundedCard>
        </div>
      ))}
      {enoughUsers && <RightButton onClick={() => viewModeStore.next("PARTICIPANT")} />}
    </div>
  );
});

export default ParticipantView;
