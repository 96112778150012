import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import RedButton from "../../Button/RedButton/RedButton";
import Reset from "../Reset/Reset";
import Mute from "../Mute/Mute";
import Chat from "../Chat/Chat";
import Recording from "../Recording/Recording";
import SpeakTo from "../SpeakTo/SpeakTo";
import { useAuthorizationStore, useModalStore, useRecordStore, useRoomStore, useViewModeStore } from "../../../hooks/store";
import BlueButton from "../../Button/BlueButton/BlueButton";
import Comment from "../Comment/Comment";
import useQuery from "../../../hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Expel from "../Expel/Expel";
import dateHelper from "../../../lib/date";
import { AccountPlan } from "../../../types/enum";
import { Account } from "../../../types";
import Deafen from "../Deafen/Deafen";

const HostBottomBar: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const recordStore = useRecordStore();
  const viewModeStore = useViewModeStore();
  const authorizationStore = useAuthorizationStore();
  const [starting, setStarting] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [freemium, setFreemium] = useState(false);
  const query = useQuery();
  const navigate = useNavigate();
  const { search } = useLocation();

  const onClickEndExam = async () => {
    modalStore.openModal("END_EXAM", {
      examName: roomStore.examName,
      incidences: [
        roomStore.incidenceStore.uniqueIncidencesTriggered,
        roomStore.incidenceStore.participantsPerIncidence,
      ],
      onRestart: async () => {
        await recordStore.startRecording();
        await roomStore.startExam();
      },
      onEnd: async () => {
        await roomStore.endExam();
        if (query.get("ltk")) {
          navigate(`/exams/${roomStore.info.exam.id}/finished${search}`);
        } else {
          window.location.href = process.env.REACT_APP_BASE_URL;
        }
      },
    });
  };

  const onClickStartExam = async () => {
    setStarting(true);
    setHasStarted(true);
    await recordStore.startRecording();
    await roomStore.startExam();
    setStarting(false);
  };


  useEffect(() => {
    authorizationStore.getAccount(query.get("accountId")).then((account: Account)=>{
      if(account){
        setFreemium(account.plan === AccountPlan.FREEMIUM);
      }
    });

    if (hasStarted && !starting && freemium) {
      const timer = setInterval(async () => {
        const diff = dateHelper.getTimeDiff(roomStore.info.exam.manualStartDate);
        if (diff == "10:00") {
          modalStore.openModal("FREEMIUM_LIMIT", {
            manualStartDate: roomStore.info.exam.manualStartDate,
          });
        } else if (diff == "00:01") {
            await roomStore.endExam();
            if (query.get("ltk")) {
              navigate(`/exams/${roomStore.info.exam.id}/finished${search}`);
            } else {
              window.location.href = process.env.REACT_APP_BASE_URL;
            }
          clearInterval(timer);
        }
      }, 1000);
    }
  }, [hasStarted, starting, freemium]);

  return (
    <div className='flex flex-row w-full justify-between'>
      <div className='flex flex-row space-x-8 '>
        <SpeakTo />
      </div>
      <div className='flex flex-row space-x-12 '>
        <Mute />
        <Deafen />
        <Reset />
        <Chat />
        <Comment />
        {roomStore.isRecordingEnabled && <Recording />}
        {viewModeStore.isMode("ONE_ON_ONE") && <Expel/>}
      </div>
      <div className='flex flex-row items-center space-x-6'>
        {roomStore.hasStarted ? (
          <div data-test-id='end_button'>
            <RedButton className='whitespace-nowrap text-lg' onClick={onClickEndExam}>
              {t("end_restart_session")}
            </RedButton>
          </div>
        ) : (
          <div data-test-id='start_button'>
            <BlueButton
              disabled={starting}
              className='whitespace-nowrap text-lg'
              onClick={onClickStartExam}
            >
              {t("start_session")}
            </BlueButton>
          </div>
        )}
      </div>
    </div>
  );
});

export default HostBottomBar;
