import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useUserStore, useToastStore, useViewModeStore } from "../../../hooks/store";
import { ExamsUser } from "../../../types";
import Popover from "../../Popover/Popover";
import Icons from "../../../images/icons";
import styles from "../BottomBar.module.scss";

const SpeakTo: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const toastStore = useToastStore();
  const viewModeStore = useViewModeStore();
  const disabled = viewModeStore.isMode("ONE_ON_ONE");
  let iconToRender: ReactElement;
  let toastSpeaking: string;
  let toastListening: string;


  const audioIcon = () => {
    const listenTo = userStore.cameraStore.listenTo;
    const speakTo = userStore.cameraStore.speakTo;

    switch (`${speakTo}-${listenTo}`) {
      case "all-all":
        iconToRender = <Icons.AudioOnSpeakerOn className='h-6' />;
        toastSpeaking = t("speaking_to_all");
        toastListening = t("listening_to_all");
        break;
      case "none-all":
        iconToRender = <Icons.AudioOffSpeakerOn className='h-6' />;
        toastSpeaking = t("speaking_to_none");
        toastListening = t("listening_to_all");
        break;
      case "all-none":
        iconToRender = <Icons.AudioOnSpeakerOff className='h-6' />;
        toastSpeaking = t("speaking_to_all");
        toastListening = t("listening_to_none");
        break;
      case "none-none":
        iconToRender = <Icons.AudioOffSpeakerOff className='h-6' />;
        toastSpeaking = t("speaking_to_none");
        toastListening = t("listening_to_none");
        break;
      default:
        iconToRender = <Icons.AudioOne className='h-6' />;
        toastSpeaking = t("speaking_to");
        toastListening = t("listening_to_all");
    }
  
    return iconToRender;
  };
  
  if (!userStore.isLocalHost()) {
    return null;
  }

  const onSpeakToParticipant = async (participant: ExamsUser) => {
    userStore.listenToAllParticipants();
    await userStore.speakToOneParticipant(participant.uid);
    audioIcon();
    toastStore.addToast(`${t("speaking_to")} ${participant.username}`);
    toastStore.addToast(toastListening);
  };

  const onSpeakToAll = async () => {
    userStore.listenToAllParticipants();
    await userStore.speakToAllParticipants();
    audioIcon();
    toastStore.addToast(toastSpeaking);
    toastStore.addToast(toastListening);
  };

  const onSpeakToNone = async () => {
    userStore.listenToAllParticipants();
    await userStore.speakToNoneParticipants();
    audioIcon();
    toastStore.addToast(toastSpeaking);
    toastStore.addToast(toastListening);
  };

  return (
    <Popover
      disabled={disabled}
      content={
        <div className={`${styles.popover}`}>
          <span className={`${styles.popoverOption}`}>{t("audio_channel_to")}</span>
          <span className={`${styles.popoverOption}`} onClick={onSpeakToAll}>
          <Icons.AudioOnSpeakerOn className='h-6' />
            {t("audio_all")}
          </span>
          <Popover
            position='right-end'
            content={
              userStore.totalParticipants > 0 ? (
                <div className={`${styles.popover} max-h-60 overflow-auto`}>
                  {userStore.participants.map((participant) => (
                    <span
                      key={participant.uid}
                      className={`${styles.popoverOption} `}
                      onClick={() => onSpeakToParticipant(participant)}
                    >
                      {participant.username}
                    </span>
                  ))}
                </div>
              ) : null
            }
          >
            <span className={`${styles.popoverOption}`}>
              <Icons.AudioOne className='h-6' />
              {t("audio_to")}
              ...
            </span>
          </Popover>
          <span className={`${styles.popoverOption}`} onClick={onSpeakToNone}>
          <Icons.AudioOffSpeakerOn className='h-6' />
            {t("audio_none")}
          </span>
        </div>
      }
    >
      <div className={`${styles.popoverButton}`}>
        <div className='relative'>
          { audioIcon() }
          <Icons.Chevron
            className='absolute w-2 top-0 -right-4 transform rotate-180'
            width='10px'
          />
        </div>
        <span className='text-xs text-center leading-4'>{t("audio_channel_to")}</span>
      </div>
    </Popover>
  );
});

export default SpeakTo;
