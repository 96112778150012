import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../services/i18n/config";
import { useLoadingStore } from "../../hooks/store";

import styles from "./Loading.module.scss";

export const Loading: React.FunctionComponent = () => <div className={styles.lds_dual_ring} />;

const LoadContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const store = useLoadingStore();

  if (store.loading) {
    return (
      <div className='fixed inset-0 flex flex-col items-center justify-center bg-black opacity-100 z-50'>
        <Loading />
        <div className='mt-2 text-blue font-bold text-lg'>
          {t("loading")}
          ...
        </div>
      </div>
    );
  }

  return null;
});

export default LoadContainer;
