import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthorizationStore } from "../../hooks/store";
import useQuery from "../../hooks/useQuery";
import Icons from "../../images/icons";
import FeedbackService from "../../services/feedback.service";
import { RoomExam } from "../../types";
import Button from "../Button/Button";
import Question from "../Pages/Feedback/Question";

type Props = {
  exam: RoomExam;
};

const Evaluation: React.FunctionComponent<Props> = ({ exam }: Props) => {
  const [ratings, setRatings] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [questions, setQuestions] = useState([]);
  const authorizationStore = useAuthorizationStore();
  const query = useQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const postRating = async () => {
    const data = {
      questions: ratings,
      examId: exam.id,
      sessionId: exam.sessionId,
      userId: authorizationStore.roomUser.id,
    };
    return await FeedbackService.create(data, authorizationStore.roomUser.guest);
  };

  const handleFeedback = (index: number, question: string, star: number) => {
    const ratingsAux = ratings;
    ratingsAux[index] = {
      question,
      star,
      defaultQuestion: authorizationStore.sessionResult.defaultFeedback && index < 4,
    };
    setRatings(ratingsAux);
    setDisabled(!(questions.length === ratings.length));
  };

  const onSubmit = async () => {
    await postRating()
      .then((response) => {
        if (response.status === 200 && response.data.id !== null) {
          if (authorizationStore.roomUser.guest || query.get("ltk") || query.get("lms")) {
            navigate(`/exams/${exam.id}/finished${search}`);
          } else {
            window.location.href = process.env.REACT_APP_BASE_URL;
          }
        }
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
  };

  const onClose = () => {
    window.location.href = (authorizationStore.roomUser.guest || (query.get("ltk")) || query.get("lms")) ? `/exams/${exam.id}/finished${search}` : process.env.REACT_APP_BASE_URL
  }

  useEffect(() => {
    if (authorizationStore.sessionResult.defaultFeedback) {
      let q: string[] = Array.from(authorizationStore.sessionResult.defaultFeedback);
      if (exam.feedbackQuestions) {
        q = q.concat(exam.feedbackQuestions);
      }
      setQuestions(q);
    } else if (authorizationStore.sessionResult.feedback) {
      const q: string[] = Array.from(authorizationStore.sessionResult.feedback);
      setQuestions(q);
    }
  }, [
    authorizationStore.sessionResult.defaultFeedback,
    authorizationStore.sessionResult.feedback,
    exam.feedbackQuestions,
  ]);

  return (
    <div
      className='fixed z-10 inset-0 overflow-hidden visible bg-opacity-0'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      id='feedback-form-modal'
    >
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity'
          aria-hidden='true'
        ></div>

        <div
          className='flex justify-center mx-auto transition-all duration-500 ease-out'
          id='feedback-form-modal'
        >
          <div className='absolute inset-0 flex items-center justify-center bg-gray-700 bg-dark-transparent'>
            <div className='px-12 bg-[#FCFCFD] text-[#23262F] text-center relative'>
              <Icons.Close onClick={onClose} className='absolute right-7 top-7 cursor-pointer w-3' />
              <div className='p-6'>
                <div className='items-center justify-between mt-4 relative'>
                  <h1 className='font-bold'>{t("rate_your_experience")}</h1>
                  <p className='mt-3'>{t("rank_these_factors")}</p>
                </div>
                <div className='flex justify-center'>
                  <form
                    action=''
                    className='w-full mt-4 mb-9 text-left flex flex-col justify-center items-center'
                  >
                    <div className='border-[#4B506D66] w-full border px-12 pb-8 pt-6 rounded-md'>
                      <div className='grid gap-4 mt-4 font-bold flex'>
                        {questions.map((question, i) => {
                          return (
                            <Question
                              question={question}
                              handleFeedback={handleFeedback}
                              index={i}
                              key={i}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className='flex justify-end mt-8 w-full px-4 space-x-4'>
                      <Button onClick={onClose} className='btn btn-lg btn-blue borderless'>{t("cancel")}</Button>
                      <Button
                        onClick={onSubmit}
                        disabled={disabled}
                        className='btn btn-blue btn-md'
                      >
                        {t("submit")}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evaluation;
