import React from "react";
import { format } from "date-fns/fp";
import sanitizeHtml from "sanitize-html";

import { ExamsMessage } from "../../../../types";

type Props = {
  message: ExamsMessage;
};

const LocalMessage: React.FunctionComponent<Props> = ({ message }: Props) => {
  const URLify = (_message: string) => {
    // eslint-disable-next-line no-useless-escape
    const urls = _message.match(/((((https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    if (urls) {
      urls.forEach((url) => {
        // eslint-disable-next-line no-param-reassign
        _message = _message.replace(
          url,
          `<a class="text-[#1A1A1A] underline" target="_blank" href="${url}">${url}</a>`,
        );
      });
    }
    return _message;
  };

  const sanitize = (dirty) => ({
    __html: sanitizeHtml(URLify(dirty), {
      allowedTags: ["a"],
      allowedAttributes: {
        a: ["href", "target", "class"],
      },
    }),
  });

  return (
    <div className='self-end flex flex-col max-w-[100%]'>
      <div
        className='bg-blue text-white py-2 px-3 rounded-t-lg rounded-bl-lg break-all leading-5'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={sanitize(message.text)}
      />
      <div className='flex flex-row justify-end text-2xs italic leading-2 mt-2'>
        <span className='block'>{format("HH:mm", message.timestamp)}</span>
      </div>
    </div>
  );
};

export default LocalMessage;
