import React from "react";
import { useTranslation } from "react-i18next";

import "../../services/i18n/config";
import Icons from "../../images/icons";

import styles from "./IdentityBlock.module.scss";


type Props = {
  docCheck: any;
  overallResult: any;
  username: string;
  type?: "primary" | "secondary";
};

const IdentityBlock: React.FunctionComponent<Props> = ({
  docCheck,
  overallResult,
  username,
  type = "primary",
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`${type} ${type === "primary" ? styles.block : styles.block_secondary}`}>
      <div className={styles.title}>
        {overallResult && (
          <Icons.Security
            className={
              overallResult.status === "approved" ? "h-4 mr-1 text-green" : "h-4 mr-1 text-red"
            }
          />
        )}
        <h3>{t("identity_validation")}</h3>
      </div>
      <div className={styles.content}>
        <div className={`${styles.data} space-x-2`}>
          <Icons.User className='w-[20px] h-[20px]' />
          <div>
            <h5>{t("name")}</h5>
            <p>{`${docCheck.attributes["name-first"]} ${docCheck.attributes["name-last"]}`}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Envelop className='w-[20px] h-[16px] text-blue' />
          <div>
            <h5>{t("email")}</h5>
            <p>{username}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Globe className='w-[20px] h-[16px] text-blue' />
          <div>
            <h5>{t("country")}</h5>
            <p>{docCheck.attributes["country-code"]}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.FileBlank className='text-blue w-[16px] h-[20px]' />
          <div>
            <h5>{t("document_type")}</h5>
            <p>{docCheck.type.split("/")[1].replace("-", " ").toUpperCase()}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Exclamation className='text-blue w-[16px] h-[20px]' />
          <div>
            <h5>{t("expiration_date")}</h5>
            <p>{docCheck.attributes["expiration-date"]}</p>
          </div>
        </div>
        <div className={`${styles.data} space-x-2`}>
          <Icons.Bookmark className='text-blue w-[16px] h-[20px]' />
          <div>
            <h5>{t("birth_date")}</h5>
            <p>{docCheck.attributes.birthdate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityBlock;
