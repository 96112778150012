import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { useRoomStore, useViewModeStore } from "../../../hooks/store";
import { Views } from "../../../types";
import Dropdown from "../../Dropdown/Dropdown";

import PlusAndMinus from "./PlusAndMinus/PlusAndMinus";
import Icons from "../../../images/icons";

const ViewModeDropdown: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const viewModeStore = useViewModeStore();
  const roomStore = useRoomStore();
  const currentMode = viewModeStore.mode;
  const maxUsers = viewModeStore.getNumUsers(currentMode);
  const enoughUsers = _.size(roomStore.userStore.participants) > maxUsers;

  const options = [
    { value: "PARTICIPANT", label: t("participant") },
    { value: "ONE_ON_ONE", label: t("one_on_one") },
  ];
  if (roomStore.info.exam.screenSharing) {
    options.splice(1, 0, { value: "EXAM", label: _.capitalize(t("exam")) });
    options.splice(2, 0, { value: "HYBRID_EXAM", label: t("hybrid_exam") });
    options.splice(3, 0, { value: "HYBRID_PARTICIPANT", label: t("hybrid_participant") });
  }
  if (roomStore.info.exam.identityRegistration) {
    options.splice(3, 0, { value: "IDENTITY", label: t("identity") });
  }

  const selectedMode = _.find({ value: currentMode }, options);
  let autoStyle = viewModeStore.scroll? "text-[#FFFFFF] bg-[#2D8CFF]" : "text-[#ACACAC] bg-gray-700";
  autoStyle += enoughUsers ? " cursor-pointer" : " cursor-not-allowed" ; 

  const controlScroll = () => {
    if(enoughUsers)
      viewModeStore.controlScroll(currentMode);
  };

  const controlSwitch = () => {
    viewModeStore.controlSwitch();
  };

  return (
    <div className='flex flex-row items-center justify-center space-x-1'>
      {
        (viewModeStore.isMode("HYBRID_EXAM") || viewModeStore.isMode("HYBRID_PARTICIPANT")) 
        && 
        <div className="p-1 rounded text-[#ACACAC] bg-gray-700" onClick={controlSwitch}>
          <Icons.Switch className="w-6 h-5" />
        </div>
      }
      <div className={`${autoStyle} p-1 rounded`} onClick={controlScroll}>
        <Icons.AutoScroll className="w-6 h-5" />
      </div>
      <Dropdown
        mode='DARK'
        options={options}
        className='w-[176px]'
        value={selectedMode}
        onChange={(option) => viewModeStore.changeMode(option.value as keyof Views)}
      />
      <PlusAndMinus
        disabledMinus={viewModeStore.isRangeAtMinimum(currentMode)}
        disabledPlus={viewModeStore.isRangeAtMaximum(currentMode)}
        onClickMinus={() => viewModeStore.decreaseUsers(currentMode)}
        onClickPlus={() => viewModeStore.increaseUsers(currentMode)}
      />
    </div>
  );
});

export default ViewModeDropdown;
