import React from "react";
import { Navigate, useLocation, useOutletContext, useParams } from "react-router-dom";
import { ContextRoom } from "../../../types";
import BackgroundE4Z from "../BackgroundE4Z";
import { ExamsUserRole } from "../../../types/enum";
import { useTranslation } from "react-i18next";

export default function Welcome() {
  const { user } = useOutletContext<ContextRoom>();
  const { id } = useParams();
  const { t } = useTranslation();
  const { search } = useLocation();

  if (user.role === ExamsUserRole.HOST && id) {
    return <Navigate to={`/exams/${id}/requirements${search}`} />;
  }

  return (
    <BackgroundE4Z title={t("welcome")}>
      {id && (
        <a
          className='z-10 w-40 inline-flex items-center justify-center px-3 py-2 border border-transparent text-lg font-bold rounded-lg shadow-sm text-white bg-[#2DABFF] hover:bg-blue-300 focus:outline-none mt-16'
          href={`/exams/${id}/instructions${search}`}
        >
          {t("next")}
        </a>
      )}
    </BackgroundE4Z>
  );
}
