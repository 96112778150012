import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthorizationStore } from "../../../hooks/store";
import BackgroundE4Z from "../BackgroundE4Z";

export default function Passcode() {
  const [passcode, setPasscode] = useState("");
  const { state, search } = useLocation();
  const authorizationStore = useAuthorizationStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const handlePasscode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value);
  };

  const handleSubmit = (event) => {
    if (event.key === "Enter" || event.target.id === "submit") {
      authorizationStore.password = passcode;
      localStorage.setItem("password", passcode);
      navigate(`/exams/${id}/welcome${search}`);
    }
  };

  return (
    <BackgroundE4Z title={t("use_session_passcode")}>
      <div className='w-full text-center space-y-2 mt-5 z-10'>
        {state && state.wrongPasscode && (
          <h4 className='text-red font-bold font-size-18'>{t("wrong_passcode")}</h4>
        )}
        <div className='flex justify-center space-x-4'>
          <input
            type='text'
            value={passcode}
            onChange={handlePasscode}
            onKeyDown={handleSubmit}
            required
            className='h-10 rounded-full w-[35%] border-2 appearance-none text-xs border-gray-300 placeholder-gray-300 font-light focus:outline-none focus:bg-white focus:border-gray-500'
          />
          <button onClick={handleSubmit} id='submit' className='btn btn-primary btn-blue'>
            {t("join")}
          </button>
        </div>
      </div>
    </BackgroundE4Z>
  );
}
