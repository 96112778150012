import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React from "react";

import { useUserStore, useViewModeStore } from "../../../../hooks/store";
import HybridPlayer from "../../../Players/HybridPlayer/HybridPlayer";
import RoundedCard from "../../../RoundedCard/RoundedCard";
import LeftButton from "../Button/LeftButton/LeftButton";
import RightButton from "../Button/RightButton/RightButton";

const HybridParticipantView: React.FunctionComponent = observer(() => {
  const viewModeStore = useViewModeStore();
  const userStore = useUserStore();

  const users = userStore.hybridParticipantModeParticipants;
  const maxUsers = viewModeStore.getNumUsers("HYBRID_PARTICIPANT");
  const enoughUsers = _.size(userStore.participants) > maxUsers;
  const columns = viewModeStore.getUsersColumns("HYBRID_PARTICIPANT", _.size(users));
  const rows = viewModeStore.getUsersRows("HYBRID_PARTICIPANT", _.size(users));
  const positionClass = viewModeStore.hybridRight ? "justify-end" : "justify-start";

  if (_.isEmpty(users)) {
    return null;
  }

  return (
    <div
      className='grid gap-2 h-full w-full relative'
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)` ,
        gridTemplateRows: `repeat(${rows}, 1fr`,
      }}
    >
      {enoughUsers && <LeftButton onClick={() => viewModeStore.prev("HYBRID_PARTICIPANT")} />}
      {users.map((participant) => (
        <div
          onDoubleClick={() => userStore.doubleClickOnParticipant(participant)}
          key={participant.uid}
        >
          <RoundedCard className='h-full'>
            <HybridPlayer focus='PARTICIPANT' className='h-full w-full' user={participant} position={positionClass} />
          </RoundedCard>
        </div>
      ))}
      {enoughUsers && <RightButton onClick={() => viewModeStore.next("HYBRID_PARTICIPANT")} />}
    </div>
  );
});

export default HybridParticipantView;
