export enum ExamsUserRole {
  HOST = "HOST",
  PARTICIPANT = "PARTICIPANT",
}

export enum StateType {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  SCHEDULED = "SCHEDULED",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
}

export enum AccountPlan {
  PAY = "PAY",
  FREEMIUM = "FREEMIUM",
  ZOOM_PRO = "ZOOM_PRO",
}

export enum ExamIncidenceType {
  ABSENT_PARTICIPANT = "ABSENT_PARTICIPANT",
  PARTICIPANT_NOT_ALONE = "PARTICIPANT_NOT_ALONE",
  KO_ID_VALIDATION = "KO_ID_VALIDATION",
  DROP_LVL_ATTENTION = "DROP_LVL_ATTENTION",
  NEW_TAB_WINDOW = "NEW_TAB_WINDOW",
  STOP_SHARING_DESKTOP = "STOP_SHARING_DESKTOP",
  USER_DROPPED = "USER_DROPPED",
  CAMERA_STOPPED = "CAMERA_STOPPED",
  MICROPHONE_STOPPED = "MICROPHONE_STOPPED",
  KO_CHROME_EXTENSION = "KO_CHROME_EXTENSION",
  COPY_EXTERNAL_TEXT = "COPY_EXTERNAL_TEXT",
  TAB_ACTIVATE = "TAB_ACTIVATE",
  PASTE_TEXT = "PASTE_TEXT",
  FILE_DOWNLOADED = "FILE_DOWNLOADED",
  MULTIPLE_MONITORS = "MULTIPLE_MONITORS",
  ID_NAME_MISMATCH = "ID_NAME_MISMATCH",
  OBJECT_DETECTED = "OBJECT_DETECTED", 
}
