import React from "react";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import { ModalShareScreenTutorial } from "../../../types";
import BlueButton from "../../Button/BlueButton/BlueButton";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalShareScreenTutorial;
};

const ShareScreenTutorial: React.FunctionComponent<Props> = ({ data, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Base onClose={onClose} disableCloseButton={data.registrationId}>
      <div className='max-w-[550px]'>
        <p className='font-bold text-xl text-center mb-2'>{t("share_screen_instructions")}</p>
        <p className='mb-4'>{t("share_entire_screen")}</p>
        <img
          className='mb-4 flex justify-center w-full'
          src={data.image}
          width={450}
          alt='share screen tutorial'
        />
        <div className='flex items-center justify-end space-x-2'>
          <BlueButton
            onClick={() => {
              onClose();
              data.onOpen();
            }}
          >
            {t("go_to_screen_sharing_settings")}
          </BlueButton>
        </div>
      </div>
    </Base>
  );
};

export default ShareScreenTutorial;
