import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { useUserStore, useRoomStore, useLoadingStore } from "../../../hooks/store";
import ParticipantChat from "../../Chat/ParticipantChat/ParticipantChat";
import UserPlayer from "../../Players/UserPlayer/UserPlayer";
import CollapsableSideBar from "../CollapsableSideBar/CollapsableSideBar";
import Icons from "../../../images/icons";
import Chatbot from "./Chatbot/Chatbot";

const ParticipantSideBar: React.FunctionComponent = observer(() => {
  const userStore = useUserStore();
  const roomStore = useRoomStore();

  const loadingStore = useLoadingStore();

  useEffect(() => {
    if (roomStore.info?.exam) {
      loadingStore.stopLoading();
    } else {
      loadingStore.startLoading();
    }
  }, [roomStore.info?.exam]);

  if (!roomStore.info?.exam) {
    return null;
  }

  return (
    // BUG: Type '{ children: Element[]; }' has no properties in common with type 'IntrinsicAttributes'.ts
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CollapsableSideBar>
      <div className='w-full h-48 bg-[#000000]'>
        {!_.isNil(userStore.host) && (
          <UserPlayer user={userStore.host} className='w-full h-full' focus='PARTICIPANT' />
        )}
        {!_.isNil(roomStore.info) && !roomStore.info.exam.isUnattended && _.isNil(userStore.host) && (
          <div className='h-full flex items-center justify-center bg-blue-dark'>
            <div className='flex items-center justify-center'>
              <Icons.Logo2 />
            </div>
          </div>
        )}
        {!_.isNil(roomStore.info) && roomStore.info.exam.isUnattended && (
          <div className='h-full flex items-center justify-center bg-blue-dark'>
            <div className='text-white text-sm my-auto mx-4 text-center'>
              <div className='flex items-center justify-center'>
                <Icons.Logo2 />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col flex-1'>
        {!_.isNil(roomStore.info) && roomStore.info.exam.isUnattended ? (
          <div className='h-full flex'>
            <Chatbot />
          </div>
        ) : (
          <ParticipantChat />
        )}
      </div>
    </CollapsableSideBar>
  );
});

export default ParticipantSideBar;
