/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../../services/i18n/config";
import { useDeviceStore, useRoomStore, useUserStore } from "../../hooks/store";
import Dropdown from "../Dropdown/Dropdown";
import RenderPlayer from "../Players/RenderPlayer/RenderPlayer";
import VolumeIndicator from "../VolumeIndicator/VolumeIndicator";
import { ExamsUserRole } from "../../types/enum";
import { useMultipleMonitors } from "../../hooks/useMultipleMonitorsCheck";
import IncidenceService from "../../services/incidence.service";

const CheckAudioAndVideo: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const deviceStore = useDeviceStore();
  const userStore = useUserStore();
  const roomStore = useRoomStore();

  const [volume, setVolume] = React.useState(0);
  const [camera, setCamera] = React.useState<MediaDeviceInfo>();
  const [microphone, setMicrophone] = React.useState<MediaDeviceInfo>();
  const video = userStore.localUser.videoTrack as ICameraVideoTrack;
  const audio = userStore.localUser.audioTrack as IMicrophoneAudioTrack;
  const thereAreMultipleMonitors = useMultipleMonitors();
  const [monitorsChecked, setMonitorsChecked] = useState(false);

  useEffect(() => {
    if (monitorsChecked) {
      return;
    }

    if (thereAreMultipleMonitors && roomStore?.info?.exam && roomStore?.info?.user?.role === ExamsUserRole.PARTICIPANT) {
      
      IncidenceService.create({
        examId: roomStore.info.exam.id,
        sessionId: roomStore.info.exam.sessionId,
        userId: roomStore.info.user.id,
        type: "MULTIPLE_MONITORS",
        guest: roomStore.info.user.guest
      })
     setMonitorsChecked(true);
    }
  }, [roomStore?.info?.exam, roomStore?.info?.user?.role])

  useEffect(() => {
    const loadDevices = async () => {
      await userStore.rtc.createCameraLocalTracks();
      await deviceStore.init();
      const selectedCamera =
        deviceStore.cameraList.find(
          (cam: MediaDeviceInfo) => cam.deviceId === localStorage.cameraId,
        ) || _.first(deviceStore.cameraList);

      const selectedMic =
        deviceStore.microphoneList.find(
          (mic: MediaDeviceInfo) => mic.deviceId === localStorage.microphoneId,
        ) || _.first(deviceStore.microphoneList);

      setCamera(selectedCamera);
      localStorage.cameraId = selectedCamera.deviceId;

      setMicrophone(selectedMic);
      localStorage.microphoneId = selectedMic.deviceId;
    };
    loadDevices();
  }, [deviceStore, userStore]);

  useEffect(() => {
    if (_.isNull(audio)) return;

    const intervalId = setInterval(() => {
      setVolume(audio.getVolumeLevel() * 100);
    }, 100);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [audio]);

  const changeCamera = (id: string): void => {
    const selectedCamera = _.find({ deviceId: id }, deviceStore.cameraList);
    setCamera(selectedCamera);
    deviceStore.changeCamera(selectedCamera.deviceId);
  };

  const changeMicrophone = (id: string): void => {
    const selectedMicrophone = _.find({ deviceId: id }, deviceStore.microphoneList);
    setMicrophone(selectedMicrophone);
    deviceStore.changeMicrophone(selectedMicrophone.deviceId);
  };

  return (
    <>
      <div className='w-full flex bg-white items-center justify-center'>
        <div className='mt-6 mb-3 text-center'>
          <p className='font-bold text-gray-700'>{t("get_you_setup")}</p>
          <p>{t("see_and_hear_you")}</p>
          <p>{t("you_can_choose_your_preferred_settings_in_the_drop_down_menu_below")}</p>
        </div>
      </div>
      <div className='w-full flex-1 flex bg-white items-center justify-center overflow-y-scroll pb-20'>
        <div className='w-[450px] flex flex-col'>
          <div className='flex items-center justify-center h-[325px] overflow-hidden rounded shadow-md bg-black'>
            {_.isNil(video) ? (
              <div className='text-white text-center font-bold text-lg px-12'>
                {t("we_need_authorization_to_access_your_camera_and_audio")}
              </div>
            ) : (
              <RenderPlayer track={video} className='w-full h-full' type="CAM" name={userStore.localUser.username} />
            )}
          </div>
          <div className='mt-2'>
            <VolumeIndicator volume={volume} />
          </div>
          <div className='mt-6 flex flex-row space-x-2'>
            <div className='flex-1'>
              <Dropdown
                mode='LIGHT'
                value={
                  !_.isNil(camera)
                    ? { value: camera?.deviceId, label: `${t("camera")}: ${camera?.label}` }
                    : undefined
                }
                onChange={(cam) => changeCamera(cam.value)}
                options={_.map(
                  (cam) => ({ value: cam.deviceId, label: cam.label }),
                  deviceStore.cameraList,
                )}
              />
            </div>
            <div className='flex-1'>
              <Dropdown
                mode='LIGHT'
                value={
                  !_.isNil(microphone)
                    ? { value: microphone?.deviceId, label: `${t("audio")}: ${microphone?.label}` }
                    : undefined
                }
                onChange={(mic) => changeMicrophone(mic.value)}
                options={_.map(
                  (mic) => ({ value: mic.deviceId, label: mic.label }),
                  deviceStore.microphoneList,
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CheckAudioAndVideo;
