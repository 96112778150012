import LmsService from "../services/lms";
import { ID } from "../types";


type asyncFunc = () => Promise<boolean>;
type response = { "success": boolean, "status": string };

const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
const excellentLearningId = process.env.REACT_APP_EXCELLENT_ID;

const excellentLearningHelper = {
  isExcellentLearning(accountId: ID) {
    return accountId == excellentLearningId;
  },
  async handleExamClosed(accountId: ID, finishExamFn: asyncFunc) {
    if (accountId !== excellentLearningId) {
      return;
    }
  
    const handleMessage = async (event) => {
      if (!this.isMessageFromExtension(event)) {
        return;
      }
  
      const messageFromExtension = event.data.message;
      if (messageFromExtension === "CLOSE") {
        await this.handleExamClosure(finishExamFn);
      }
    };
  
    window.addEventListener("message", handleMessage);
  },
  isMessageFromExtension(event) {
    return event.source === window && event.data.type === "FROM_EXTENSION";
  },
  async  handleExamClosure(finishExamFn) {
    try {
      const result = await finishExamFn();
      if (result) {
        chrome.runtime.sendMessage(extensionId, "closetab");
      }
    } catch (error) {
      console.error("Error closing exam tab or sending message:", error);
    }
  },
  handleExamOpened(accountId: ID) {
    if(accountId == excellentLearningId) {
      try {
        chrome.runtime.sendMessage(extensionId, "windowopened|" + accountId, (response: string) => {
          if (response == "opened") {
            console.log("Exam window opened");
          }
        });
      } catch (e) {
        console.log("Error opening exam window");
      }
    }
  },
async enrollLmsExam(accountId: ID, userId: ID, examId: ID): Promise<response> {
  if(accountId == excellentLearningId) {
    try {
      const res = await LmsService.enrollParticipant(userId, examId);
      return res;
    } catch (e) {
      return { success: false, status: e };
    }
  } else {
    return { success: false, status: "" };
  }
}
};

export default excellentLearningHelper;
