import _ from "lodash/fp";
import React from "react";

import { ExamsUser } from "../../../types";
import UserPlayer from "../UserPlayer/UserPlayer";

type Props = {
  focus?: "EXAM" | "PARTICIPANT";
  user: ExamsUser;
  className?: string;
  position?: string;
};

const HybridPlayer: React.FunctionComponent<Props> = ({
  focus = "PARTICIPANT",
  user,
  className = "",
  position = "justify-end",
}: Props) => {
  const smallFocus = _.isEqual(focus, "EXAM") ? "PARTICIPANT" : "EXAM";

  return (
    <div className={`relative bg-[#000000] ${className}`}>
      <UserPlayer user={user} focus={focus} object_fit_contain className='h-full w-full' />
      <div className={`absolute inset-0 h-full w-full flex ${position}`}>
        <div
          className='overflow-hidden rounded-bl h-[31.5%] shadow-md'
          style={{ aspectRatio: "4/3" }}
        >
          <UserPlayer
            user={user}
            focus={smallFocus}
            object_fit_contain
            hideName
            className='h-full w-full'
          />
        </div>
      </div>
    </div>
  );
};

export default HybridPlayer;
