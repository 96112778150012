import { action, computed, makeObservable, observable } from "mobx";
import _ from "lodash/fp";

import i18n from "../../../services/i18n/config";
import AppStore from "..";
import { Resetable } from "../../interfaces/resetable";
import type { ChannelUser, ExamsUser, RoomExam, RoomUser } from "../../../types";
import RTCStore from "../rtc";
import RTMStore, { createActionMessage } from "../rtm";
import type { TabsName } from "../../../components/SideBar/Tabs/Tabs";
import NotifyStore from "../ui/notify";

import ChatStore from "./chat";
import UserStore from "./user";
import RecordStore from "./record";
import ActionStore from "./action";
import HandStore from "./hand";
import SentimentStore from "./sentiment";
import IncidenceStore from "./incidence";
import DetectionStore from "./detection";
import moment from "moment";
import SessionService, { SessionResponse } from "../../../services/session/sessions.service";
import SessionGuestService from "../../../services/session/sessions.guest.service";
import { ExamsUserRole } from "../../../types/enum";
import { eventEmitter } from "../../../services/util/eventEmitter";

export type RoomInfo = {
  user: RoomUser;
  exam: RoomExam;
};

class RoomStore implements Resetable {
  appStore!: AppStore;

  @observable
  info: RoomInfo = null;

  @observable
  activeTab: TabsName = "participants";

  chatStore: ChatStore;

  userStore: UserStore;

  recordStore: RecordStore;

  handStore: HandStore;

  sentimentStore: SentimentStore;

  incidenceStore: IncidenceStore;

  detectionStore: DetectionStore;

  actionStore: ActionStore;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.chatStore = new ChatStore(this);
    this.userStore = new UserStore(this);
    this.recordStore = new RecordStore(this);
    this.actionStore = new ActionStore(this);
    this.handStore = new HandStore(this);
    this.sentimentStore = new SentimentStore(this);
    this.detectionStore = new DetectionStore(this);
    this.incidenceStore = new IncidenceStore(this);
  }

  @action
  reset(): void {
    this.info = null;
    this.activeTab = "participants";

    this.userStore.reset();
    this.chatStore.reset();
    this.recordStore.reset();
    this.handStore.reset();
    this.sentimentStore.reset();
    this.detectionStore.reset();
    this.incidenceStore.reset();
    this.activeTab = "participants";
  }

  @action
  setHasStarted(hasStarted: boolean): void {
    this.info.exam.hasStarted = hasStarted;
  }

  @action
  setLmsUrl(lmsUrl: string): void {
    this.info.exam.lmsURL = lmsUrl;
  }

  @action
  setActiveTab(tab: TabsName): void {
    this.activeTab = tab;
  }

  @action
  setManualStartDate(manualStartDate: string): void {
    this.info.exam.manualStartDate = manualStartDate;
  }

  get notify(): NotifyStore {
    return this.appStore.uiStore.notify;
  }

  get rtc(): RTCStore {
    return this.appStore.rtcStore;
  }

  get rtm(): RTMStore {
    return this.appStore.rtmStore;
  }

  @computed
  get examName(): string {
    return _.get("info.exam.name", this);
  }

  @computed
  get hasStarted(): boolean {
    return _.get("info.exam.hasStarted", this);
  }

  @computed
  get isRecordingEnabled(): boolean {
    return _.get("info.exam.recordEnabled", this);
  }

  @computed
  get isSnapshotEnabled(): boolean {
    return _.get("info.exam.takeScreenshot", this);
  }

  @computed
  get isDetectionEnabled(): boolean {
    return _.get("info.exam.objectDetection", this);
  }

  @computed
  get isDemo(): boolean {
    return _.get("info.exam.isDemo", this);
  }

  @computed
  get isNotifyParticipants(): boolean {
    return _.get("info.exam.notifyParticipants", this);
  }

  @computed
  get endTime(): Date | null {
    if (_.isEmpty(this.info?.exam?.endTime)) return null;

    return new Date(this.info.exam.endTime);
  }

  @computed
  get manualStartDate(): Date | null {
    if (_.isEmpty(this.info?.exam?.manualStartDate)) return null;

    return new Date(this.info.exam.manualStartDate);
  }

  @computed
  get lmsLink(): string {
    return _.get("exam.lmsURL", this.info);
  }

  @computed
  get localUser(): ExamsUser {
    return this.userStore.localUser;
  }

  @computed
  get otherUsers(): ExamsUser[] {
    return this.userStore.otherUsers;
  }

  @computed
  get participants(): ExamsUser[] {
    return this.userStore.participants;
  }

  @computed
  get host(): ExamsUser {
    return this.userStore.host;
  }

  @action
  async join(roomInfo: RoomInfo, route: string): Promise<void> {
    try {
      this.appStore.uiStore.loading.startLoading();
      this.info = roomInfo;

      const isExam = route === "Exam";
      const hostId = parseInt(_.toString(this.info.exam.id).replace(/\D/g, "").substring(0,4) + this.info.user.agoraUserId, 10);

      const uid = this.info.user.role === (ExamsUserRole.HOST) ? hostId : this.info.user.agoraUserId;

      let role: ExamsUserRole;
      if (this.info.exam.isDemo) {
        role = this.info.user.demoRole;
      } else {
        role = this.info.user.role;
      }

      const options = {
        uid,
        token: null,
        channel: _.toString(this.info.exam.agoraId),
        user: {
          uid,
          role,
          username: this.info.user.name || this.info.exam.fullname,
          route,
          fullname: this.info.exam.fullname ?? "",
        },
      };

      await this.rtm.startMessaging(options);

      await this.rtc.startCall(options);

      this.incidenceStore.init();

      await this.sendLocation(route);

      if (!this.userStore.isLocalHost()) {
        this.sentimentStore.startAnalysis();
        if(this.hasStarted) {
          eventEmitter.emit("startExam");
        }
      }

      if(isExam && this.info.exam.recordEnabled && this.info.exam.isRecording) {
        if(this.info.exam.isUnattended) {
          this.recordStore.queryRecording();
        } else {
          const message = createActionMessage({
            type: "QUERY_RECORDING",
            params: { uid: this.localUser.uid },
          });
          await this.rtm.sendActionMessageToPeer(message, _.toString(this.host.uid));
        }
      }

      await this.autoStart();

      this.appStore.uiStore.loading.stopLoading();
    } catch (err) {
      this.appStore.uiStore.loading.stopLoading();
      throw err;
    }
  }

  @action
  async joinPre(roomInfo: RoomInfo, route: string): Promise<void> {
    if(route == "Feedback") {
      return;
    }

    this.info = roomInfo;

    const hostId = parseInt(_.toString(this.info.exam.id).replace(/\D/g, "").substring(0,4) + this.info.user.agoraUserId, 10);
    const uid = this.info.user.role === (ExamsUserRole.HOST) ? hostId : this.info.user.agoraUserId;

    let role;
    if (this.info.exam.isDemo) {
      role = this.info.user.demoRole;
    } else {
      role = this.info.user.role;
    }

    const options = {
      uid,
      token: null,
      channel: _.toString(this.info.exam.agoraId),
      user: {
        uid,
        role,
        username: this.info.user.name || this.info.exam.fullname,
        route,
        fullname: this.info.exam.fullname ?? "",
      },
    };

    await this.rtm.startMessaging(options);

    await this.rtc.cameraStore.startCall(options);

    await this.sendLocation(route);
  }

  @action
  async joinWaiting(roomInfo: RoomInfo, route: string): Promise<void> {
    if(this.rtm.inProgress) {
      this.rtm.inProgress = false;
      return;
    }

    this.info = roomInfo;
    const hostId = parseInt(_.toString(this.info.exam.id).replace(/\D/g, "").substring(0,4) + this.info.user.agoraUserId, 10);
    const uid = this.info.user.role === (ExamsUserRole.HOST) ? hostId : this.info.user.agoraUserId;

    let role;
    if (this.info.exam.isDemo) {
      role = this.info.user.demoRole;
    } else {
      role = this.info.user.role;
    }

    const options = {
      uid,
      token: null,
      channel: _.toString(this.info.exam.agoraId),
      user: {
        uid,
        role,
        username: this.info.user.name || this.info.exam.fullname,
        route,
        fullname: this.info.exam.fullname ?? "",
      },
    };
    await this.rtm.startMessaging(options);

    await this.sendLocation(route);
  }

  async sendLocation(route: string): Promise<void> {
    if (this.userStore.isLocalHost()) return;

    if (_.isEmpty(this.host)) return;

    const message = createActionMessage({
      type: "CURRENT_ROUTE",
      params: { uid: this.localUser.uid, route },
    });
    await this.rtm.sendActionMessageToPeer(message, _.toString(this.host.uid));
  }

  async autoStart(): Promise<void> {
    if (!this.info.exam.isUnattended) return;

    eventEmitter.emit("startExam");
    await this.autoStartExam();
    await this.recordStore.autoStartRecording();
  }

  @action
  async autoStartExam(): Promise<void> {
    if (!this.info.exam.isUnattended) return;

    if (!_.isEmpty(this.info.exam.endTime)) return;

    await this.startExam();
  }

  @action
  setEndTime(val: string): void {
    this.info.exam.endTime = val;
  }

  @action
  async controlAnalysis(evt: string): Promise<void> {
    await this.sentimentStore.pausePlayAnalysis(evt);
  }

  @action
  async leave(): Promise<void> {
    await this.rtc.leaveCall();
    this.rtm.leaveMessaging();
    await this.sentimentStore.stopAnalysis();
  }

  @action
  async startExam(): Promise<void> {
    if (this.info.exam.hasStarted) {
      return;
    }
    await SessionService.manualStart(this.info.exam.id, this.info.exam.sessionId);
    const message = createActionMessage({ type: "START_EXAM" });
    await this.rtm.channel?.sendMessage(message);

    await this.notifyStartExam();
  }


  @action
  async finishWaiting(): Promise<void> {
    await SessionService.finishWaitingRoom(this.info.exam.id, this.info.exam.sessionId);
    const message = createActionMessage({ type: "FINISH_WAITING" });
    await this.rtm.channel?.sendMessage(message);
  }

  @action
  async banParticipant(participant: ChannelUser): Promise<void> {
    const message = createActionMessage({ type: "BAN_PARTICIPANT" });
    this.rtm.removeChannelUser(_.toString(participant.uid));
    await this.rtm.sendActionMessageToPeer(message, _.toString(participant.uid));
  }

  @action
  async callActionFinishExam(): Promise<boolean> {
    try {
      const result: SessionResponse = this.info.user.guest
        ? await SessionGuestService.finishExam(
            this.info.exam.id,
            this.incidenceStore.exam.sessionId,
            this.info.user.id,
          )
        : await SessionService.finishExam(this.info.exam.id, this.incidenceStore.exam.sessionId);
  
      return result.state === "FINISHED";
    } catch (error) {
      console.error("Error finishing exam:", error);
      throw error;
    }
  }

  @action
  async endExam(): Promise<void> {
    if (!this.info.exam.isUnattended) {
      await this.callActionFinishExam();
    }
    const message = createActionMessage({ type: "END_EXAM" });
    await this.rtm.channel?.sendMessage(message);
  }

  @action
  async notifyStartExam(): Promise<void> {
    if (this.info.exam.isUnattended) {
      this.setEndTime(this.info.exam.endTime);
    } else {
      const endTime = moment(this.info.exam.startDate);
      this.setEndTime(endTime.add(50, "minutes").format("YYYY-MM-DDTHH:mm:ss"));
    }
    // REFACTOR: There is multiple if's with this condition
    if (this.info.user.guest) {
      const result = await SessionGuestService.getRoom(
        this.info.exam.id,
        this.info.exam.sessionId,
        this.info.user.id,
      );
      this.setLmsUrl(result.lmsURL);
      this.setManualStartDate(result.manualStartDate);
    } else {
      const result = await SessionService.getRoom(this.info.exam.id, this.info.exam.sessionId);
      this.setLmsUrl(result.lmsURL);
      this.setManualStartDate(result.manualStartDate);
    }
    this.setHasStarted(true);
    const message = this.info.exam.recordEnabled
      ? i18n.t("exam_has_started_recording")
      : i18n.t("exam_has_started");
    this.notify.showExamNotification(message);
  }

  @action
  notifyEndExam(): void {
    this.notify.showExamNotification(i18n.t("exam_ended"));
  }

  @action
  notifyFinishWaiting(): void {
    this.notify.showExamNotification(i18n.t("waiting_room_ended"));
  }

  @action
  async fiveMinutesLeft(): Promise<void> {
    const message = createActionMessage({ type: "FIVE_MINUTES_LEFT" });
    await this.rtm.channel?.sendMessage(message);

    this.notify.showExamNotification(i18n.t("five_minutes_left"));
  }
}

export default RoomStore;
