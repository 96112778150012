import { useEffect, useRef } from "react";

export default function useInterval(callback, delay) {
    // store a mutable reference to the callback
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const savedCallback = useRef(() => {}); // pass a dummy function as initial value
  
    // update the reference on every render
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    // set up an interval and clear it on cleanup
    useEffect(() => {
      // skip if delay is null or undefined
      if (delay == null) return;
  
      // define a tick function that runs the current callback
      const tick = () => {
        savedCallback.current();
      };
  
      // set up an interval with the given delay
      const id = setInterval(tick, delay);
  
      // return a cleanup function to clear the interval
      return () => clearInterval(id);
    }, [delay]); // only re-run if delay changes
  }