import type { UID } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import { action, computed, makeObservable, observable } from "mobx";
import ExamsLogger from "../../../logger";
import { Resetable } from "../../interfaces/resetable";
import { ExamIncidence, ExamIncidenceType } from "../../../channels/exam_incidences_channel";
import type { ID, RoomExam } from "../../../types";
import NotifyStore from "../ui/notify";

import UserStore from "./user";

import RoomStore from ".";
import connectChannel from "../../../channels/incidences_channel";
import { Client } from "@stomp/stompjs";
import { sec } from "../../../services/util/security";
import dateHelper from "../../../lib/date";
import incidenceService, { CreateParams } from "../../../services/incidence.service";

export type Incidence = {
  id: ID;
  uid: UID;
  type: ExamIncidenceType;
  creationDate: string;
  read: boolean;
  message?: string;
};

export type ParticipantsPerIncidence = {
  [key: string]: Array<number>;
};

const logPrefix = "[Incidence Store]";

class IncidenceStore implements Resetable {
  roomStore!: RoomStore;

  @observable
  incidences: Incidence[] = [];

  @observable
  selected: UID | null = null;

  stomp: Client = null;

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get userStore(): UserStore {
    return this.roomStore.userStore;
  }

  @computed
  get sortedIncidences(): Incidence[] {
    return _.sortBy("timestamp", this.incidences);
  }

  @computed
  get uniqueIncidencesTriggered(): Incidence[] {
    // eslint-disable-next-line arrow-body-style
    return this.incidences.filter((i1, index) => {
      return this.incidences.findIndex((i2) => i1.type === i2.type) === index;
    });
  }

  @computed
  get participantsPerIncidence(): ParticipantsPerIncidence {
    const counts = {};
    this.incidences.forEach((incidence) => {
      if (counts[incidence.type] === undefined) {
        counts[incidence.type] = [];
      }
      if (!counts[incidence.type].includes(incidence.uid)) {
        counts[incidence.type].push(incidence.uid);
      }
    });
    return counts;
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @computed
  get totalIncidences(): number {
    return this.incidences.length;
  }

  shouldNotifyCurrentParticipant(incidence: ExamIncidence): boolean {
    return (
      NotifyStore.shouldNotifyParticipant(incidence) &&
      !this.roomStore.userStore.isLocalUser(incidence.userId)
    );
  }

  createIncidence(incidence: CreateParams) {
    if (this.userStore.isLocalHost()) return;
    try {
      incidenceService.create(incidence);
      if (
        this.exam?.notifyParticipants
      ) {
        const incident = {
          examId: incidence.examId,
          userId: incidence.userId,
          incidenceType: incidence.type,
        } as ExamIncidence;
        if (NotifyStore.shouldNotifyParticipant(incident)) {
          this.roomStore.notify.showParticipantNotification(incident);
        }
      }
    } catch (e) {
      ExamsLogger.error(logPrefix, "Incidence creation error: ", e);
    }
  }

  isIncidentTriggered(uid: UID): string {
    let color = "";
    this.incidences.forEach((incidence) => {
      if (incidence.uid === uid && incidence.read === false) {
        color = incidence.type === "DROP_LVL_ATTENTION" ? "bg-[#F2A20C]" : "bg-[#FE5900]";
      }
    });
    return color;
  }

  userIncidences(uid: UID): Incidence[] {
    const userIncidences: Incidence[] = [];
    _.forEach((incidence) => {
      if (incidence.uid === uid) {
        userIncidences.push(incidence);
      }
    }, this.incidences);
    return _.sortBy("timestamp", userIncidences);
  }

  userDropped(uid: UID): boolean {
    let dropped = false;

    this.incidences.forEach((incidence) => {
      if (incidence.uid === uid) {
        const { participants } = this.userStore;
        const userInChannel = _.find((user) => user.uid === uid && user.screenTrack, participants);
        dropped = incidence.type === "USER_DROPPED" && !userInChannel;
      }
    });
    return dropped;
  }

  getToken = async (): Promise<string> => {
    return await sec.getAccessTokenSilently()();
  };

  @action
  setSelected(uid: UID | null): void {
    this.selected = uid;
  }

  @action
  markRead(): void {
    const incidenceRead: Incidence[] = [];
    _.forEach((incidence) => {
      const incident = { ...incidence };
      incident.read = true;
      incidenceRead.push(incident);
    }, this.incidences);
    this.setIncidences(incidenceRead);
  }

  @action
  init(): void {
    if (!this.userStore.isLocalHost()) return;
    const onConnect = () => {
      this.stomp.subscribe("/communication/user", (message: { body: string }) => {
        const data = JSON.parse(message.body);

        if (!this.userStore.isLocalHost()) return;

        const newIncidences: Incidence[] = [
          {
            id: data.object.id,
            uid: data.object.agoraUserId,
            type: data.object.incidenceType,
            message: data.object.message,
            creationDate: dateHelper.utcToUserTimezone(data.object.creationDate),
            read: false,
          },
        ];
        this.setIncidences(_.concat(this.incidences, newIncidences));
      });
    };
    this.getToken().then((token) => {
      this.stomp = connectChannel(onConnect, token);
    });
  }

  @action
  setIncidences(incidences: Incidence[]): void {
    this.incidences = incidences;
  }

  @action
  reset(): void {
    this.incidences = [];
    this.stomp = null;
    this.selected = null;
  }
}

export default IncidenceStore;
