import React from "react";

import BottomBar from "../BottomBar/BottomBar";
import SideBar from "../SideBar/SideBar";
import VideoCall from "../VideoCall/VideoCall";
import TopBar from "../TopBar/TopBar";

const Exam: React.FunctionComponent = () => (
  <div className='w-full h-full flex flex-col bg-black max-h-screen overflow-hidden'>
    <TopBar />
    <div className='flex flex-row flex-1 max-h-[calc(100vh-6.5rem)]'>
      <SideBar />
      <VideoCall />
    </div>
    <BottomBar />
  </div>
);

export default Exam;
