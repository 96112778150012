import { useContext } from "react";

import { AppContext } from "../../context";
import ExamsLogger from "../../logger";
import AppStore from "../../stores/app";
import AuthorizationStore from "../../stores/app/authorization";
import DeviceStore from "../../stores/app/device";
import RoomStore from "../../stores/app/room";
import ActionStore from "../../stores/app/room/action";
import ChatStore from "../../stores/app/room/chat";
import HandStore from "../../stores/app/room/hand";
import IncidenceStore from "../../stores/app/room/incidence";
import RecordStore from "../../stores/app/room/record";
import UserStore from "../../stores/app/room/user";
import ViewModeStore from "../../stores/app/room/user/viewMode";
import RTCStore from "../../stores/app/rtc";
import CameraStore from "../../stores/app/rtc/camera";
import ScreenStore from "../../stores/app/rtc/screen";
import RTMStore from "../../stores/app/rtm";
import UIStore from "../../stores/app/ui";
import CollapsedStore from "../../stores/app/ui/collapsed";
import LoadingStore from "../../stores/app/ui/loading";
import ModalStore from "../../stores/app/ui/modal";
import NotifyStore from "../../stores/app/ui/notify";
import ToastStore from "../../stores/app/ui/toast";

export const useAppStore = (): AppStore => {
  const context = useContext(AppContext);
  if (context === null) {
    ExamsLogger.error("Use the hook inside AppProvider");
  }
  return context;
};

export const useUIStore = (): UIStore => {
  const store = useAppStore();
  return store.uiStore;
};

export const useToastStore = (): ToastStore => {
  const store = useUIStore();
  return store.toast;
};

export const useLoadingStore = (): LoadingStore => {
  const store = useUIStore();
  return store.loading;
};

export const useModalStore = (): ModalStore => {
  const store = useUIStore();
  return store.modal;
};

export const useNotifyStore = (): NotifyStore => {
  const store = useUIStore();
  return store.notify;
};

export const useCollapsedStore = (): CollapsedStore => {
  const store = useUIStore();
  return store.collapsed;
};

export const useRoomStore = (): RoomStore => {
  const store = useAppStore();
  return store.roomStore;
};

export const useHandStore = (): HandStore => {
  const store = useRoomStore();
  return store.handStore;
};

export const useUserStore = (): UserStore => {
  const store = useRoomStore();
  return store.userStore;
};

export const useActionStore = (): ActionStore => {
  const store = useRoomStore();
  return store.actionStore;
};

export const useRecordStore = (): RecordStore => {
  const store = useRoomStore();
  return store.recordStore;
};

export const useChatStore = (): ChatStore => {
  const store = useRoomStore();
  return store.chatStore;
};

export const useIncidenceStore = (): IncidenceStore => {
  const store = useRoomStore();
  return store.incidenceStore;
};

export const useViewModeStore = (): ViewModeStore => {
  const store = useUserStore();
  return store.viewModeStore;
};

export const useRTMStore = (): RTMStore => {
  const store = useAppStore();
  return store.rtmStore;
};

export const useRTCStore = (): RTCStore => {
  const store = useAppStore();
  return store.rtcStore;
};

export const useCameraStore = (): CameraStore => {
  const store = useRTCStore();
  return store.cameraStore;
};

export const useScreenStore = (): ScreenStore => {
  const store = useRTCStore();
  return store.screenStore;
};

export const useDeviceStore = (): DeviceStore => {
  const store = useAppStore();
  return store.deviceStore;
};

export const useAuthorizationStore = (): AuthorizationStore => {
  const store = useAppStore();
  return store.authorizationStore;
};
