import { AxiosResponse } from "axios";
import { ID } from "../types";

import client from "./client";

type Feedback = {
  question: string;
  star: number;
};

type CreateParams = {
  questions: Feedback[];
  examId: ID;
  sessionId: ID;
  userId: ID;
};

export const create = async (data: CreateParams, guest?: boolean): Promise<AxiosResponse> => {
  const configs = {
    params: {
      examId: data.examId,
    },
  };
  const postData = {
    question: data.questions,
  };

  const url = `/exam-service/v1/sessions${guest ? "/guests" : ""}/${
    data.sessionId
  }/feedback?userId=${data.userId}`;

  const response = await client.post(url, postData, configs);
  return response;
};

const FeedbackService = {
  create,
};

export default FeedbackService;
