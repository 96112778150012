import React from "react";

import Logo from "../Logo/Logo";
import ExamName from "../ExamName/ExamName";
import ViewModeDropdown from "../ViewModeDropdown/ViewModeDropdown";
import SpeakingTo from "../SpeakingTo/SpeakingTo";
import HostToolTip from "../../Tooltip/HostToolTip/HostToolTip";
import Tooltip from "../../Tooltip/Tooltip";
import Icons from "../../../images/icons";
import { useTranslation } from "react-i18next";
import styles from "../../Tooltip/Tooltip.module.scss";
import ConnectionPlayer from "../../Players/ConnectionPlayer/ConnectionPlayer";
import _ from "lodash";

type Props = {
  examName: string;
  hideSpeakingToAndDropdown?: boolean;
  numberOfParticipants?: number;
  idValidation?: boolean;
  uploadFiles?: boolean;
  groupSession?: boolean;
  connectionState?: string;
};

const HostTopBar: React.FunctionComponent<Props> = ({
  examName,
  hideSpeakingToAndDropdown = false,
  numberOfParticipants = 0,
  idValidation = false,
  uploadFiles = false,
  groupSession = false,
  connectionState = "",
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='h-10 w-full flex items-center'>
      <Logo />
      <div className='px-2 flex flex-grow justify-between'>
        <div className='px-2 flex'>
          <ExamName name={examName} />
          {!_.isEmpty(connectionState) && <ConnectionPlayer connectionState={connectionState} />}
        </div>
        {!hideSpeakingToAndDropdown && <SpeakingTo />}
        <div className='flex px-2'>
          {!hideSpeakingToAndDropdown && (
            <div className='flex justify-end mr-3'>
              <ViewModeDropdown />
            </div>
          )}
          <Tooltip
          place="left"
          tooltipId="host-group-sessions-tooltip"
          content={
            <div>
              {t(`${groupSession ? "remember_users_interaction" : "remember_non_users_interaction"}`)}
            </div>
          }
          offset={"{ 'bottom': 80 }"}
          className={styles.hostTooltip}
          >
            <div className="mr-2">
              { groupSession && (<Icons.UserSpeakOrange />)}
              { !groupSession && (<Icons.UserNonContact />)}
            </div>
          </Tooltip>
          <HostToolTip
            examName={examName}
            numberOfParticipants={numberOfParticipants}
            idValidation={idValidation}
            uploadFiles={uploadFiles}
          />
        </div>
      </div>
    </div>
  );
}

export default HostTopBar;
