import classNames from "classnames";
import React from "react";

type Props = {
  text: string;
  odd: boolean;
  isActive: boolean;
  isCompleted: boolean;
};

const Item: React.FunctionComponent<Props> = ({ odd, text, isActive, isCompleted }: Props) => {
  const classWrapper = classNames("flex flex-row items-center space-x-4 h-16 px-3", {
    "bg-gray-100": odd,
    "bg-white": !odd,
  });

  const classIndicator = classNames("rounded h-6 w-2", {
    "bg-gray-300": !isCompleted && !isActive,
    "bg-green": isCompleted,
    "bg-blue": isActive,
  });

  const classText = classNames("text-sm truncate", {
    "text-black font-medium": !isCompleted && !isActive,
    "text-gray-400 font-normal": isCompleted,
    "text-blue font-bold": isActive,
  });

  return (
    <div className={classWrapper}>
      <div className={classIndicator} />
      <div className='flex flex-col flex-1 overflow-hidden '>
        <span className={classText}>{text}</span>
      </div>
    </div>
  );
};

export default Item;
