import _ from "lodash/fp";
import React from "react";

import { ExamsUser } from "../../../types";
import RenderPlayer from "../RenderPlayer/RenderPlayer";
import UserNamePlayer from "../UserNamePlayer/UserNamePlayer";

type Props = {
  focus?: "EXAM" | "PARTICIPANT";
  user: ExamsUser;
  className?: string;
  hideName?: boolean;
  object_fit_contain?: boolean;
};

const UserPlayer: React.FunctionComponent<Props> = ({
  focus = "PARTICIPANT",
  user,
  className = "",
  hideName = false,
  object_fit_contain = false,
}: Props) => {
  return (
    <div className={`relative bg-[#000000] ${className}`}>
      {_.isEqual(focus, "EXAM") && (
        <RenderPlayer
          track={user.screenTrack}
          className={`h-full w-full ${object_fit_contain && "object_fit_contain_video"}`}
          type="SCREEN"
          name={user.username}
        />
      )}
      {_.isEqual(focus, "PARTICIPANT") && (
        <RenderPlayer
          track={user.videoTrack}
          className={`h-full w-full ${object_fit_contain && "object_fit_contain_video"}`}
          type="CAM"
          name={user.username}
        />
      )}
      {!hideName && <UserNamePlayer name={user.username} />}
    </div>
  );
}

export default UserPlayer;
