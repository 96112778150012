import React from "react";
import { observer } from "mobx-react-lite";

import { useIncidenceStore, useUserStore } from "../../hooks/store";

import IncidenceFilter from "./IncidenceFilter/IncidenceFilter";
import Incidence from "./Incidence/Incidence";
import { UID } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";

const Incidences: React.FunctionComponent = observer(() => {
  const incidenceStore = useIncidenceStore();
  const userStore = useUserStore();

  const selectedUID = incidenceStore.selected;

  const selectUser = (uid: UID | null) => {
    incidenceStore.setSelected(uid);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='px-4 bg-white'>
        <IncidenceFilter
          selected={incidenceStore.selected}
          onSelect={selectUser}
          users={userStore.participants}
        />
      </div>
      <div className='flex-1 mt-2'>
        <div className='bg-gray-100 flex flex-col h-full w-full'>
          <div className='relative flex-1 h-full'>
            <div className='absolute inset-0 overflow-x-hidden overflow-y-auto p-3 space-y-2 flex flex-col'>
              {_.isNil(selectedUID)
                ? incidenceStore.sortedIncidences.map((incidence) => (
                    <Incidence key={incidence.id} incidence={incidence} />
                  ))
                : incidenceStore
                    .userIncidences(selectedUID)
                    .map((incidence) => <Incidence key={incidence.id} incidence={incidence} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Incidences;
