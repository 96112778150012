import axios, { AxiosResponse, Method } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import type { UID } from "agora-rtc-sdk-ng";
import _, { startCase } from "lodash/fp";

import i18n from "../../../services/i18n/config";
import { Resetable } from "../../interfaces/resetable";
import ExamsLogger from "../../../logger";
import type { AWSCredentials } from "..";
import RTMStore, { createActionMessage } from "../rtm";
import NotifyStore from "../ui/notify";
import type { RoomExam } from "../../../types";

import RoomStore from ".";
import SessionService from "../../../services/session/sessions.service";
import SessionGuestService from "../../../services/session/sessions.guest.service";

const logPrefix = "[Record Store]";

export type AcquireParams = {
  channel: string;
  agoraRecordingType: "CAMERA" | "SCREEN";
};

export type StartParams = {
  channel: string;
  resourceId: string;
  token?: string;
  aws?: AWSCredentials;
  agoraRecordingType: "CAMERA" | "SCREEN";
};

export type StopParams = {
  sid: string;
  resourceId: string;
  channel: string;
  agoraRecordingType: "CAMERA" | "SCREEN";
};

export type QueryParams = {
  sid: string;
  resourceId: string;
  channel: string;
  agoraRecordingType: "CAMERA" | "SCREEN";
};

export type QueryResponse = {
  cname: string,
  uid: string,
  resourceId: string,
  serverResponse: {
    status: number,
    sliceStartTime: number
  }
}

export enum CloudServiceStatus {
  NOT_STARTED = 0,
  INITIALIZATION_COMPLETE = 1,
  STARTING = 2,
  PARTIALLY_READY = 3,
  READY = 4,
  IN_PROGRESS = 5,
  STOP_REQUESTED = 6,
  STOPPED = 7,
  EXITED = 8,
  EXITED_ABNORMALLY = 20,
}

// https://docs.agora.io/en/cloud-recording/restfulapi/#/Cloud%20Recording/acquire
const NON_HOST_UID = "4294967295"; // Max unsigned integer

// https://docs.agora.io/en/cloud-recording/restfulapi/

class RecordStore implements Resetable {
  roomStore!: RoomStore;

  private static baseURL = "https://api.agora.io/v1/apps";

  @observable
  recording = false;

  @observable
  sidCamera: string = null;

  @observable
  sidScreen: string = null;

  @observable
  resourceIdCamera: string = null;

  @observable
  resourceIdScreen: string = null;

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
  }

  get rtm(): RTMStore {
    return this.roomStore.rtm;
  }

  get notify(): NotifyStore {
    return this.roomStore.appStore.uiStore.notify;
  }

  @computed
  get appId(): string {
    return this.roomStore.appStore.appId;
  }

  @computed
  get awsCredentials(): AWSCredentials {
    return this.roomStore.appStore.awsCredentials;
  }

  @computed
  get authorization(): string {
    return `Basic ${this.roomStore.appStore.restfullAPIToken}`;
  }

  @computed
  get recordingUrl(): string {
    return `${RecordStore.baseURL}/${this.appId}/cloud_recording`;
  }

  @computed
  get isRecording(): boolean {
    return this.recording;
  }

  @computed
  get channelCamera(): string {
    return this.roomStore.rtc.cameraStore.client.channelName;
  }

  @computed
  get channelScreen(): string {
    return this.roomStore.rtc.screenStore.client.channelName;
  }

  @computed
  get token(): string {
    return this.roomStore.rtc.callOptions.token;
  }

  // If we use an UID already in the channel, this UID wont be recorded
  @computed
  get uid(): UID {
    return _.getOr(NON_HOST_UID, "host.uid", this.roomStore.userStore);
  }

  @computed
  get exam(): RoomExam {
    return this.roomStore.info.exam;
  }

  @action
  reset(): void {
    this.sidCamera = null;
    this.sidScreen = null;
    this.resourceIdCamera = null;
    this.resourceIdScreen = null;
    this.recording = false;
  }

  @action
  setSids(cam: string, screen: string): void {
    this.setSidCamera(cam);
    this.setSidScreen(screen);
  }

  @action
  setSidCamera(val: string): void {
    this.sidCamera = val;
  }

  @action
  setSidScreen(val: string): void {
    this.sidScreen = val;
  }

  @action
  setResourceIds(cam: string, screen?: string): void {
    this.setResourceIdCamera(cam);
    if (screen) {
      this.setResourceIdScreen(screen);
    }
  }

  @action
  setResourceIdCamera(val: string): void {
    this.resourceIdCamera = val;
  }

  @action
  setResourceIdScreen(val: string): void {
    this.resourceIdScreen = val;
  }

  @action
  setRecording(val: boolean): void {
    this.recording = val;
  }

  @action
  notifyStartRecording(): void {
    this.notify.showRecordNotification(i18n.t("recording_started"));
    this.setRecording(true);
  }

  @action
  notifyStopRecording(): void {
    this.notify.showRecordNotification(i18n.t("recording_stopped"));
    this.setRecording(false);
  }

  @action
  async autoStartRecording(): Promise<void> {
    if (!this.exam.isUnattended || !this.exam.recordEnabled) return;

    if (this.exam.userRecording) return;

    await this.startRecording();
  }

  @action
  async queryRecording(): Promise<void> {  
    const restartRecording = async (recordingType: "CAMERA" | "SCREEN") => {
      const titleCaseRecordingType = startCase(recordingType.toLowerCase()).replace(/\s/g, "");
      const sidKey = `sid${titleCaseRecordingType}`;
      const resourceIdKey = `resourceId${titleCaseRecordingType}`;
      const channelKey = `channel${titleCaseRecordingType}`;

      try {
        await this.query({
          sid: this[sidKey],
          resourceId: this[resourceIdKey],
          channel: this[channelKey],
          agoraRecordingType: recordingType,
        }).then((res)=> {
          if (res?.data.serverResponse.status !== CloudServiceStatus.IN_PROGRESS) {
            restartResource(recordingType, titleCaseRecordingType);
          }
        });
  
      } catch (err) {
        if(err.response.status === 412 && err.response.data?.code == "SESSION_RECORDING_EXPIRED") {
          await restartResource(recordingType, titleCaseRecordingType);
        } else {
          ExamsLogger.error(logPrefix, `ReStarting the ${recordingType.toLowerCase()} recording failed`, err);
        }
      }
    };
  
    const restartResource = async (recordingType, titleCaseRecordingType:string) => {
      const resource = await this.acquireResource(recordingType);
      this[`setResourceId${titleCaseRecordingType}`](resource);

      const sid = await this.startRecordingSession(recordingType, resource);
      this[`setSid${titleCaseRecordingType}`](sid);
    };

    if (this.exam.recordEnabled) {
      await restartRecording("CAMERA");

      if (this.exam.screenSharing) {
        await restartRecording("SCREEN");
      }
    }
  }
  

  @action
  async startRecording(): Promise<void> {
    if (!this.exam.recordEnabled) return;
  
    ExamsLogger.info(logPrefix, "Starting the recording");
    try {
      const resource_cam = await this.acquireResource("CAMERA");
      const resource_screen = this.exam.screenSharing ? await this.acquireResource("SCREEN") : null;
  
      this.setResourceIds(resource_cam, resource_screen);
  
      await this.startRecordingSession("CAMERA", resource_cam).then((sid_cam: string)=>{
        this.setSids(sid_cam, null);
        this.setRecording(true);
        this.sendStartNotification();

        if (this.exam.screenSharing) {
          this.startRecordingSession("SCREEN", resource_screen).then((sid_screen: string) => {
            this.setSids(sid_cam, sid_screen);
          });
        }
      });

    } catch (err) {
      this.handleRecordingError(err);
    }
  }


  private async acquireResource(agoraRecordingType: "CAMERA" | "SCREEN"): Promise<string | null> {
    return this.acquire({ channel: agoraRecordingType === "CAMERA" ? this.channelCamera : this.channelScreen, agoraRecordingType });
  }

  private async startRecordingSession(agoraRecordingType: "CAMERA" | "SCREEN", resourceId: string | null): Promise<string | null> {
    return this.start({
      channel: agoraRecordingType === "CAMERA" ? this.channelCamera : this.channelScreen,
      resourceId,
      token: this.token,
      aws: this.awsCredentials,
      agoraRecordingType,
    });
  }
  
  private handleRecordingError(err: Error): void {
    this.setSids(null, null);
    this.setResourceIds(null, null);
    this.setRecording(false);
    this.sendStopNotification();
    ExamsLogger.error(logPrefix, err);
    throw err;
  }

  @action
  async stopRecording(): Promise<void> {
    if (!this.exam.recordEnabled) return;

    if (!this.exam.userRecording) return;

    ExamsLogger.info(logPrefix, "Stoping the recording");

    try {
      await Promise.all([
        this.stop({
          sid: this.sidCamera,
          resourceId: this.resourceIdCamera,
          channel: this.channelCamera,
          agoraRecordingType: "CAMERA",
        }),
        this.stop({
          sid: this.sidScreen,
          resourceId: this.resourceIdScreen,
          channel: this.channelScreen,
          agoraRecordingType: "SCREEN",
        }),
      ]);
      this.setSids(null, null);
      this.setResourceIds(null, null);
      this.setRecording(false);
      this.sendStopNotification();
    } catch (err) {
      ExamsLogger.error(logPrefix, err);
      throw err;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async makeRequest<T>(method: Method, url: string, data?: T): Promise<any> {
    const response = await axios.request({
      method,
      url: `${this.recordingUrl}${url}`,
      headers: {
        Authorization: this.authorization,
      },
      data,
    });
    return response.data;
  }

  @action
  async query(config: QueryParams): Promise<AxiosResponse> {
    const { exam } = this.roomStore.info;
    return await SessionService.agoraQuery(
      exam.id,
      exam.sessionId,
      this.uid,
      config.agoraRecordingType,
    );
  }

  @action
  async acquire(config: AcquireParams): Promise<string> {
    const { exam } = this.roomStore.info;
    if (this.roomStore.info.user.guest) {
      const response = await SessionGuestService.agoraAcquire(
        exam.id,
        exam.sessionId,
        this.roomStore.info.user.id,
        config.agoraRecordingType,
      );
      return response.sid;
    }
    const response = await SessionService.agoraAcquire(
      exam.id,
      exam.sessionId,
      config.agoraRecordingType,
    );
    return response.resourceId;
  }

  @action
  async start(config: StartParams): Promise<string> {
    const { exam } = this.roomStore.info;
    if (this.roomStore.info.user.guest) {
      const response = await SessionGuestService.agoraStart(
        exam.id,
        exam.sessionId,
        config.resourceId,
        this.roomStore.info.user.id,
        config.agoraRecordingType,
      );
      return response.sid;
    }
    const response = await SessionService.agoraStart(
      exam.id,
      exam.sessionId,
      config.resourceId,
      config.agoraRecordingType,
    );
    return response.sid;
  }

  @action
  async stop(config: StopParams): Promise<void> {
    const { exam } = this.roomStore.info;
    if (this.roomStore.info.user.guest) {
      await SessionGuestService.agoraStop(
        exam.id,
        exam.sessionId,
        config.channel,
        config.resourceId,
        this.uid,
        this.roomStore.info.user.id,
        config.agoraRecordingType,
      );
      return;
    }
    await SessionService.agoraStop(
      exam.id,
      exam.sessionId,
      config.channel,
      config.resourceId,
      this.uid,
      config.agoraRecordingType,
    );
  }

  @action
  async sendStartNotification(): Promise<void> {
    const message = createActionMessage({ type: "START_RECORD" });
    await this.rtm.channel?.sendMessage(message);
  }

  @action
  async sendStopNotification(): Promise<void> {
    const message = createActionMessage({ type: "STOP_RECORD" });
    await this.rtm.channel?.sendMessage(message);
  }
}

export default RecordStore;
