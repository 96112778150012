import React from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Loading/Loading";

export default function LoadingPage() {
  const { t } = useTranslation();

  return (
    <div className='fixed inset-0 flex flex-col items-center justify-center bg-black opacity-100 z-50'>
      <Loading />
      <div className='mt-2 text-blue font-bold text-lg'>
        {t("loading")}
        ...
      </div>
    </div>
  );
}
