import React from "react";
import { ExamCredentials } from "../../../factories";
import AppWrapper from "../../AppWrapper/AppWrapper";

interface UnProtectedWrapperProps {
  children: React.ReactNode;
}

const UnProtectedWrapper: React.FunctionComponent<UnProtectedWrapperProps> = ({ children }) => {
  return <AppWrapper credentials={ExamCredentials}>{children}</AppWrapper>;
};

export default UnProtectedWrapper;
