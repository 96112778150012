import React from "react";
import { useTranslation } from "react-i18next";
import BackgroundE4Z from "../BackgroundE4Z";
import Button from "../../Button/Button";

export default function ForceChrome(): JSX.Element {
  const { t } = useTranslation();

  return (
    <BackgroundE4Z title={t("browser_not_supported")} >
        <p className='text-white text-xl text-center my-2'>
            {t("download_chrome_or_edge_if_you_dont_have_it")}
            <br/>
            <div className="flex gap-3">
              <Button onClick={() => { window.location.href="https://www.google.com/chrome/"} } className='btn btn-primary btn-blue mx-auto mt-4'>
                  {t("download")} Chrome
              </Button>
              <Button onClick={() => { window.location.href="https://www.microsoft.com/en-us/edge/download"} } className='btn btn-primary btn-blue mx-auto mt-4'>
                  {t("download")} Edge
              </Button>
            </div>

        </p>
    </BackgroundE4Z>
  );
}
