import React from "react";
import { useTranslation } from "react-i18next";
import "../../../services/i18n/config";
import { ModalFreemiumLimitData } from "../../../types";
import Button from "../../Button/Button";
import Base, { BaseModalProps } from "../Base/Base";

type Props = BaseModalProps & {
  data: ModalFreemiumLimitData;
};

const FreemiumLimit: React.FunctionComponent<Props> = ({ onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Base onClose={onClose} className='w-1/2 !px-6 rounded-md'>
        <div className='flex flex-col space-y-4 mx-8 w-md text-left'>
          <div className='font-bold text-lg'>{ t("ten-minutes-remaining") }</div>
          <div>
            <p>{t("time-upgrade-plan")}</p>
          </div>

          <div className='flex items-center self-end gap-2' id='modal-finish'>
            <Button
              onClick={() => { onClose(); }}
              className={"bg-white border border-gray-400 text-gray-600"}>
              {t("close")}
            </Button>
          </div>
        </div>
      </Base>
    </>
  );
};

export default FreemiumLimit;
