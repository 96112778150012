import React, { useEffect, useState } from "react";
import backgroundLogo from "../../../assets/images/background_e4z.svg";
import logo from "../../../assets/images/logo_e4z.svg";
import PingLoader from "../../PingLoader/PingLoader";
import _ from "lodash";
import { useMultipleMonitors } from "../../../hooks/useMultipleMonitorsCheck";
import { useTranslation } from "react-i18next";
import Icons from "../../../images/icons";
import { useAppStore, useRoomStore } from "../../../hooks/store";

const defaultPermissions = {
  camera: null,
  microphone: null,
  screen: null,
  notifications: null,
  agora: null,
};

const defaultDescriptions = {
  camera: "",
  microphone: "",
  screen: "",
  notifications: "",
  agora: "",
};

export default function PreCheck(): JSX.Element {
  const [permissions, setPermissions] = useState(defaultPermissions);
  const [descriptions, setDescriptions] = useState(defaultDescriptions);
  const [speedStatus, setSpeedStatus] = useState("");
  const [agoraStatus, setAgoraStatus] = useState(null);
  const areAllPermissionsGranted = Object.values(permissions).every(
    (permission) => permission === true,
  );
  const appStore = useAppStore();
  const roomStore = useRoomStore();
  const cameraStore = roomStore.rtc.cameraStore;

  const { t } = useTranslation();
  const checkCamMic = async (type?: "CAM" | "MIC") => {
    try {
      const cameraMicStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      cameraMicStream.getTracks().forEach((track) => track.stop());
      setPermissions((prevPermissions) => ({ ...prevPermissions, camera: true, microphone: true }));
      setDescriptions((prevDescriptions) => ({ ...prevDescriptions, camera: "", microphone: "" }));
    } catch (error) {
      const cameraDenied = error.message.includes("Permission denied");
      const cameraDescription = cameraDenied
        ? t("cameraPermissionDenied") + `: ${error.message}`
        : t("cameraAccessError") + `: ${error.message}`;
      const microphoneDescription = cameraDenied
        ? t("microphonePermissionDenied") + `: ${error.message}`
        : t("microphoneAccessError") + `: ${error.message}`;
      setDescriptions((prevDescriptions) => ({
        ...prevDescriptions,
        camera: cameraDescription,
        microphone: microphoneDescription,
      }));
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        camera: false,
        microphone: false,
      }));

      if (
        error.message.includes("Permission denied by system") &&
        navigator.userAgent.toUpperCase().includes("MAC")
      ) {
        if (type == "CAM") {
          window.location.href =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera";
        } else {
          window.location.href =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone";
        }
      } else {
        const allowCamMic = window.confirm(
          `${t("accessBlocked", { permission: t("cameraAndMicrophone") })}`,
        );
        if (allowCamMic) {
          alert(`${t("accessInstructions", { permission: t("cameraAndMicrophone") })}`);
        }
        return;
      }
    }
  };

  const checkScreen = async () => {
    try {
      // Request to share the display
      const screenSharingStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      const videoTracks = screenSharingStream.getVideoTracks();

      if (videoTracks.length === 0) {
        throw new Error("No video track found.");
      }

      // Check if the user is sharing the entire screen
      const trackSettings = videoTracks[0].getSettings() as MediaTrackSettings & {
        displaySurface?: string;
      };
      const { displaySurface } = trackSettings;

      if (displaySurface !== "monitor") {
        // Ensure only full screen (monitor) is shared
        setPermissions((prevPermissions) => ({ ...prevPermissions, screen: false }));
        setDescriptions((prevDescriptions) => ({
          ...prevDescriptions,
          screen: "You must share the entire screen, not just a window or application.",
        }));

        throw new Error("You must share the entire screen, not just a window or application.");
      }

      // Stop the video tracks after the check
      videoTracks.forEach((track) => track.stop());

      // Update state to indicate success
      setPermissions((prevPermissions) => ({ ...prevPermissions, screen: true }));
      setDescriptions((prevDescriptions) => ({ ...prevDescriptions, screen: "" }));
    } catch (error) {
      const errorMessage = error.message;

      if (errorMessage.includes("NotAllowedError") || errorMessage.includes("Permission Denied")) {
        if (navigator.userAgent.toUpperCase().includes("MAC")) {
          // Direct users to macOS privacy settings if on a Mac
          window.location.href =
            "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture";
        } else {
          // Prompt users on other systems to allow screen sharing
          setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            screen: "Please allow full-screen sharing access in your browser settings.",
          }));
          setPermissions((prevPermissions) => ({ ...prevPermissions, screen: false }));
        }
      } else {
        // Handle other errors
        setDescriptions((prevDescriptions) => ({ ...prevDescriptions, screen: errorMessage }));
        setPermissions((prevPermissions) => ({ ...prevPermissions, screen: false }));
      }
    }
  };

  const checkMonitors = async () => {
    const thereAreMultipleMonitors = useMultipleMonitors();
    const multipleMonitors = t("multipleMonitorsDetected");
    if (thereAreMultipleMonitors) {
      setDescriptions((prevDescriptions) => ({ ...prevDescriptions, screen: multipleMonitors }));
      setPermissions((prevPermissions) => ({ ...prevPermissions, screen: false }));
    }
  };

  const checkNotificationPermission = async () => {
    if (!("Notification" in window)) {
      setDescriptions((prevDescriptions) => ({
        ...prevDescriptions,
        notifications: "This browser does not support notifications.",
      }));
      setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: false }));
      return Promise.resolve(false);
    }

    try {
      if (Notification.permission === "granted") {
        setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: true }));
        return Promise.resolve(true);
      } else if (Notification.permission === "denied") {
        setDescriptions((prevDescriptions) => ({
          ...prevDescriptions,
          notifications: "Notifications are blocked.",
        }));
        setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: false }));
        return Promise.resolve(false);
      } else {
        const notificationPermission = await Notification.requestPermission();

        if (notificationPermission === "granted") {
          setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: true }));
          return Promise.resolve(true);
        } else {
          setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            notifications: notificationPermission,
          }));
          setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: false }));
          const allowNotifications = window.confirm(
            `${t("accessBlocked", { permission: t("notifications") })}`,
          );
          if (allowNotifications) {
            alert(`${t("accessInstructions", { permission: t("notifications") })}`);
          }
          return Promise.resolve(false);
        }
      }
    } catch (error) {
      const notificationDescription = t("errorNotification") + error.message;
      setDescriptions((prevDescriptions) => ({
        ...prevDescriptions,
        notifications: notificationDescription,
      }));
      setPermissions((prevPermissions) => ({ ...prevPermissions, notifications: false }));
      return Promise.reject(error);
    }
  };

  const checkSpeed = async () => {
    const start: number = Date.now();
    const url = `https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg?t=${start}`;
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    let end: number;
    let size: number | undefined;
    let time: number;

    xhr.addEventListener("progress", updateProgress);
    xhr.addEventListener("load", transferComplete);

    xhr.open("GET", url);
    xhr.send();

    function updateProgress(oEvent: ProgressEvent<EventTarget>): void {
      if (oEvent.lengthComputable) {
        if (!size) {
          size = oEvent.total;
        }
        const percentComplete: number = (oEvent.loaded / oEvent.total) * 100;
        setSpeedStatus(`${Math.round(percentComplete)}%`);
      }
    }

    function transferComplete(): void {
      end = Date.now();
      time = (end - start) / 1000;

      if (size !== undefined) {
        const speedInKbs = parseInt((size / time / 1024).toFixed(2));
        if (speedInKbs < 750) {
          setSpeedStatus(t("poor"));
        } else if (speedInKbs < 1500) {
          setSpeedStatus(t("fair"));
        } else {
          setSpeedStatus(t("good"));
        }

        const colorPaths = (speedInKbs: number) => {
          const colorIndex =
            speedInKbs < 600
              ? { color: "red", index: 0 }
              : speedInKbs < 1e3
              ? { color: "orange", index: 1 }
              : { color: "green", index: 2 };

          for (let i = 0; i <= colorIndex?.index; i++) {
            Array.from(paths)
              .slice(0, i + 1)
              ?.forEach((path) => path?.setAttribute("fill", colorIndex.color));
          }
        };

        const paths = document.getElementById("icon_connection")?.querySelectorAll("path");
        colorPaths(speedInKbs);
      }
    }
  };

  const checkAgoraConnectivity = async () => {
    try {
      cameraStore.init();

      await cameraStore.client.join(appStore.appId, "test-channel", null, null);

      setAgoraStatus(true);

      await cameraStore.client.leave();
      setPermissions((prevPermissions) => ({ ...prevPermissions, agora: true }));
    } catch (error) {
      setDescriptions((prevDescriptions) => ({
        ...prevDescriptions,
        agora:
          "Failed to connect to server, please check Firewall rules or change your connection!",
      }));
      setPermissions((prevPermissions) => ({ ...prevPermissions, agora: false }));
      setAgoraStatus(false);
    }
  };

  const checkPermissions = async () => {
    await checkCamMic();
    await checkScreen().then(() => checkMonitors());
    await checkSpeed();
    await checkAgoraConnectivity();
  };

  useEffect(() => {
    const runPrecheck = async () => {
      await checkPermissions();
    };

    runPrecheck();
  }, []);

  return (
    <div className='min-h-screen flex items-center justify-center bg-blue-dark py-12 px-4 sm:px-6 lg:px-8'>
      <img alt='Exams For Zoom' className='absolute h-full' src={backgroundLogo} />
      <div className='max-w-[50%] w-full z-10 h-[calc(100vh-6rem)]'>
        <div className='flex items-center justify-center'>
          <img alt='Exams For Zoom' className='w-[176px]' src={logo} />
        </div>
        <div className='flex flex-col justify-between border-2 border-black/50 rounded-lg bg-black/50 h-64 p-8 mt-8 h-[90%] text-white text-xl'>
          <h2 className='text-3xl text-center'>{t("preexamSetup")}</h2>
          <p className='text-center my-2'>{t("thisPageSetup")}</p>
          {areAllPermissionsGranted && (
            <p className='flex items-end justify-center text-xl gap-2 mt-3'>
              <span className='relative inline-flex text-2xl justify-center items-center'>🙌</span>

              {t("permissionsSet")}
            </p>
          )}

          <PingLoader
            label={t("precheckCamera")}
            status={permissions.camera}
            loading={_.isNull(permissions.camera)}
            description={descriptions.camera}
            action={!permissions.camera ? () => checkCamMic("CAM") : null}
          />
          <PingLoader
            label={t("precheckMicrophone")}
            status={permissions.microphone}
            loading={_.isNull(permissions.microphone)}
            description={descriptions.microphone}
            action={!permissions.microphone ? () => checkCamMic("MIC") : null}
          />
          <PingLoader
            label={t("precheckScreenSharing")}
            status={permissions.screen}
            loading={_.isNull(permissions.screen)}
            description={descriptions.screen}
            action={!permissions.screen ? checkScreen : null}
          />
          <PingLoader
            label={t("precheckNotifications")}
            status={permissions.notifications}
            loading={_.isNull(permissions.notifications)}
            description={descriptions.notifications}
            action={!permissions.notifications ? checkNotificationPermission : null}
          />
          <PingLoader
            label={t("precheckInternetConnection")}
            status={_.size(speedStatus) > 3 ? _.size(speedStatus) > 3 : null}
            loading={_.size(speedStatus) < 4}
            description={speedStatus}
            custom={
              <Icons.Connection
                id='icon_connection'
                className={`h-5 ${_.size(speedStatus) > 3 ? "block" : "hidden"}`}
              />
            }
          />
          <PingLoader
            label={t("precheckAgora")}
            status={agoraStatus}
            loading={_.isNull(agoraStatus)}
            description={descriptions.agora}
            action={!permissions.agora ? checkAgoraConnectivity : null}
          />
        </div>
      </div>
    </div>
  );
}
