import React from "react";
import _ from "lodash/fp";
import { observer } from "mobx-react-lite";

import { useToastStore } from "../../hooks/store";
import { ToastType } from "../../stores/app/ui/toast";
import Icons from "../../images/icons";

export const useTimeout = (fn: CallableFunction, delay: number): void => {
  const timer = React.useRef(null);

  /* eslint-disable  react-hooks/exhaustive-deps */
  React.useEffect(() => {
    timer.current = setTimeout(() => {
      fn?.();
    }, delay);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [timer]);
  /* eslint-enable  react-hooks/exhaustive-deps */
};

type Props = {
  id: string;
  message: string;
  type: ToastType;
  closeToast: (id: string) => void;
  action: () => void;
  actionText: string;
};

export const Toast: React.FunctionComponent<Props> = ({ id, message, closeToast, type, action, actionText }: Props) => {
  useTimeout(() => {
    if (!action) {
      closeToast(id);
    }
  }, 4000);

  return (
    <div data-test-id={`toast-${id}`} className='flex items-center justify-center z-50'>
      <div className='rounded-lg bg-[#212325] shadow p-[16px]'>
        <div className='text-white flex font-size-[14px] justify-center align-center items-stretch space-x-2'>
          {type === "WARNING" && <Icons.Warning />}
          {type === "GROUP_SESSION" && <Icons.UserSpeakOrange />}
          {type === "NON_GROUP_SESSION" && <Icons.UserNonContact />}
          <div>
          {message?.includes("@") ? (
            <p>{message}</p>
          ) : (
            message?.split(".").map((paragraph, idx) => (
              <p key={idx}>{paragraph}</p>
            ))
          )}
          </div>
          {actionText && (
            <span className="ml-1 cursor-pointer text-blue" onClick={action}>{actionText}</span>
          )}
        </div>
      </div>
    </div>
  );
};

const ToastList = observer(() => {
  const store = useToastStore();

  return (
    <div className='fixed flex flex-col space-y-4 top-0 items-center w-full z-50 mt-12'>
      {_.map(
        (toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            id={toast.id}
            closeToast={(id) => store.removeToast(id)}
            type={toast.type}
            action={toast.action}
            actionText={toast.actionText}
          />
        ),
        store.toastQueue,
      )}
    </div>
  );
});

export default ToastList;
