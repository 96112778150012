import { observer } from "mobx-react-lite";
import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useChatStore, useUserStore } from "../../../hooks/store";
import Icons from "../../../images/icons";
import "../../../services/i18n/config";

const Chat: React.FunctionComponent = observer(() => {
  const chatStore = useChatStore();
  const userStore = useUserStore();
  const anyMessage = chatStore.anyUnreadMessage;
  const totalMessages = chatStore.totalUnreadMessages;
  const { t } = useTranslation();

  const focusChatInput = () => {
    if (userStore.isLocalHost()) return;

    chatStore.focusInput();
  };

  const className = classNames("flex flex-col items-center justify-between", {
    "text-green": anyMessage,
    "text-gray-250": !anyMessage,
    "cursor-pointer": userStore.isLocalParticipant(),
  });

  return (
    <div className={className} onClick={focusChatInput}>
      <div className='relative'>
        <Icons.Bell className='h-6' />
        <span className='text-2xs leading-2 absolute top-0 -right-2'>{totalMessages}</span>
      </div>
      <span className='text-xs text-center leading-4'>{t("chat")}</span>
    </div>
  );
});

export default Chat;
