import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";

import "../../../services/i18n/config";
import { ContextRoom } from "../../../types";
import { ExamsUserRole } from "../../../types/enum";
import ExamConditionsComponent from "../../ExamConditions/ExamConditions";
import PreroomWrapper from "../../Room/Preroom";
import { useLoadingStore } from "../../../hooks/store";

export default function Requirements() {
  const { t } = useTranslation();
  const { exam, user } = useOutletContext<ContextRoom>();
  const userIsParticipant = user.role === ExamsUserRole.PARTICIPANT;
  const { search } = useLocation();
  const loadingStore = useLoadingStore();

  useEffect(() => {
    if (exam) {
      loadingStore.stopLoading();
    } else {
      loadingStore.startLoading();
    }
  }, [exam])

  if (!exam) {
    return null;
  }

  return (
    <PreroomWrapper exam={exam} user={user} route={t("requirements")}>
      <ExamConditionsComponent
        nextURL={`/exams/${exam?.id}/${
          exam.identityRegistration && userIsParticipant ? "registration" : "start"
        }${search}`}
        exam={exam}
        role={user.role}
        demoRole={user.role}
        share_screen_tutorial_img={`${process.env.PUBLIC_URL}/share_screen_tutorial.png`}
      />
    </PreroomWrapper>
  );
}
