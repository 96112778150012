import React, { useState, useEffect } from "react";

import { useRoomStore } from "../../../../../hooks/store";
import { ExamsUser } from "../../../../../types";
import IdentityBlock from "../../../../IdentityValidation/IdentityBlock";
import UserPlayer from "../../../../Players/UserPlayer/UserPlayer";
import RoundedCard from "../../../../RoundedCard/RoundedCard";

import styles from "./RenderUser.module.scss";
import SessionService from "../../../../../services/session/sessions.service";

type RenderUserProps = {
  participant: ExamsUser;
  numberOfParticipants: number;
  handleDoubleClick: (participant: ExamsUser) => void;
};

type DocCheck = {
  extracted: Record<string, unknown>;
  documentDataChecking: Array<{ ocr: string; category: string }>;
  status: string;
};

type IdentityDataInterface = {
  docCheck: DocCheck;
  docImg: string;
  faceMatchingImg: string;
  overallResult?: {
    status: "declined" | "approved";
    comments: Array<{
      service: "liveness" | "doc-check" | "face-matching";
      status: "approved" | "declined";
      comment: string;
    }>;
  };
};

const RenderUser: React.FunctionComponent<RenderUserProps> = (props: RenderUserProps) => {
  const { participant, handleDoubleClick, numberOfParticipants } = props;

  const [identityData, setIdentityData] = useState<IdentityDataInterface>({
    docCheck: { extracted: {}, documentDataChecking: [], status: "denied" },
    docImg: "",
    faceMatchingImg: "",
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const roomStore = useRoomStore();
  const { identityRegistration } = roomStore.info.exam;

  useEffect(() => {
    if (identityRegistration) {
      SessionService.getIdValidationResult(
        roomStore.info.exam.id,
        roomStore.info.exam.sessionId,
        participant.uid,
      ).then((response) => {
        setIdentityData({
          docCheck: response.docCheck,
          docImg: response.docImage,
          faceMatchingImg: response.faceMatchingImg,
          overallResult: response.overallResult,
        });
        setSuccess(true);
        setLoading(false);
      });
    }
  }, [roomStore.info.exam.id, participant, identityRegistration, roomStore.info.exam.sessionId]);

  return (
    <div onDoubleClick={() => handleDoubleClick(participant)} key={participant.uid}>
      <RoundedCard className={styles.roundedCard}>
        <UserPlayer
          object_fit_contain
          focus='PARTICIPANT'
          className={styles.h70}
          user={participant}
        />
        {!success ? null : (
          <div className={numberOfParticipants > 1 ? styles.imgBlockBig : styles.imgBlockSmall}>
            <img src={identityData.faceMatchingImg} alt='img' />
            <img src={identityData.docImg} alt='img' />
          </div>
        )}
        {loading ? null : (
          <IdentityBlock
            docCheck={identityData.docCheck}
            username={participant.username}
            overallResult={identityData.overallResult}
          />
        )}
      </RoundedCard>
    </div>
  );
};

export default RenderUser;
