import type { IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import _ from "lodash/fp";
import { action, computed, makeObservable, observable, ObservableSet } from "mobx";

import type { ChannelUser, ExamsUser } from "../../../../types";
import { ExamsUserRole } from "../../../../types/enum";
import RTCStore from "../../rtc";
import RTMStore from "../../rtm";
import { Resetable } from "../../../interfaces/resetable";
import RoomStore from "..";
import compare from "../../../../lib/compare";
import CameraStore from "../../rtc/camera";

import ViewModeStore from "./viewMode";

class UserStore implements Resetable {
  roomStore!: RoomStore;

  viewModeStore: ViewModeStore;

  @observable
  hiddenParticipants: ObservableSet<UID> = observable.set();

  @observable
  isHostBusy = false;

  constructor(room: RoomStore) {
    makeObservable(this);
    this.roomStore = room;
    this.viewModeStore = new ViewModeStore(this);
  }

  @action
  reset(): void {
    this.hiddenParticipants.clear();
    this.viewModeStore.reset();
    this.isHostBusy = false;
  }

  get rtm(): RTMStore {
    return this.roomStore.rtm;
  }

  get rtc(): RTCStore {
    return this.roomStore.rtc;
  }

  get cameraStore(): CameraStore {
    return this.rtc.cameraStore;
  }

  @computed
  get localUser(): ExamsUser {
    const user = _.get(this.rtc.localUid, this.channelUsers);
    return {
      uid: this.rtc.localUid,
      audioTrack: this.rtc.localAudioTrack,
      videoTrack: this.rtc.localVideoTrack,
      screenTrack: this.rtc.localScreenTrack,
      username: user?.username,
      role: user?.role as ExamsUserRole,
      route: user?.route,
    };
  }

  @computed
  get otherUsers(): ExamsUser[] {
    return _.map(
      (user) => ({
        uid: user.uid,
        username: _.get([user.uid, "username"], this.channelUsers),
        role: _.get([user.uid, "role"], this.channelUsers) as ExamsUserRole,
        videoTrack: user.videoTrack,
        audioTrack: user.audioTrack,
        screenTrack: _.get([user.uid, "videoTrack"], this.screenRemoteUsers),
        route: _.get([user.uid, "route"], this.channelUsers),
      }),
      this.remoteUsers,
    );
  }

  @computed
  get rtmParticipants(): ChannelUser[] {
    const users = _.map(
      (user) => ({
        uid: user.uid,
        fullname: _.get([user.uid, "fullname"], this.channelUsers),
        username: _.get([user.uid, "username"], this.channelUsers),
        role: _.get([user.uid, "role"], this.channelUsers) as ExamsUserRole,
        route: _.get([user.uid, "route"], this.channelUsers),
        picture: _.get([user.uid, "picture"], this.channelUsers),
      }),
      this.channelUsers,
    );
    return _.reject((user) => this.isHost(user.uid), users);
  }

  @computed
  get speakingToNone(): boolean {
    return this.cameraStore.speakTo === "none";
  }

  @computed
  get listeningToNone(): boolean {
    return this.cameraStore.listenTo === "none";
  }

  @computed
  get speakingToAll(): boolean {
    return this.cameraStore.speakTo === "all";
  }

  @computed
  get listeningToAll(): boolean {
    return this.cameraStore.listenTo === "all";
  }

  @computed
  get speakingToUser(): boolean {
    return !["all", "none"].includes((this.cameraStore.speakTo).toString());
  }

  @computed
  get listeningToUser(): boolean {
    return !["all", "none"].includes((this.cameraStore.listenTo).toString());
  }

  @computed
  private get channelUsers(): Record<string, ChannelUser> {
    return this.rtm.channelUsers;
  }

  @computed
  private get remoteUsers(): IAgoraRTCRemoteUser[] {
    const uid = this.rtc.localUid;
    return _.reject((user) => compare.equalAgoraUID(user.uid, uid), this.rtc.remoteUsers);
  }

  @computed
  private get screenRemoteUsers(): Record<UID, IAgoraRTCRemoteUser> {
    const uid = this.rtc.localUid;
    return _.omit(uid, this.rtc.screenRemoteUsers);
  }

  private isRole(role: ExamsUserRole, uid: UID): boolean {
    const user = _.get(uid, this.channelUsers);
    if (_.isEmpty(user)) return false;

    return _.isEqual(user.role, role);
  }

  isHost(uid: UID): boolean {
    return this.isRole(ExamsUserRole.HOST, uid);
  }

  isUserHost(user: ExamsUser): boolean {
    return this.isHost(user.uid);
  }

  isParticipant(uid: UID): boolean {
    return this.isRole(ExamsUserRole.PARTICIPANT, uid);
  }

  isUserParticipant(user: ExamsUser): boolean {
    return this.isParticipant(user.uid);
  }

  isLocalUser(uid: UID): boolean {
    return compare.equalAgoraUID(this.localUser.uid, uid);
  }

  isLocalHost(): boolean {
    return this.isUserHost(this.localUser);
  }

  isLocalParticipant(): boolean {
    return this.isUserParticipant(this.localUser);
  }

  @computed
  get participants(): ExamsUser[] {
    const withoutHost = _.reject((user) => this.isUserHost(user), this.otherUsers);
    if (this.isLocalParticipant()) {
      return [this.localUser, ...withoutHost];
    }

    return withoutHost;
  }

  @computed
  get totalParticipants(): number {
    return this.participants.length;
  }

  @computed
  get host(): ExamsUser {
    if (this.isLocalHost()) {
      return this.localUser;
    }

    return _.find((user) => this.isUserHost(user), this.otherUsers);
  }

  participantIndex(uid: UID): number {
    return _.findIndex((user) => compare.equalAgoraUID(user.uid, uid), this.participants);
  }

  user(uid: UID): ExamsUser {
    if (compare.equalAgoraUID(this.localUser.uid, uid)) {
      return this.localUser;
    }

    return _.find((user) => compare.equalAgoraUID(user.uid, uid), this.otherUsers);
  }

  @action
  listenToOneParticipant(uid: UID): void {
    if (!this.isLocalHost()) return;

    if (!this.isParticipant(uid)) return;

    this.cameraStore.playOnlyOneAudio(uid);
  }

  @action
  listenToAllParticipants(): void {
    if (!this.isLocalHost()) return;

    this.cameraStore.playAllAudio();
  }

  @action
  listenToNoneParticipants(): void {
    if (!this.isLocalHost()) return;

    this.cameraStore.stopAllAudio();
  }

  @action
  listenToHost(): void {
    if (this.isLocalHost()) return;

    this.isHostBusy = false;
    this.cameraStore.playAudio(this.host.uid);
  }

  @action
  notListenToHost(): void {
    if (this.isLocalHost()) return;

    this.isHostBusy = true;
    this.cameraStore.stopAudio(this.host.uid);
  }

  @action
  async speakToOneParticipant(uid: UID): Promise<void> {
    if (!this.isLocalHost()) return;

    if (!this.isParticipant(uid)) return;

    this.cameraStore.remotePlayOnlyOneAudio(uid);
  }

  @action
  async speakToAllParticipants(): Promise<void> {
    if (!this.isLocalHost()) return;

    await this.cameraStore.remotePlayAllAudio();
  }

  @action
  async speakToNoneParticipants(): Promise<void> {
    if (!this.isLocalHost()) return;

    await this.cameraStore.remoteStopAllAudio();
  }

  @computed
  get participantModeParticipants(): ExamsUser[] {
    const { participants } = this;
    if (this.viewModeStore.getNumUsers("PARTICIPANT") > _.size(participants)) {
      return participants;
    }

    const range = this.viewModeStore.getUserRange("PARTICIPANT");
    return ViewModeStore.cyclicList(participants, range);
  }

  @computed
  get oneOnOneModeParticipant(): ExamsUser {
    const range = this.viewModeStore.getUserRange("ONE_ON_ONE");
    return _.first(ViewModeStore.cyclicList(this.participants, range));
  }

  @computed
  get examModeParticipants(): ExamsUser[] {
    const { participants } = this;
    if (this.viewModeStore.getNumUsers("EXAM") > _.size(participants)) {
      return participants;
    }

    const range = this.viewModeStore.getUserRange("EXAM");
    return ViewModeStore.cyclicList(participants, range);
  }

  @computed
  get hybridExamModeParticipants(): ExamsUser[] {
    const { participants } = this;
    if (this.viewModeStore.getNumUsers("HYBRID_EXAM") > _.size(participants)) {
      return participants;
    }

    const range = this.viewModeStore.getUserRange("HYBRID_EXAM");
    return ViewModeStore.cyclicList(participants, range);
  }

  @computed
  get hybridParticipantModeParticipants(): ExamsUser[] {
    const { participants } = this;
    if (this.viewModeStore.getNumUsers("HYBRID_PARTICIPANT") > _.size(participants)) {
      return participants;
    }

    const range = this.viewModeStore.getUserRange("HYBRID_PARTICIPANT");
    return ViewModeStore.cyclicList(participants, range);
  }

  @action
  setUserRoute(uid: UID, route: string): void {
    const user = _.pipe(_.get(uid), _.set("route", route))(this.channelUsers) as ChannelUser;
    this.rtm.updateChannelUser(user);
  }

  @action
  doubleClickOnParticipant(participant: ExamsUser): void {
    const index = this.roomStore.userStore.participantIndex(participant.uid);
    this.viewModeStore.changeMode("ONE_ON_ONE");
    this.viewModeStore.changeUserRange("ONE_ON_ONE", { from: index, to: index + 1 });
  }
}

export default UserStore;
