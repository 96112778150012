import _ from "lodash/fp";
import { action, makeObservable, observable } from "mobx";

import type { Views, ViewsMax, ViewsUserRange } from "../../../../types";
import { Resetable } from "../../../interfaces/resetable";

import UserStore from ".";

class ViewModeStore implements Resetable {
  userStore!: UserStore;

  @observable
  mode: keyof Views = "PARTICIPANT";

  @observable
  views: Views;

  @observable
  scroll= false;

  @observable
  hybridRight = true;

  interval: ReturnType<typeof setInterval>

  private static max: ViewsMax = {
    HYBRID_PARTICIPANT: 6,
    HYBRID_EXAM: 6,
    EXAM: 6,
    IDENTITY: 6,
    PARTICIPANT: 48,
    ONE_ON_ONE: 1,
  };

  private static default: ViewsMax = {
    HYBRID_PARTICIPANT: 6,
    HYBRID_EXAM: 6,
    EXAM: 6,
    IDENTITY: 6,
    PARTICIPANT: 9,
    ONE_ON_ONE: 1,
  };

  private static calculateRange = (range: ViewsUserRange): number => range.to - range.from;

  private static cyclicIndex = (index: number, len: number): number => ((index % len) + len) % len;

  static cyclicList<T>(list: T[], range: ViewsUserRange): T[] {
    return [...list, ...list].slice(range.from, range.to);
  }

  constructor(user: UserStore) {
    makeObservable(this);
    this.userStore = user;
    this.defaultViews();
  }

  @action
  defaultViews(): void {
    this.views = {
      HYBRID_PARTICIPANT: { from: 0, to: ViewModeStore.default.HYBRID_PARTICIPANT },
      HYBRID_EXAM: { from: 0, to: ViewModeStore.default.HYBRID_EXAM },
      EXAM: { from: 0, to: ViewModeStore.default.EXAM },
      IDENTITY: { from: 0, to: ViewModeStore.default.EXAM },
      PARTICIPANT: { from: 0, to: ViewModeStore.default.PARTICIPANT },
      ONE_ON_ONE: { from: 0, to: ViewModeStore.default.ONE_ON_ONE },
    };
  }

  @action
  reset(): void {
    this.mode = "PARTICIPANT";
    this.defaultViews();
  }

  @action
  changeUserRange(mode: keyof Views, range: ViewsUserRange): void {
    if (ViewModeStore.calculateRange(range) > ViewModeStore.max[mode]) return;

    this.views[mode] = range;
  }

  isMode(mode: keyof Views): boolean {
    return this.mode === mode;
  }

  getUserRange(mode: keyof Views): ViewsUserRange {
    return _.get(mode, this.views);
  }

  @action
  changeMode(mode: keyof Views): void {
    this.mode = mode;
    this.scroll && this.controlScroll(mode);
  }

  getNumUsers(mode: keyof Views): number {
    const range = this.getUserRange(mode);
    return ViewModeStore.calculateRange(range);
  }

  @action
  getUsersColumns(mode: keyof Views, users: number): number {
    const breakpoints = [1,4,9,16,25,36,42,48];

    for (let i = 0; i < breakpoints.length; i++) {
      if(users <= breakpoints[i]) 
        return Math.floor(breakpoints[i] / Math.floor(Math.sqrt(breakpoints[i])));
    }
    return 3;
  }

  @action
  getUsersRows(mode: keyof Views, users: number): number {
    const breakpoints = [2,6,12,20,25,30,42,48];

    for (let i = 0; i < breakpoints.length; i++) {
      if(users <= breakpoints[i]) 
        return Math.floor(Math.sqrt(breakpoints[i]));
    }
    return 2;
  }

  @action
  controlScroll(mode: keyof Views): void {
    if(!this.scroll){
      this.interval = setInterval(() => {
        this.next(mode);
      }, 10000);
    }else{
      clearInterval(this.interval);
    }
    this.scroll = !this.scroll;
  }

  @action
  controlSwitch(): void {
    this.hybridRight = !this.hybridRight;
  }

  @action
  increaseUsers(mode: keyof Views): void {
    if (this.isRangeAtMaximum(mode)) return;

    const range = this.getUserRange(mode);
    const totalUsers = _.size(this.userStore.participants);
    const from = range.to + 1 > totalUsers ? 0 : range.from;
    const to = range.to + 1 > totalUsers ? _.min([(range.to - range.from), totalUsers]) : range.to;

    this.changeUserRange(mode, { from: from, to: to + 1 });
  }

  @action
  decreaseUsers(mode: keyof Views): void {
    if (this.isRangeAtMinimum(mode)) return;

    const range = this.getUserRange(mode);
    const totalUsers = _.size(this.userStore.participants);
    const from = range.to + 1 > totalUsers ? 0 : range.from;
    const to = range.to + 1 > totalUsers ? _.min([(range.to - range.from), totalUsers]) : range.to;

    this.changeUserRange(mode, { from: from, to: to - 1 });
  }

  @action
  next(mode: keyof Views): void {
    const index = this.getUserRange(mode).from;
    const totalUsers = _.size(this.userStore.participants);

    const nextIndex = index + this.getNumUsers(mode);
    const i = ViewModeStore.cyclicIndex(nextIndex, totalUsers);
    this.changeUserRange(mode, { from: i, to: i + this.getNumUsers(mode) });
  }

  @action
  prev(mode: keyof Views): void {
    const index = this.getUserRange(mode).from;
    const totalUsers = _.size(this.userStore.participants);

    const nextIndex = index - this.getNumUsers(mode);
    const i = ViewModeStore.cyclicIndex(nextIndex, totalUsers);
    this.changeUserRange(mode, { from: i, to: i + this.getNumUsers(mode) });
  }

  isUserFocused(index: number): boolean {
    const range = this.getUserRange("ONE_ON_ONE");
    return this.isMode("ONE_ON_ONE") && _.isEqual(range.from, index);
  }

  isRangeAtMinimum(mode: keyof Views): boolean {
    return this.getNumUsers(mode) <= 1;
  }

  isRangeAtMaximum(mode: keyof Views): boolean {
    const totalUsers = _.size(this.userStore.participants);
    const max = totalUsers < ViewModeStore.max[mode] ? totalUsers : ViewModeStore.max[mode];
    return this.getNumUsers(mode) >= max;
  }
}

export default ViewModeStore;
