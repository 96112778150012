import { UID } from "agora-rtc-sdk-ng";
import { ID } from "../../types";
import client from "../client";

// TODO: handle exam state and state properly
export type SessionResponse = {
  academicInstructions: string;
  accessType: "LINK" | "LIST";
  banned: boolean;
  examId: ID;
  examStartDate: string;
  examState: "CANCELED" | "CLOSED" | "DRAFT" | "OPEN" | "SCHEDULED";
  id: ID;
  inWaitingLounge: boolean;
  sessionType: "LIVE" | "UNATTENDED";
  state: "FINISHED" | "OPENED" | "STARTED";
  supervisor: boolean;
  unattended: boolean;
  passCodeEnable: boolean;
  groupSession: boolean;
  userId?: ID;
  defaultFeedback?: [
    "EASY_TO_USE",
    "CLARITY_INSTRUCTIONS",
    "LEVEL_DISTRACTIONS",
    "QUALITY_AUDIO_VIDEO",
  ];
  feedback?: string[];
  uploadFiles?: boolean;
};

export type SentTypeType = "ONE_TO_MANY" | "ONE_TO_ONE"

export type ExamChatRequestType = {
  message: string;
  sentByAgoraUserId: UID;
  sentToAgoraUserId: UID;
  sentType: SentTypeType;
}

export type SessionRoomInfoResponse = {
  id: ID;
  examId: ID;
  name: string;
  username: string;
  lmsURL: string;
  endTime: string | null;
  identityRegistration: boolean;
  recording: boolean;
  sentimentEnabled: boolean;
  recognitionEnabled: boolean;
  attentionEnabled: boolean;
  disableAudio: boolean;
  uploadFiles: boolean;
  takeScreenshot: boolean;
  feedback: string[];
  userRecordingCamera: boolean;
  userRecordingScreen: boolean;
  manualStarted: boolean;
  supervisorFinishedWaitingRoom: boolean;
  screenSharing: boolean;
  passcode: string;
  manualStartDate?: string;
  notifyParticipants?: boolean;
  objectDetection?: boolean;
};

export type SessionStartResponse = {
  agoraId: number;
  agoraUserId: number;
  examId: ID;
  id: ID;
};

export type SessionFinishResponse = {
  agoraId: number;
  examId: ID;
  id: ID;
};

export type SessionIdValidationToken = {
  responseCode: number;
  token: string;
};

const get = async (examId: ID): Promise<SessionResponse> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${examId}`,
  );
  return response.data;
};

const link = async (examId: ID, password?: string): Promise<SessionResponse> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/link?examId=${examId}${
      password ? "&password=" + password : ""
    }`,
  );
  return response.data;
};

const proctorLink = async (examId: ID): Promise<SessionResponse> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/proctor/link?examId=${examId}`,
  );
  return response.data;
};

const start = async (
  examId: ID,
  sessionId: ID,
  password?: string,
): Promise<SessionStartResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/start?examId=${examId}${
      password ? "&password=" + password : ""
    }`,
  );
  return response.data;
};

const getRoom = async (examId: ID, sessionId: ID): Promise<SessionRoomInfoResponse> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/room/${sessionId}?examId=${examId}`,
  );
  return response.data;
};

const finishWaitingRoom = async (examId: ID, sessionId: ID): Promise<SessionRoomInfoResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/waiting_room/finish?examId=${examId}`,
  );
  return response.data;
};

const manualStart = async (examId: ID, sessionId: ID): Promise<SessionRoomInfoResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/manual/start?examId=${examId}`,
  );
  return response.data;
};

const addBan = async (examId: ID, sessionId: ID, agoraUserId: ID): Promise<SessionRoomInfoResponse> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/ban?examId=${examId}&agoraUserId=${agoraUserId}`,
  );
  return response.data;
};

const getToken = async (examId: ID, sessionId: ID): Promise<SessionIdValidationToken> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/idValidation/token?examId=${examId}`,
  );
  return response.data;
};

const storeIdValidationResult = async (
  examId: ID,
  sessionId: ID,
  result: string,
): Promise<SessionStartResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/idValidation/result/${result}?examId=${examId}`,
  );
  return response.data;
};

const getIdValidationResult = async (examId: ID, sessionId: ID, agoraUserId: UID): Promise<any> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/idValidation/result?examId=${examId}&agoraUserId=${agoraUserId}`,
  );
  return response.data;
};

const agoraStart = async (
  examId: ID,
  sessionId: ID,
  resourceId: string,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/agora/${resourceId}/start?examId=${examId}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const agoraStop = async (
  examId: ID,
  sessionId: ID,
  channel: string,
  resourceId: string,
  uid: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/agora/${resourceId}/stop?channel=${channel}&examId=${examId}&uid=${uid}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const agoraAcquire = async (
  examId: ID,
  sessionId: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/agora/acquire?examId=${examId}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response.data;
};

const agoraQuery = async (
  examId: ID,
  sessionId: ID,
  uid: ID,
  agoraRecordingType: "CAMERA" | "SCREEN",
): Promise<any> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/agora/query?examId=${examId}&uid=${uid}&agoraRecordingType=${agoraRecordingType}`,
  );
  return response;
};

const finishExam = async (examId: ID, sessionId: ID): Promise<SessionResponse> => {
  const response = await client.put(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/finish?examId=${examId}`,
  );
  return response.data;
};

const getIncidences = async (examId: ID, sessionId: ID): Promise<any> => {
  const response = await client.get(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/incidences/all?examId=${examId}`,
  );
  return response.data;
};

const addChat = async (examId: ID, sessionId: ID, request: ExamChatRequestType): Promise<SessionRoomInfoResponse> => {
  const response = await client.post(
    `${process.env.REACT_APP_API_EXAM_SERVICE}/v1/sessions/${sessionId}/chat?examId=${examId}`,
    request,
  );
  return response.data;
};

const SessionService = {
  get,
  start,
  getRoom,
  getToken,
  addBan,
  finishWaitingRoom,
  storeIdValidationResult,
  getIdValidationResult,
  agoraAcquire,
  agoraQuery,
  agoraStart,
  agoraStop,
  link,
  proctorLink,
  finishExam,
  manualStart,
  getIncidences,
  addChat
};

export default SessionService;
