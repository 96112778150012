import React from "react";

import Icons from "../../../images/icons";

const Logo: React.FunctionComponent = () => (
  <div className='flex items-center text-white w-64'>
    <Icons.Logo width='93px' className='ml-6' />
  </div>
);

export default Logo;
