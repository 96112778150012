// https://codepen.io/kmt91kok71/pen/PobBBOZ
export const makeScreenshot = async (): Promise<string> => {
  try {
    // eslint-disable-next-line
    const devices = navigator.mediaDevices as any;
    const stream = await devices.getDisplayMedia({
      video: { mediaSource: "screen" },
    });

    const track = stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(track);
    const bitmap = await imageCapture.grabFrame();
    track.stop();

    const canvas = document.createElement("canvas");
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    const context = canvas.getContext("2d");
    context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
    const image = canvas.toDataURL("image/jpeg");
    return image;
  } catch (e) {
    // User cancel screenshot popup
    return null;
  }
};

const ScrenshotLib = {
  makeScreenshot,
};

export default ScrenshotLib;
