import React from "react";
import IncidenceService from "../../services/incidence.service";
import ExamsLogger from "../../logger";
import SessionGuestService from "../../services/session/sessions.guest.service";
import SessionService from "../../services/session/sessions.service";
import { RoomExam, RoomUser } from "../../types";
import ParticipantSideBarBeforeExam from "../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/ParticipantSideBarBeforeExam";
import ParticipantTopBar from "../TopBar/ParticipantTopBar/ParticipantTopBar";
import Persona from "persona";
import { getCountryIdValidation, getLangIdValidation } from "../../services/i18n/config";
import { ExamIncidenceType } from "../../types/enum";

export type Props = {
  nextURL: string;
  exam: RoomExam;
  user: RoomUser;
};

// TODO: redo avoidStuck function
// Backend connection not working well
export default function RegistrationComponent({ nextURL, exam, user }: Props) {
  const throwIncidence = async (incidentType: ExamIncidenceType, redirect = false) => {
    try {
      await IncidenceService.create({
        examId: exam.id,
        sessionId: exam.sessionId,
        userId: user.id,
        type: incidentType,
        guest: user.guest,
      });
    } catch (e) {
      ExamsLogger.error("Incidence creation error: ", e);
    }
    if (redirect) {
      window.location.href = nextURL;
    }
  };

  const sendResult = async (id: string) => {
    if (user.guest) {
      await SessionGuestService.storeIdValidationResult(exam.id, exam.sessionId, id, user.id);
    } else {
      await SessionService.storeIdValidationResult(exam.id, exam.sessionId, id);
    }
  };

  return (
    <div className='bg-black flex flex-col'>
      <ParticipantTopBar
        examName={exam.name}
        takeScreenshot={exam.takeScreenshot}
        uploadFiles={exam.uploadFiles}
        recordEnabled={exam.recordEnabled}
        isUnattended={exam.isUnattended}
      />
      <div className='flex flex-row h-full'>
        <div className='h-screen'>
          <ParticipantSideBarBeforeExam registrationEnabled={exam.identityRegistration} />
        </div>
        <div className='w-[100%]'>
          <div className='flex justify-center h-[100%]'>
            <Persona.Inquiry
              fields={{
                ["selected_country_code"]: getCountryIdValidation(),
              }}
              language={getLangIdValidation()}
              templateId={process.env.REACT_APP_PERSONA_TEMPLATE_ID}
              environmentId={process.env.REACT_APP_PERSONA_ENV_ID}
              frameHeight={720}
              frameWidth={500}
              onComplete={async ({ inquiryId, status, fields }) => {
                if (status !== "completed") {
                  await throwIncidence(ExamIncidenceType.KO_ID_VALIDATION);
                } else {
                  const name = (fields["name-first"].value + " " + fields["name-last"].value).toLowerCase();
                  if (name != (exam.fullname).toLowerCase()) {
                    await throwIncidence(ExamIncidenceType.ID_NAME_MISMATCH);
                  };
                }
                sendResult(inquiryId).then(() => {
                  window.location.href = nextURL;
                }).catch(() => {
                  throwIncidence(ExamIncidenceType.KO_ID_VALIDATION);
                });
                // Inquiry completed. Optionally tell your server about it.
                console.log(`Sending finished inquiry ${inquiryId} to backend`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
