import React from "react";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import { ExamCredentials } from "../../../factories";
import { ContextRoom } from "../../../types";
import FileUpload from "../../FileUpload/FileUpload";
import Wrapped from "../../SideBar/ParticipantSideBar/ParticipantSideBarBeforeExam/Wrapped/Wrapped";
import ParticipantTopBar from "../../TopBar/ParticipantTopBar/ParticipantTopBar";

export default function Finish() {
  const { user, exam } = useOutletContext<ContextRoom>();
  const { search } = useLocation();

  if (!exam.uploadFiles) {
    return <Navigate to={`/exams/${exam.id}/feedback${search}`} />;
  }
  return (
    <>
      <div className='bg-black flex flex-col h-screen'>
        <ParticipantTopBar
          examName={exam.name}
          takeScreenshot={exam.takeScreenshot}
          uploadFiles={exam.uploadFiles}
          recordEnabled={exam.recordEnabled}
          isUnattended={exam.isUnattended}
        />
        <div className='flex flex-row h-screen'>
          <Wrapped credentials={ExamCredentials} exam={exam} user={user} route='Finish' />
          <div className='flex flex-col flex-1 bg-white border border-black border-l-8'></div>
        </div>
      </div>
      <div
        className='fixed z-10 inset-0 overflow-hidden visible bg-opacity-0'
        aria-labelledby='modal-title'
        role='dialog'
        aria-modal='true'
        id='feedback-form-modal'
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div
            className='fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity'
            aria-hidden='true'
          ></div>
          <div
            className='flex justify-center mx-auto transition-all duration-500 ease-out'
            id='feedback-form-modal'
          >
            <FileUpload />
          </div>
        </div>
      </div>
    </>
  );
}
