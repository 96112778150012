import React from "react";

import Logo from "../Logo/Logo";
import ExamName from "../ExamName/ExamName";
import ParticipantToolTip from "../../Tooltip/ParticipantToolTip/ParticipantToolTip";
import Icons from "../../../images/icons";
import HostBusyLabel from "../HostBusyLabel/HostBusyLabel";
import ConnectionPlayer from "../../Players/ConnectionPlayer/ConnectionPlayer";
import _ from "lodash";

type Props = {
  examName: string;
  takeScreenshot?: boolean;
  uploadFiles?: boolean;
  recordEnabled?: boolean;
  isUnattended?: boolean;
  isDemo?: boolean;
  showHostBusyLabel?: boolean;
  connectionState?: string;
};

const ParticipantTopBar: React.FunctionComponent<Props> = ({
  examName,
  takeScreenshot = false,
  uploadFiles = false,
  recordEnabled = false,
  isUnattended = false,
  showHostBusyLabel = false,
  connectionState = "",
}: Props) => (
  <div className='h-10 w-full flex items-center'>
    <Logo />
    <div className='px-2 flex flex-grow justify-between'>
      <div className='px-2 flex'>
        <ExamName name={examName} />
        {!_.isEmpty(connectionState) && <ConnectionPlayer connectionState={connectionState} />}
      </div>
      {showHostBusyLabel && <HostBusyLabel />}
      <ParticipantToolTip
        align='text-left'
        offset="{'bottom': 100}"
        tooltipId='tt-topbar'
        takeScreenshot={takeScreenshot}
        uploadFiles={uploadFiles}
        recordEnabled={recordEnabled}
        isUnattended={isUnattended}
      >
        <div className='w-5'>
          <Icons.SecurityBlue />
        </div>
      </ParticipantToolTip>
    </div>
  </div>
);

export default ParticipantTopBar;
