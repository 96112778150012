import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAuthorizationStore } from "../../../hooks/store";
import CountDown from "../../CountDown/CountDown";
import BackgroundE4Z from "../BackgroundE4Z";
import dateHelper from "../../../lib/date";

type TitleProps = {
  waitingLounge: boolean;
};
const Title = ({ waitingLounge }: TitleProps) => {
  const { t } = useTranslation();

  if (waitingLounge) {
    return <>{t("waiting_for_supervisor")}</>;
  }

  return <>{t("your_exam_will_start_in")}</>;
};

export default function Scheduled() {
  const authorizationStore = useAuthorizationStore();
  const { id } = useParams();
  const { search, state } = useLocation();

  useEffect(() => {
    if (state && state.type === "WAITING_LOUNGE") {
      setInterval(() => {
        window.location.href = `/exams/${id}/welcome${search}`;
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <BackgroundE4Z title={<Title waitingLounge={state && state.type === "WAITING_LOUNGE"} />}>
      <div className='text-white'>
        {state && state.type === "NOT_OPEN" && (
          <CountDown
            dateTime={dateHelper.utcToUserTimezone(authorizationStore?.examInfo?.examStartDate)}
            navigateTo={`/exams/${id}/welcome${search}`}
          />
        )}
      </div>
    </BackgroundE4Z>
  );
}
