import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { useAuthorizationStore } from "../../../hooks/store";
import { ID } from "../../../types";
import Loading from "../Loading/Loading";
import { observer } from "mobx-react-lite";
import useQuery from "../../../hooks/useQuery";
import { ExamSessionErrors } from "../../../stores/app/authorization";
import { sec } from "../../../services/util/security";
import { ExamsUserRole } from "../../../types/enum";
import LtiService from "../../../services/lti";
import { addAccessTokenInterceptorForLti } from "../../../services/client";

type Props = {
  examId: ID;
};

const getUserContextAccount = (user: User) => {
  const userContext = user[`${process.env.REACT_APP_AUTH0_API}userRolesContext`];

  return userContext ? userContext.accounts : undefined;
};

const Protected: React.FunctionComponent<Props> = observer(({ examId }: Props): JSX.Element => {
  const user = sec.getAuthUser();
  const authorizationStore = useAuthorizationStore();
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const accountId = getUserContextAccount[0]?.accountId || query.get("accountId");
  const ltk = query.get("ltk");
  const lms = query.get("lms");
  const [redirected, setRedirected] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { loginWithPopup } = useAuth0();

  const getTemporalTokenLti = useCallback(async () => {
    const temporalToken = await LtiService.getTemporalToken(ltk);
    const me = await LtiService.me(temporalToken.LTI);
    addAccessTokenInterceptorForLti(accountId, temporalToken.panel);
    sec.setAccessTokenSilently(() => temporalToken.panel);
    authorizationStore.setRoomUser({
      name: me.name,
      role: me.role,
      id: temporalToken.userId,
      account: "",
      agoraUserId: "",
      guest: false,
    });
  }, [accountId, authorizationStore, ltk]);

  const getTemporalTokenLms = useCallback(async () => {
    addAccessTokenInterceptorForLti(accountId, lms);
    sec.setAccessTokenSilently(() => lms);
    authorizationStore.setRoomUser({
      name: "",
      role: ExamsUserRole.PARTICIPANT,
      id: "",
      account: accountId,
      agoraUserId: "",
      guest: false,
    });
  }, [accountId, authorizationStore, lms]);

  const handleExamState = async() => {
    if(authorizationStore.examInfo == null){
      await authorizationStore.getExamInfo(examId, accountId);
    }

    switch (authorizationStore.examInfo?.examState) {
      case "DRAFT":
      case "SCHEDULED":
        setRedirected(true);
        navigate(`/exams/${examId}/scheduled${search}`, {
          state: { type: "NOT_OPEN" },
        });
        break;
      case "CANCELED":
        navigate("/not-found");
        break;
      case "CLOSED":
        if (authorizationStore?.sessionResult?.supervisor) {
          if (query.get("ltk") || query.get("lms")) {
            navigate(`/exams/${examId}/finished${search}`);
          } else {
            window.location.href = process.env.REACT_APP_BASE_URL;
          }
        } else if (
            !window.location.href.includes("finish") &&
            !window.location.href.includes("feedback")
          ) {
            const search = new URL(document.location.toString()).searchParams;
            window.location.href = `/exams/${examId}/finish?${search}`;
          }
        break;
      default:
        break;
    }
  };

  const handleAccess = async () => {
    if (ltk && !authorizationStore.roomUser?.id) {
      await getTemporalTokenLti();
    }
    if (lms && !authorizationStore.roomUser?.id) {
      await getTemporalTokenLms();
    }
    authorizationStore.checkAccessControl(accountId, examId, ltk, lms).then(() => {
      const { error } = authorizationStore;

      if (user) {
        authorizationStore.setRoomUser({
          id: user[`${process.env.REACT_APP_AUTH0_API}userId`],
          name: user.email,
          role: authorizationStore.sessionResult?.supervisor
            ? ExamsUserRole.HOST
            : ExamsUserRole.PARTICIPANT,
          account: accountId,
          agoraUserId: authorizationStore.startResult?.agoraUserId,
          guest: false,
          picture: user.picture,
        });
      }

      if (error === ExamSessionErrors.LOGIN) {
        navigate(`/exams/${examId}?${query}`);
      }

      if (error && !redirected) {
        if (error === ExamSessionErrors.NOT_FOUND) {
          navigate(`/not-found${search}`);
        }

        if ([ExamSessionErrors.NOT_OPEN, ExamSessionErrors.EXAM_NOT_IN_OPENED].includes(error)) {
          handleExamState();
        }


        if (error === ExamSessionErrors.NOT_OPEN_NOT_USER_SESSION) {
          navigate(`/exams/${examId}/closed${search}`);
        }

        if (error === ExamSessionErrors.WAITING_LOUNGE) {
          setRedirected(true);
          navigate(`/exams/${examId}/waiting${search}`);
        }

        if (error === ExamSessionErrors.PASSCODE_REQUIRED) {
          navigate(`/exams/${examId}/passcode${search}`);
        }

        if (error === ExamSessionErrors.GUEST_USERNAME_REQUIRED) {
          navigate(`/exams/${examId}${search}`);
        }

        if (error === ExamSessionErrors.EXAM_ACCESS_TYPE_LINK_WRONG_PASSWORD) {
          navigate(`/exams/${examId}/passcode${search}`, {
            state: { wrongPasscode: authorizationStore.password },
          });
        }

        if (error === ExamSessionErrors.EXAM_SESSION_USER_BANNED) {
          window.location.href = `/rejected${search}`;
        }

        if (error === ExamSessionErrors.SESSION_NOT_ADMIT_MORE_PARTICIPANT) {
          window.location.href = `/not-admited${search}`;
        }

        if (error === ExamSessionErrors.UNAUTHORIZED) {
          window.location.href = `/not-admited${search}`;
        }

        if (error === ExamSessionErrors.SERVER_ERROR) {
          // TODO: how do we handle 500 server errors?
          if (!user) {
            navigate(`/exams/${examId}?${query}`);
          } else {
            window.location.href = process.env.REACT_APP_BASE_URL;
          }
        }
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    handleAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    authorizationStore,
    examId,
    loginWithPopup,
    navigate,
    query,
    redirected,
    search,
    user,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Outlet
      context={{
        exam: authorizationStore.roomExam,
        user: authorizationStore.roomUser,
        accountId,
      }}
    />
  );
});

export default Protected;
