import _ from "lodash/fp";
import { action, computed, makeObservable, observable } from "mobx";

import i18n from "../../../services/i18n/config";
import ExamsLogger from "../../../logger";
import type { RoomExam } from "../../../types";
import { ExamIncidence, ExamIncidenceType } from "../../../channels/exam_incidences_channel";

import UIStore from ".";

const logPrefix = "[Notify Store]";

const participantIncidenceTypes: ExamIncidenceType[] = [
  "ABSENT_PARTICIPANT",
  "KO_CHROME_EXTENSION",
  "DROP_LVL_ATTENTION",
  "PARTICIPANT_NOT_ALONE",
  "NEW_TAB_WINDOW",
  "STOP_SHARING_DESKTOP",
  "CAMERA_STOPPED",
  "MICROPHONE_STOPPED",
  "COPY_EXTERNAL_TEXT",
  "PASTE_TEXT",
  "FILE_DOWNLOADED",
  "MULTIPLE_MONITORS",
  "ID_NAME_MISMATCH",
  "OBJECT_DETECTED"
];

const participantIncidenceMessages: Record<ExamIncidenceType, string> = {
  ABSENT_PARTICIPANT: i18n.t("stay_in_front_of_the_camera"),
  KO_ID_VALIDATION: "",
  KO_CHROME_EXTENSION: i18n.t("ko_chrome_extension_participant"),
  TAB_ACTIVATE: "You are in a new tab, this will be notified.",
  COPY_EXTERNAL_TEXT: i18n.t("external_text_copied"),
  DROP_LVL_ATTENTION: i18n.t("attention_dropped"),
  PARTICIPANT_NOT_ALONE: i18n.t("another_person_detected"),
  NEW_TAB_WINDOW: i18n.t("new_tab_opened"),
  STOP_SHARING_DESKTOP: i18n.t("stopped_sharing_screen"),
  USER_DROPPED: "",
  CAMERA_STOPPED: i18n.t("your_camera_has_stopped"),
  MICROPHONE_STOPPED: i18n.t("your_microphone_has_stopped"),
  PASTE_TEXT: i18n.t("external_text_pasted"),
  FILE_DOWNLOADED: i18n.t("file_downloaded_participant"),
  MULTIPLE_MONITORS: i18n.t("multiple_monitors_detected"),
  ID_NAME_MISMATCH: i18n.t("id_name_mismatch"),
  OBJECT_DETECTED: "",
};

type NotificationActions = {
  onClick?: () => void;
};

export type NotificationObject = {
  title: string;
  options?: NotificationOptions;
  actions?: NotificationActions;
};

class NotifyStore {
  uiStore!: UIStore;

  @observable
  queue: NotificationObject[] = [];

  constructor(ui: UIStore) {
    makeObservable(this);
    this.uiStore = ui;
  }

  static init(): void {
    if (NotifyStore.isPermissionGranted()) return;

    NotifyStore.askPermission();
  }

  @computed
  get nextNotification(): NotificationObject {
    return _.first(this.queue);
  }

  static isPermissionGranted(): boolean {
    return Notification.permission === "granted";
  }

  static async askPermission(): Promise<void> {
    const state = await Notification.requestPermission();
    ExamsLogger.info(logPrefix, "Permission state:", state);
  }

  static shouldNotifyParticipant(examIncidence: ExamIncidence): boolean {
    return _.includes(examIncidence.incidenceType, participantIncidenceTypes);
  }

  @computed
  get exam(): RoomExam {
    return this.uiStore.appStore.roomStore.info.exam;
  }

  @action
  emptyNotifications(): void {
    this.queue = [];
  }

  @action
  queueNotification(notification: NotificationObject): void {
    this.queue.push(notification);
  }

  @action
  dequeueNotification(): NotificationObject {
    return this.queue.shift();
  }

  spawnNotification(
    title: string,
    options: NotificationOptions,
    actions: NotificationActions,
  ): void {
    this.queueNotification({ title, options, actions });
  }

  showExamsNotification(title: string, text: string, actions?: NotificationActions): void {
    const options: NotificationOptions = {
      body: text,
    };
    this.spawnNotification(title, options, actions);
  }

  showHandNotification(text: string): void {
    this.showExamsNotification("HAND", text);
  }

  showRecordNotification(text: string): void {
    this.showExamsNotification("RECORD", text);
  }

  showMessageNotification(text: string, onClick: () => void): void {
    this.showExamsNotification("NEW MESSAGE", text, { onClick });
  }

  showExamNotification(text: string): void {
    this.showExamsNotification(`EXAMS: ${this.exam.name}`, text);
  }

  showParticipantNotification(examIncidence: ExamIncidence): void {
    this.showExamsNotification(
      `EXAMS: ${this.exam.name}`,
      participantIncidenceMessages[examIncidence.incidenceType],
    );
  }
}

export default NotifyStore;
