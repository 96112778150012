import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import "../../../../../services/i18n/config";
import ParticipantToolTip from "../../../../Tooltip/ParticipantToolTip/ParticipantToolTip";
import { useUserStore, useRoomStore } from "../../../../../hooks/store";
import UserPlayer from "../../../../Players/UserPlayer/UserPlayer";
import Icons from "../../../../../images/icons";

const ParticipantCam: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const roomStore = useRoomStore();

  return (
    <div className='w-full h-48 bg-[#000000]'>
      {!_.isNil(userStore.host) && (
        <UserPlayer user={userStore.host} className='w-full h-full' focus='PARTICIPANT' />
      )}
      {!_.isNil(roomStore.info) && roomStore.info.exam.isUnattended && (
        <div className='h-full flex items-center justify-center bg-blue-dark'>
          {roomStore.info.exam.isDemo ? (
            <div className='text-white text-sm my-auto mx-4 text-center relative'>
              <div className='flex items-center justify-center'>
                <Icons.Logo2 />
              </div>
            </div>
          ) : (
            <div className='text-white text-sm my-auto mx-4 text-center'>
              {t("no_live_assistance")}
              <ParticipantToolTip
                offset="{'bottom':160, 'left':-680}"
                align='text-left'
                event='click'
                eventOff='click'
                tooltipId='tt-hostcam'
              >
                <span className='cursor-pointer'>{t("no_live_assistence_here")}</span>
              </ParticipantToolTip>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default ParticipantCam;
