import { ID } from "../types";
import client from "./client";

const me = async (token: string): Promise<any> => {
  const response = await client.get(`${process.env.REACT_APP_LMS_URL}/me`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const enrollParticipant = async (userId:ID, examId:ID) => {
  const response = await client.get(`${process.env.REACT_APP_LMS_URL}/users/${userId}/${examId}/enroll`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};


const LmsService = {
  me,
  enrollParticipant,
};

export default LmsService;
