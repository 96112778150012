import React from "react";
import _ from "lodash/fp";
import { useTranslation } from "react-i18next";

import "../../../services/i18n/config";
import BlueButton from "../../Button/BlueButton/BlueButton";

export type Props = {
  nextURL: string;
  callback?: () => void;
  forceCallback?: boolean;
};

const BottomBarBeforeExam: React.FunctionComponent<Props> = ({
  nextURL,
  callback,
  forceCallback,
}: Props) => {
  const { t } = useTranslation();
  const goToNextUrl = () => {
    const site = _.last(_.split("/", nextURL));
    if (site === "start" || forceCallback) {
      callback();
    } else {
      window.location.href = nextURL;
    }
  };

  return (
    <div className='flex-none px-6 h-16 bg-gray-700 w-full flex flex-row-reverse items-center fixed bottom-0'>
      {!_.isEmpty(nextURL) && (
        <BlueButton onClick={goToNextUrl} className='whitespace-nowrap text-lg'>
          {t("next")}
        </BlueButton>
      )}
    </div>
  );
};

export default BottomBarBeforeExam;
