import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { useModalStore, useRoomStore } from "../../../hooks/store";
import Icons from "../../../images/icons";

import "../../../services/i18n/config";
import { ContextRoom } from "../../../types";
import BottomBarBeforeExam from "../../BottomBar/BottomBarBeforeExam/BottomBarBeforeExam";
import BlueButton from "../../Button/BlueButton/BlueButton";
import WaitingroomWrapper from "../../Room/Waitingroom";
import TopBar from "../../TopBar/TopBar";
import WaitingList from "../../WaitingList/WatingList";
import LoadingPage from "../Loading/Loading";

export default function WaitingRoom() {
  const { t } = useTranslation();
  const { exam, user } = useOutletContext<ContextRoom>();
  const roomStore = useRoomStore();
  const modalStore = useModalStore();
  const { search } = useLocation();

  useEffect(() => {
    if (roomStore.info?.exam?.supervisorFinishedWaitingRoom) {
      window.location.href = `/exams/${exam.id}/welcome${search}`;
    }

  }, [roomStore.info?.exam?.supervisorFinishedWaitingRoom])

  useEffect(() => {
    if (roomStore.appStore.authorizationStore?.sessionResult?.banned) {
      window.location.href = `/exams/${exam.id}/rejected${search}`;
    }
    
  }, [roomStore.appStore.authorizationStore?.sessionResult?.banned])

  if (!exam.agoraId || !user.id) {
    return <LoadingPage />;
  }

  const openRoom = async() => {
    modalStore.openModal("OPEN_ROOM", {
        onConfirm: async () => {
            await roomStore.finishWaiting();
            window.location.href = `/exams/${exam.id}/requirements${search}`;
        },
    });
  }

  return (
    <WaitingroomWrapper exam={exam} user={user} route={t("waiting")}>
      <div className='h-screen w-full bg-black'>
        <TopBar hideSpeakingToAndDropdown />
        {_.isEqual(user.role, "PARTICIPANT") && (
        <div className='w-full h-full bg-[#F8FAFB] text-center justify-center py-4'>
            <p className='font-semibold text-xl'>{exam.name}</p>
            <Icons.WaitingRoom className='w-full my-2' />
            <div>
                <p className='font-bold text-md'>{t("the_session_is_not_open_yet")}</p>
                <p>{t("wait_for_a_moment_while_the_person_in_charge_opens_the_session")}</p>
            </div>
            <BottomBarBeforeExam nextURL='' />
        </div>
        )}
        {_.isEqual(user.role, "HOST") && (
        <div className='w-full h-full bg-white text-center justify-center py-6'>
            <p className='font-semibold text-xl mb-4'>{exam.name}</p>

            <WaitingList exam={exam} />
            <div className='flex-none px-6 h-16 bg-gray-700 w-full flex flex-row-reverse items-center fixed bottom-0'>
                <BlueButton onClick={openRoom} className='whitespace-nowrap text-lg'>
                {t("open_the_room")}
                </BlueButton>
            </div>
        </div>
        )}
      </div>
    </WaitingroomWrapper>
  );
}
