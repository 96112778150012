/* eslint-disable @typescript-eslint/no-var-requires */
const moment = require("moment");
const momentTz = require("moment-timezone");

const dateHelper = {
  getTimezoneList() {
    return momentTz?.tz.names();
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTimeDiff(date: any) {
    return moment(moment(date).diff(moment())).format("mm:ss");
  },
  /**
   *  Given a date it returns the same it returns the same date in ISO format
   * @param date
   * @returns The date in ISO format
   */
  getIsoFormat(date: string) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss");
  },
  /**
   *  Given a date it returns the same date in specified format or default format
   * @param date
   * @param format
   * @returns The date in specified format or default format
   */
  getInFormat(date: string, format?: string) {
    const formatrgx = format === undefined ? "DD/MM/YYYY - HH:mm" : format;
    return moment(date).format(formatrgx);
  },

  /**
   * Given a given a date it returns the same date in UTC ISO string format
   * @param date date in ISO string
   * @param format format date string
   * @returns the date in UTC ISO string format
   */
  userTimezoneToUTC(dateString: Date | string, format?: string | undefined): string | undefined {
    const formatrgx = format === undefined ? "YYYY-MM-DDTHH:mm:ss" : format;
    const date = moment(dateString).isValid();
    return date ? moment(dateString)?.utc()?.format(formatrgx) : "–";
  },

  /**
   * Given a UTC date it returns the same date in user local timezone in ISO string format
   * @param date UTC date in ISO string
   * @param format format date string
   * @returns the date in user local timezone in ISO string format
   */
  utcToUserTimezone(dateStringUTC: Date | string, format?: string | undefined): string | undefined {
    const formatrgx = format === undefined ? "YYYY-MM-DDTHH:mm:ss" : format;
    const date = moment(dateStringUTC).isValid();
    return date ? moment.utc(dateStringUTC)?.local()?.format(formatrgx) : "–";
  },

  //* ** */

  /**
   * Given a UTC date it returns the same date in the specific timezone in ISO string format
   * @param date
   * @param timeZone
   * @param format format date string
   * @returns the date
   */
  utcToTimezone(dateStringUTC: Date | string, timeZone: string, format?: string) {
    const formatrgx = format === undefined ? "YYYY-MM-DDTHH:mm:ss" : format;
    return moment.utc(dateStringUTC)?.tz(timeZone)?.format(formatrgx);
  },

  /**
   * Given date in the specific timezone it returns the same date in a UTC date in ISO string
   * @param date
   * @param timeZone
   * @param format format date string
   * @returns the date
   */

  timezoneToUtc(dateStringUTC: Date | string, timeZone: string, format?: string) {
    const formatrgx = format === undefined ? "YYYY-MM-DDTHH:mm:ss" : format;
    return moment.tz(dateStringUTC, timeZone).utc().format(formatrgx);
  },

  getDateFromDateAndHour(date: Date, hour: Date) {
    const newDate = date;
    newDate.setHours(hour.getHours());
    newDate.setMinutes(hour.getMinutes());
    newDate.setMilliseconds(0);
    return newDate;
  },
};

export default dateHelper;
