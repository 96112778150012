import { ID } from "../types";

import client from "./client";

type CreateParams = {
  comment: string;
  userId: ID;
  examId: ID;
  sessionId: ID;
};

export const create = async (data: CreateParams): Promise<void> => {
  const configs = {
    params: {
      examId: data.examId,
    },
  };
  const postData = {
    comment: data.comment,
    agoraUserId: data.userId,
  };

  const url = "/exam-service/v1/sessions/" + data.sessionId + "/comment";

  const response = await client.post(url, postData, configs);
  return response.data;
};

const CommentService = {
  create,
};

export default CommentService;
